import AppHome from "@/views/template/AppHome";
import GuiaProduccionListarView from "@/modules/guia/views/produccion/ListarView.vue";
import GuiaProduccionCrearView from "@/modules/guia/views/produccion/CrearView.vue";
import GuiaProduccionMostrarView from "@/modules/guia/views/produccion/MostrarView.vue";
import GuiaProduccionEditarView from "@/modules/guia/views/produccion/EditarView.vue";
import GuiaRiegoListarView from "@/modules/guia/views/riego/ListarView.vue";
import GuiaRiegoCrearView from "@/modules/guia/views/riego/CrearView.vue";
import GuiaRiegoMostrarView from "@/modules/guia/views/riego/MostrarView.vue";
import GuiaRiegoEditarView from "@/modules/guia/views/riego/EditarView.vue";
import GuiaPlagaListarView from "@/modules/guia/views/plaga/ListarView.vue";
import GuiaPlagaCrearView from "@/modules/guia/views/plaga/CrearView.vue";
import GuiaPlagaMostrarView from "@/modules/guia/views/plaga/MostrarView.vue";
import GuiaPlagaEditarView from "@/modules/guia/views/plaga/EditarView.vue";
import GuiaTratamientoListarView from "@/modules/guia/views/tratamiento/ListarView.vue";
import GuiaTratamientoCrearView from "@/modules/guia/views/tratamiento/CrearView.vue";
import GuiaTratamientoMostrarView from "@/modules/guia/views/tratamiento/MostrarView.vue";
import GuiaTratamientoEditarView from "@/modules/guia/views/tratamiento/EditarView.vue";
import GuiaPlanificacionListarView from "@/modules/guia/views/planificacion/ListarView.vue";
import GuiaPlanificacionCrearView from "@/modules/guia/views/planificacion/CrearView.vue";
import GuiaPlanificacionEditarView from "@/modules/guia/views/planificacion/EditarView.vue";
import GuiaPlanificacionMostrarView from "@/modules/guia/views/planificacion/MostrarView.vue";

const moduleRoute = {
    path: "/guia",
    component: AppHome,
    /*beforeEnter: (to, from, next) => {

    },*/
    children: [
        {
            path: "/guia/produccion",
            name: "guia.produccion.listar",
            components: { content: GuiaProduccionListarView},
        },
        {
            path: "/guia/produccion/crear",
            name: "guia.produccion.crear",
            components: { content: GuiaProduccionCrearView},
        },
        {
            path: "/guia/produccion/:id",
            name: "guia.produccion.mostrar",
            components: { content: GuiaProduccionMostrarView},
        },
        {
            path: "/guia/produccion/:id/editar",
            name: "guia.produccion.editar",
            components: { content: GuiaProduccionEditarView},
        },
        {
            path: "/guia/riego",
            name: "guia.riego.listar",
            components: { content: GuiaRiegoListarView},
        },
        {
            path: "/guia/riego/crear",
            name: "guia.riego.crear",
            components: { content: GuiaRiegoCrearView},
        },
        {
            path: "/guia/riego/:id",
            name: "guia.riego.mostrar",
            components: { content: GuiaRiegoMostrarView},
        },
        {
            path: "/guia/riego/:id/editar",
            name: "guia.riego.editar",
            components: { content: GuiaRiegoEditarView},
        },
        {
            path: "/guia/plaga",
            name: "guia.plaga.listar",
            components: { content: GuiaPlagaListarView},
        },
        {
            path: "/guia/plaga/crear",
            name: "guia.plaga.crear",
            components: { content: GuiaPlagaCrearView},
        },
        {
            path: "/guia/plaga/:id",
            name: "guia.plaga.mostrar",
            components: { content: GuiaPlagaMostrarView},
        },
        {
            path: "/guia/plaga/:id/editar",
            name: "guia.plaga.editar",
            components: { content: GuiaPlagaEditarView},
        },
        {
            path: "/guia/tratamiento",
            name: "guia.tratamiento.listar",
            components: { content: GuiaTratamientoListarView},
        },
        {
            path: "/guia/tratamiento/crear",
            name: "guia.tratamiento.crear",
            components: { content: GuiaTratamientoCrearView},
        },
        {
            path: "/guia/tratamiento/:id",
            name: "guia.tratamiento.mostrar",
            components: { content: GuiaTratamientoMostrarView},
        },
        {
            path: "/guia/tratamiento/:id/editar",
            name: "guia.tratamiento.editar",
            components: { content: GuiaTratamientoEditarView},
        },
        {
            path: "/guia/planificacion",
            name: "guia.planificacion.listar",
            components: { content: GuiaPlanificacionListarView},
        },
        {
            path: "/guia/planificacion/crear",
            name: "guia.planificacion.crear",
            components: { content: GuiaPlanificacionCrearView},
        },
        {
            path: "/guia/planificacion/:id/editar",
            name: "guia.planificacion.editar",
            components: { content: GuiaPlanificacionEditarView},
        },
        {
            path: "/guia/planificacion/:id",
            name: "guia.planificacion.mostrar",
            components: { content: GuiaPlanificacionMostrarView},
        },
    ]
};

export default router => {
    router.addRoute(moduleRoute);
};
