<template>
  <div class="flex-fill">
    <label for="selectTipo" class="form-label">Tipo</label>
    <select v-model="this.tipo" id="selectTipo" class="form-select" aria-label="Seleccione un tipo de actividad">
      <option selected value="0">Todos</option>
      <option value="1">Evento</option>
      <option value="2">Feria</option>
    </select>
  </div>
</template>
<script>
import router from "@/router";

export default {
  name: 'SelectTipoActividadComponent.vue',
  data() {
    return {
      tipo: 0,
    }
  },
  watch: {
    tipo: function (nuevo) {
      let params = {...this.$route.query, tipo: nuevo};
      router.push({ path: this.$route.path, query: params });
    }
  },
  mounted() {
    if (this.$route.query.tipo !== undefined)
      this.tipo = this.$route.query.tipo;
  }
}
</script>