<template>
  <div class="flex-fill">
    <label for="tipoCultivo" class="form-label">Tipo de Cultivo</label>
    <select v-model="this.form.tipoCultivo" @change="onChangeTipoCultivo($event)" id="tipoCultivo"
            class="form-select">
      <option value="0">Todos</option>
      <option v-for="tipoCultivo in this.lista.tipoCultivos" :key="tipoCultivo.id"
              :value="tipoCultivo.id">{{tipoCultivo.nombre}}
      </option>
    </select>
  </div>
</template>
<script>
import Service from "@/utils/Service";
import {route} from "@/utils/Route";
import router from "@/router";

export default {
  name: 'SelectTipoCultivoComponent',
  data() {
    return {
      lista: {
        tipoCultivos: []
      },
      form: {
        tipoCultivo: 0
      }
    }
  },
  methods: {
    listarTipoCultivo() {
      Service.get(route('recurso.tipo_cultivo')).then( data => {
        this.lista.tipoCultivos = data.data;
      }).catch( error => {
        console.log(error);
      });
    },

    onChangeTipoCultivo() {
      let params = {...this.$route.query, tipoCultivo: this.form.tipoCultivo};
      router.push({ path: this.$route.path, query: params });
    },
  },
  mounted() {
    this.listarTipoCultivo();
    if (this.$route.query.tipoCultivo !== undefined)
      this.form.tipoCultivo = this.$route.query.tipoCultivo;
  }
}
</script>