<template>
  <div class="d-flex justify-content-center">
    <div class="col-sm-12 col-md-8 col-lg-6 col-xl-4 col-xxl-3 pt-5 mx-2">
      <div class="card mt-4">

        <div class="card-body p-4">
          <mensaje-alerta-component :error="this.error" class="mt-2" />
          <div class="text-center mt-2">
            <h5 class="text-primary">Bienvenido!</h5>
            <p class="text-muted">Inicia sesión para ingresar al panel de PuntoVerdeApp.</p>
          </div>
          <div class="p-2 mt-4">
            <form v-on:submit.prevent="onIniciarSesion" id="form">
              <div class="mb-3">
                <label for="username" class="form-label">Correo:</label>
                <input v-model="this.form.username" :disabled="!this.form.activo"
                       type="email" class="form-control" id="username">
              </div>

              <div class="mb-3">
                <label class="form-label" for="password-input">Contraseña:</label>
                <div class="position-relative auth-pass-inputgroup mb-3">
                  <input v-model="this.form.password" :disabled="!this.form.activo"
                         type="password" class="form-control pe-5 password-input" id="password-input">
                </div>
              </div>
              <div class="mt-4">
                <button :disabled="!this.form.activo" class="btn btn-primary w-100" type="submit">Iniciar Sesión</button>
              </div>
            </form>
          </div>
        </div>
        <!-- end card body -->
      </div>
      <!-- end card -->
    </div>
  </div>
</template>
<script>

import {route} from "@/utils/Route";
import Service from "@/utils/Service";
import router from "@/router";
import store from "@/store";
import MensajeAlertaComponent from "@/components/utils/MensajeAlerta.vue";

export default {
  name: 'InicioSesionView',
  components: {MensajeAlertaComponent},
  data() {
    return {
      error: {
        mostrar: false,
        mensaje: null,
      },
      form: {
        activo: true,
        username: '',
        password: ''
      }
    }
  },
  methods: {
    onIniciarSesion() {
      this.ocultarError();
      this.desactivarForm();
      Service.post(route('iniciar_sesion'), this.getParametros()).then( data => {
        localStorage.setItem('token', data.data.token);
        store.commit('SET_USUARIO', data.data);
        router.push("/home");
      }).catch( error => {
        this.mostrarError(error);
      }).finally( () => {
        this.activarForm();
      });
    },

    getParametros() {
      return {
        username: this.form.username,
        password: this.form.password
      }
    },

    ocultarError() {
      this.error.mostrar = false;
      this.error.mensaje = '';
    },
    mostrarError(error) {
      this.error.mostrar = true;
      this.error.mensaje = error;
    },
    desactivarForm() {
      this.form.activo = false;
    },
    activarForm() {
      this.form.activo = true;
    },
  }
}
</script>