<template>
  <div>
    <div id="modalActividadImagenEliminar" class="modal fade" tabindex="-1"
         aria-labelledby="modalLabelActividadImagenEliminar" aria-hidden="true" style="display: none;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalTituloActividadImagenEliminar">Eliminar imagen de una actividad</h5>
            <button id="btnCerrarModalActividadImagenEliminar" ref="btnCerrarModalActividadImagenEliminar"
                    type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
          </div>
          <div class="modal-body">
            <p class="text-muted">Desea eliminar la imagen</p>
          </div>
          <div class="modal-footer">
            <button :disabled="!this.form.activo" type="button" class="btn btn-light" data-bs-dismiss="modal">Cerrar</button>
            <button :disabled="!this.form.activo" @click="eliminarActividadImagen" type="button" class="btn btn-danger">Si, Eliminar</button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Service from "@/utils/Service";
import {route} from "@/utils/Route";
import Toast from "@/utils/Toast";

export default {
  name: 'ModalActividadImagenEliminarComponent',
  props: {
    imagen: {
      id: Number,
    },
    actividad: Number,
  },
  data() {
    return {
      form: {
        activo: true
      },
    }
  },
  methods: {
    eliminarActividadImagen() {
      this.desactivarForm();
      Service.delete(route('actividad.imagen.eliminar', [this.actividad, this.imagen.id]))
          .then( (data) => {
        Toast.showSuccess(data.mensaje)
        this.$emit('onActividadImagenEliminado');
        this.$refs.btnCerrarModalActividadImagenEliminar.click();
      }).catch((error) => {
        console.log(error)
      }).finally(()=>{
        this.activarForm();
      });
    },

    desactivarForm() {
      this.form.activo = false;
    },
    activarForm() {
      this.form.activo = true;
    },
  }

}
</script>