<template>
  <div class="container">
    <h3>Registrar una guía de producción</h3>

    <div class="card mt-2">
      <div class="card-body">
        <form v-on:submit.prevent="registrarGuiaProduccion()" id="form">
          <div class="d-flex flex-column">
            <mensaje-alerta-component :error="this.error" class="mt-2" />
            <div class="row">
              <div class="col-12 mt-2">
                <label for="titulo" class="form-label">Título <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <input v-model="this.form.titulo" id="titulo" type="text" class="form-control" maxlength="100"
                       :disabled="!this.form.activo" required>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6 mt-2">
                <label for="tipoProduccion" class="form-label">Tipo producción <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <select v-model="this.form.tipoProduccion" id="tipoProduccion" class="form-select"
                        :disabled="!this.form.activo" required>
                  <option value="1">Comercial</option>
                  <option value="2">Autoconsumo</option>
                </select>
              </div>
              <div class="col-12 col-md-6 mt-2">
                <label for="urlVideo" class="form-label">Enlace del vídeo en youtube</label>
                <input v-model="this.form.urlVideo" id="urlVideo" type="text" class="form-control"
                       placeholder="https://youtube.com/" maxlength="255" :disabled="!this.form.activo">
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-4 mt-2">
                <label for="departamento" class="form-label">Departamento <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <select v-model="this.form.departamento" id="departamento" class="form-select"
                        :disabled="!this.form.activo" required>
                  <option value="1">Santa Cruz</option>
                  <option value="2">Beni</option>
                  <option value="3">Pando</option>
                  <option value="4">Cochabamba</option>
                  <option value="5">Chuquisaca</option>
                  <option value="6">Tarija</option>
                  <option value="7">La Paz</option>
                  <option value="8">Oruro</option>
                  <option value="9">Potosi</option>
                </select>
              </div>
              <div class="col-12 col-md-4 mt-2">
                <label for="tipoCultivo" class="form-label">Tipo cultivo</label>
                <select v-model="this.form.tipoCultivo" @change="onChangeTipoCultivo($event)" id="tipoCultivo"
                        class="form-select" :disabled="!this.form.activo">
                  <option value="0">Todos</option>
                  <option v-for="tipoCultivo in this.lista.tipoCultivos" :key="tipoCultivo.id"
                          :value="tipoCultivo.id">{{tipoCultivo.nombre}}
                  </option>
                </select>
              </div>
              <div class="col-12 col-md-4 mt-2">
                <label for="cultivo" class="form-label">cultivo</label>
                <select v-model="this.form.cultivo" id="cultivo" class="form-select"
                        :disabled="!this.form.activo">
                  <option value="0">Todos</option>
                  <option v-for="cultivo in this.lista.cultivos" :key="cultivo.id"
                          :value="cultivo.id">{{cultivo.nombre}}
                  </option>
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6 mt-2">
                <label for="imagen" class="form-label">Imagen <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <input @change="onChangeInputImagen($event)" class="form-control" type="file" id="imagen" accept="image/jpeg,image/png"
                       :disabled="!this.form.activo" required>
              </div>
              <div class="col-12 col-md-6 mt-2">
                <label for="pdf" class="form-label">Pdf</label>
                <input @change="onChangeInputPdf($event)" class="form-control" type="file" id="pdf" accept="application/pdf"
                       :disabled="!this.form.activo">
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-2">
                <span class="form-label">Descripción <span class="fs-12 text-danger">(Obligatorio)</span></span>
                <Editor name="descripcion" :api-key="tiny.key" :init="tiny.init" v-model="this.form.descripcion" :disabled="!this.form.activo"/>
              </div>
            </div>
          </div>
          <div class="d-flex flex-row-reverse mt-4">
            <button type="submit" class="btn btn-primary waves-effect waves-light">Registrar</button>
            <a @click="router().back()" class="btn btn-soft-secondary waves-effect waves-light me-2">Atrás</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import Service from "@/utils/Service";
import {route} from "@/utils/Route";
import router from "@/router";
import Toast from "@/utils/Toast";
import MensajeAlertaComponent from "@/components/utils/MensajeAlerta.vue";
import Editor from "@tinymce/tinymce-vue";
import {tinyMCE} from "@/utils/config";

export default {
  name: 'GuiaProduccionCrearView',
  components: {Editor, MensajeAlertaComponent},
  data() {
    return {
      tiny: tinyMCE,
      error: {
        mostrar: false,
        mensaje: null,
      },
      form: {
        activo: true,
        titulo: '',
        tipoProduccion: 1,
        urlVideo: '',
        departamento: 1,
        tipoCultivo: 0,
        cultivo: 0,
        imagen: null,
        pdf: null,
        descripcion: ''
      },
      lista: {
        tipoCultivos: [],
        cultivos: []
      }
    }
  },
  methods: {
    router() {
      return router
    },
    listarTipoCultivo() {
      Service.get(route('recurso.tipo_cultivo')).then( data => {
        this.lista.tipoCultivos = data.data;
      }).catch( error => {
        console.log(error);
      });
    },
    listarCultivo(tipoCultivo) {
      Service.get(route('recurso.tipo_cultivo.cultivo', [tipoCultivo])).then( data => {
        this.lista.cultivos = data.data;
      }).catch( error => {
        console.log(error);
      });
    },
    registrarGuiaProduccion() {
      this.ocultarError();
      this.desactivarForm();
      Service.postFormData(route('guia.produccion.registrar'), this.getParametros()).then( (data)=>{
        Toast.showSuccess(data.mensaje)
        router.push({name: 'guia.produccion.listar'});
      }).catch( error => {
        this.mostrarError(error);
      }). finally( () => {
        this.activarForm();
      });
    },

    onChangeTipoCultivo(event) {
      if (event.target.value > 0) {
        this.listarCultivo(event.target.value)
      } else {
        this.lista.cultivos = [];
        this.form.cultivo = 0;
      }
    },
    onChangeInputImagen(event) {
      this.form.imagen = event.target.files[0];
    },
    onChangeInputPdf(event) {
      this.form.pdf = event.target.files[0];
    },

    getParametros() {
      let formData = new FormData();
      formData.append('titulo', this.form.titulo);
      formData.append('tipoProduccion', this.form.tipoProduccion);
      formData.append('urlVideo', this.form.urlVideo);
      formData.append('departamento', this.form.departamento);
      formData.append('tipoCultivo', this.form.tipoCultivo);
      formData.append('cultivo', this.form.cultivo);
      formData.append('imagen', this.form.imagen);
      formData.append('pdf', this.form.pdf);
      formData.append('descripcion', this.form.descripcion);

      return formData;
    },
    ocultarError() {
      this.error.mostrar = false;
      this.error.mensaje = '';
    },
    mostrarError(error) {
      this.error.mostrar = true;
      this.error.mensaje = error;
    },
    desactivarForm() {
      this.form.activo = false;
    },
    activarForm() {
      this.form.activo = true;
    },
  },

  mounted() {
    this.listarTipoCultivo();
  },

}
</script>