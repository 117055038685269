<template>
  <div class="container">
    <h3 class="mb-2">Modificar un huerto</h3>
    <div class="card">
      <div class="card-body">
        <form v-on:submit.prevent="modificarHuerto()" id="form">
          <div class="d-flex flex-column">
            <mensaje-alerta-component :error="this.error" class="mb-2" />
            <div class="row">
              <div class="col-12 col-md-6 mb-2">
                <label for="departamento" class="form-label">Departamento <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <select v-model="this.form.departamento" id="departamento" class="form-select"
                        :disabled="!this.form.activo" required>
                  <option value="1">Santa Cruz</option>
                  <option value="2">Beni</option>
                  <option value="3">Pando</option>
                  <option value="4">Cochabamba</option>
                  <option value="5">Chuquisaca</option>
                  <option value="6">Tarija</option>
                  <option value="7">La Paz</option>
                  <option value="8">Oruro</option>
                  <option value="9">Potosi</option>
                </select>
              </div>
              <div class="col-12 col-md-6 mb-2">
                <label for="tipoProduccion" class="form-label">Tipo producción <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <select v-model="this.form.tipoProduccion" id="tipoProduccion" class="form-select"
                        :disabled="!this.form.activo" required>
                  <option value="1">Comercial</option>
                  <option value="2">Autoconsumo</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6 mb-2">
                <label for="cultivador" class="form-label">Cultivador <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <select v-model="this.form.cultivador" id="cultivador"
                        class="form-select" aria-label="Seleccione un cultivador de la lista"
                        :disabled="!this.form.activo" required>
                  <option selected value="0">Seleccione un cultivador</option>
                  <option v-for="cultivador in this.lista.cultivadores" :key="cultivador.id"
                          :value="cultivador.id">{{cultivador.apellidos + ' ' + cultivador.nombre}}
                  </option>
                </select>
              </div>
              <div class="col-12 col-md-6 mb-2">
                <label for="imagen" class="form-label">Imagen
                  <a v-if="this.form.urlImagen != null" :href="this.form.urlImagen" target="_blank">ver actual</a>
                </label>
                <input @change="onChangeInputImagen($event)" class="form-control" type="file" id="imagen" accept="image/jpeg,image/png"
                       :disabled="!this.form.activo">
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-2">
                <label for="nombre" class="form-label">Nombre del huerto <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <input v-model="this.form.nombre" id="nombre" type="text" class="form-control" maxlength="100" :disabled="!this.form.activo" required>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-2">
                <label for="descripcion" class="form-label">Descripción del huerto <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <textarea v-model="this.form.descripcion" id="descripcion" class="form-control" rows="3"
                          maxlength="500" :disabled="!this.form.activo" required>
                </textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-4">
                <label class="form-label">Seleccione la ubicación del huerto <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <GoogleMap
                    v-if="this.googleMap.visible"
                    :api-key="this.googleMap.key"
                    style="width: 100%; height: 400px"
                    :center="this.googleMap.center"
                    :zoom="12"
                    :disabled="!this.form.activo">
                  <Marker
                      :options="{ position: this.googleMap.marker, draggable: true, clickable: true }"
                      @dragend="onGeolocalizacionActualizar($event)" />
                </GoogleMap>
              </div>
            </div>
          </div>
          <div class="d-flex flex-row-reverse">
            <button type="submit" class="btn btn-primary waves-effect waves-light">Modificar</button>
            <a @click="router().back()" class="btn btn-soft-secondary waves-effect waves-light me-2">Atrás</a>
          </div>
        </form>
      </div>
    </div>

  </div>
</template>
<script>
import { GoogleMap, Marker } from "vue3-google-map";
import Service from "@/utils/Service";
import {route} from "@/utils/Route";
import Toast from "@/utils/Toast";
import router from "@/router";
import MensajeAlertaComponent from "@/components/utils/MensajeAlerta.vue";
import {GOOGLE_MAP_KEY} from "@/utils/config";

export default {
  name: 'HuertoCrearView',
  components: {MensajeAlertaComponent, GoogleMap, Marker},
  data() {
    return {
      error: {
        mostrar: false,
        mensaje: null,
      },
      editorConfig: {},
      form: {
        activo: true,
        nombre: '',
        descripcion: '',
        longitud: 0,
        latitud: 0,
        imagen: null,
        urlImagen: null,
        tipoProduccion: 1,
        departamento: 1,
        cultivador: 0,
      },
      lista: {
        cultivadores: []
      },
      googleMap: {
        key: GOOGLE_MAP_KEY,
        visible: false,
        center: {lat: -17.783602, lng: -63.182327},
        marker: {lat: -17.783602, lng: -63.182327},
      },
    }
  },
  methods: {
    router() {
      return router
    },
    mostrarHuerto(){
      this.ocultarError();
      this.googleMap.visible = false;
      Service.get(route('huerto.mostrar', this.getParametrosMostrar()), this.getParametros()).then( (data)=>{
        this.form.nombre = data.data.nombre;
        this.form.descripcion = data.data.descripcion;
        this.form.urlImagen = data.data.urlImagen;
        this.form.latitud = data.data.latitud;
        this.form.longitud = data.data.longitud;
        this.form.tipoProduccion = data.data.tipoProduccion;
        this.form.departamento = data.data.departamento;
        this.form.cultivador = data.data.cultivador;
        this.googleMap.center.lat = Number(data.data.latitud);
        this.googleMap.center.lng = Number(data.data.longitud);
        this.googleMap.marker.lat = Number(data.data.latitud);
        this.googleMap.marker.lng = Number(data.data.longitud);
        this.googleMap.visible = true;
      }).catch( error => {
        this.mostrarError(error);
      });
    },
    modificarHuerto() {
      this.ocultarError();
      this.desactivarForm();
      Service.putFormData(route('huerto.modificar', this.getParametrosMostrar()), this.getParametros()).then( (data)=>{
        Toast.showSuccess(data.mensaje)
        router.push({name: 'huerto.listar'});
      }).catch( error => {
        this.mostrarError(error);
      }).finally( () => {
        this.activarForm();
      });
    },
    listarCultivador() {
      Service.get(route('recurso.cultivador')).then( data => {
        this.lista.cultivadores = data.data;
      }).catch( error => {
        console.log(error);
      });
    },
    onGeolocalizacionActualizar(event) {
      this.form.latitud = event.latLng.lat();
      this.form.longitud = event.latLng.lng();
    },
    onChangeInputImagen(event) {
      this.form.imagen = event.target.files[0];
    },
    getParametrosMostrar() {
      return [this.$route.params.id];
    },
    getParametros() {
      let formData = new FormData();
      formData.append('departamento', this.form.departamento);
      formData.append('tipoProduccion', this.form.tipoProduccion);
      formData.append('nombre', this.form.nombre);
      formData.append('descripcion', this.form.descripcion);
      formData.append('imagen', this.form.imagen);
      formData.append('cultivador', this.form.cultivador);
      formData.append('latitud', this.form.latitud);
      formData.append('longitud', this.form.longitud);

      return formData;
    },
    desactivarForm() {
      this.form.activo = false;
    },
    activarForm() {
      this.form.activo = true;
    },

    ocultarError() {
      this.error.mostrar = false;
      this.error.mensaje = '';
    },
    mostrarError(error) {
      this.error.mostrar = true;
      this.error.mensaje = error;
    },
  },

  mounted() {
    this.mostrarHuerto();
    this.listarCultivador();
  }
}
</script>