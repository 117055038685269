<template>
  <div>
    <div class="mb-5"><h3>Lista de chats</h3></div>
    <tabla-chat-listar-component ref="tablaChatListar" />
  </div>
</template>
<script>
import TablaChatListarComponent from "@/modules/administracion/components/chat/TablaListarComponent.vue";
import PocketBase from 'pocketbase';
import {CHAT_SERVER_URL} from "@/utils/config";

export default {
  name: 'ChatListarView',
  components: {
    TablaChatListarComponent,
  },
  data() {
    return {
      chats: [],
      cultivador: {},
    }
  },
  methods: {
    async listarChat() {
      const pb = new PocketBase(CHAT_SERVER_URL);
      const resultList = await pb.collection('room').getList(1, 50, {
        expand: 'client', sort: '-hasNewUserMessage',
      });
      this.chats = resultList.items;
      this.$refs.tablaChatListar.setChats(this.chats);
    },
  },
  mounted() {
    this.listarChat();
  }
}
</script>