<template>
  <div class="d-flex">
    <div class="flex-fill">
      <label for="tipoPlaga" class="form-label">Tipo de Plaga</label>
      <select v-model="this.form.tipoPlaga" @change="onChangeTipoPlaga($event)" id="tipoPlaga"
              class="form-select">
        <option value="0">Todos</option>
        <option v-for="tipoPlaga in this.lista.tipoPlagas" :key="tipoPlaga.id"
                :value="tipoPlaga.id">{{tipoPlaga.nombre}}
        </option>
      </select>
    </div>
  </div>
</template>
<script>
import Service from "@/utils/Service";
import {route} from "@/utils/Route";
import router from "@/router";

export default {
  name: 'SelectTipoPlagaComponent',
  data() {
    return {
      lista: {
        tipoPlagas: []
      },
      form: {
        tipoPlaga: 0
      }
    }
  },
  methods: {
    listarTipoPlaga() {
      Service.get(route('recurso.tipo_plaga')).then( data => {
        this.lista.tipoPlagas = data.data;
      }).catch( error => {
        console.log(error);
      });
    },
    onChangeTipoPlaga(event) {
      if (event.target.value > 0) {
        let params = {...this.$route.query, tipoPlaga: this.form.tipoPlaga};
        router.push({ path: this.$route.path, query: params });
      }
    },
  },
  mounted() {
    this.listarTipoPlaga();
    if (this.$route.query.tipoPlaga !== undefined)
      this.form.tipoPlaga = this.$route.query.tipoPlaga;
  }
}
</script>