<template>
  <div class="container">
    <h3 class="mb-2">Registrar un cultivo</h3>
    <div class="card">
      <div class="card-body">
        <form v-on:submit.prevent="registrarCultivo()" id="form">
          <div class="d-flex flex-column">
            <mensaje-alerta-component :error="this.error" class="mb-2" />
            <div class="row">
              <div class="col-12 col-md-4 mt-2">
                <label for="departamento" class="form-label">Tipo de cultivo <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <select v-model="this.form.tipoCultivo" id="departamento" class="form-select"
                        :disabled="!this.form.activo" required>
                  <option v-for="tipoCultivo in this.lista.tipoCultivos" v-bind:key="tipoCultivo.id" :value="tipoCultivo.id">{{tipoCultivo.nombre}}</option>
                </select>
              </div>
              <div class="col-12 col-md-4 mt-2">
                <label for="nombre" class="form-label">Nombre del cultivo <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <input v-model="this.form.nombre" id="nombre" type="text" class="form-control" maxlength="25" :disabled="!this.form.activo" required>
              </div>
              <div class="col-12 col-md-4 mt-2">
                <label for="nombreCientifico" class="form-label">Nombre científico del cultivo <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <input v-model="this.form.nombreCientifico" id="nombreCientifico" type="text" class="form-control" maxlength="25" :disabled="!this.form.activo" required>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-2">
                <label for="descripcion" class="form-label">Descripción del cultivo <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <textarea v-model="this.form.descripcion" id="descripcion" class="form-control" rows="3"
                          maxlength="500" :disabled="!this.form.activo" required>
                </textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6 mt-2">
                <label for="imagen" class="form-label">Imagen de perfil <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <input @change="onChangeInputImagen($event)" class="form-control" type="file" id="imagen" accept="image/jpeg,image/png"
                       :disabled="!this.form.activo" required>
              </div>
              <div class="col-12 col-md-6 mt-2">
                <label for="portada" class="form-label">Imagen de portada <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <input @change="onChangeInputPortada($event)" class="form-control" type="file" id="portada" accept="image/jpeg,image/png"
                       :disabled="!this.form.activo" required>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6 mt-2">
                <label for="pdf" class="form-label">Pdf de receta de cocina </label>
                <input @change="onChangeInputPdf($event)" class="form-control" type="file" id="pdf" accept="application/pdf"
                       :disabled="!this.form.activo">
              </div>
              <div class="col-12 col-md-6 mt-2">
                <label for="cartilla" class="form-label">Pdf de cartilla de siembra </label>
                <input @change="onChangeInputCartilla($event)" class="form-control" type="file" id="cartilla" accept="application/pdf"
                       :disabled="!this.form.activo">
              </div>
            </div>

          </div>
          <div class="d-flex flex-row-reverse mt-4">
            <button :disabled="!this.form.activo" type="submit" class="btn btn-primary waves-effect waves-light">Registrar</button>
            <a @click="router().back()" class="btn btn-soft-secondary waves-effect waves-light me-2">Atrás</a>
          </div>
        </form>
      </div>
    </div>

  </div>
</template>
<script>
import Service from "@/utils/Service";
import {route} from "@/utils/Route";
import Toast from "@/utils/Toast";
import router from "@/router";
import MensajeAlertaComponent from "@/components/utils/MensajeAlerta.vue";

export default {
  name: 'CultivoCrearView',
  components: {MensajeAlertaComponent},
  data() {
    return {
      error: {
        mostrar: false,
        mensaje: null,
      },
      form: {
        activo: true,
        nombre: '',
        nombreCientifico: '',
        descripcion: '',
        imagen: null,
        portada: null,
        pdf: null,
        tipoCultivo: 1,
      },
      lista: {
        tipoCultivos: []
      },
    }
  },
  methods: {
    router() {
      return router
    },
    registrarCultivo() {
      this.desactivarForm();
      this.ocultarError();
      Service.postFormData(route('cultivo.registrar'), this.getParametros()).then( (data)=>{
        Toast.showSuccess(data.mensaje)
        router.push({name: 'cultivo.listar'});
      }).catch( error => {
        this.mostrarError(error);
      }). finally( () => {
        this.activarForm();
      });
    },
    listarTipoCultivo() {
      Service.get(route('recurso.tipo_cultivo')).then( data => {
        this.lista.tipoCultivos = data.data;
      }).catch( error => {
        console.log(error);
      });
    },
    onChangeInputImagen(event) {
      this.form.imagen = event.target.files[0];
    },
    onChangeInputPortada(event) {
      this.form.portada = event.target.files[0];
    },
    onChangeInputPdf(event) {
      this.form.pdf = event.target.files[0];
    },
    onChangeInputCartilla(event) {
      this.form.cartilla = event.target.files[0];
    },
    getParametros() {
      let formData = new FormData();
      formData.append('tipoCultivo', this.form.tipoCultivo);
      formData.append('nombre', this.form.nombre);
      formData.append('nombreCientifico', this.form.nombreCientifico);
      formData.append('descripcion', this.form.descripcion);
      formData.append('imagen', this.form.imagen);
      formData.append('portada', this.form.portada);
      formData.append('pdf', this.form.pdf);
      formData.append('cartilla', this.form.cartilla);
      return formData;
    },
    desactivarForm() {
      this.form.activo = false;
    },
    activarForm() {
      this.form.activo = true;
    },

    ocultarError() {
      this.error.mostrar = false;
      this.error.mensaje = '';
    },
    mostrarError(error) {
      this.error.mostrar = true;
      this.error.mensaje = error;
    },
  },
  mounted() {
    this.listarTipoCultivo();
  }
}
</script>