<template>
  <div class="container">
    <div class="d-flex flex-column">
      <mensaje-alerta-component :error="this.error" class="mb-2" />
      <div class="mb-2">
        <a @click="router().back()" class="btn btn-soft-secondary waves-effect waves-light me-2">Atrás</a>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="d-flex position-relative">
            <div class="row">
              <div class="col-12 col-md-4 col-xl-3">
                <div class="d-flex justify-content-center">
                  <img :src="this.cultivador.urlFoto" class="rounded img-fluid w-50 w-md" alt="punto-verde"/>
                </div>
              </div>
              <div class="col-12 col-md-8 col-xl-9">
                <div class="d-flex flex-column">
                  <div class="d-flex mt-3 mt-md-0 ">
                    <span class="fw-medium">Nombre: </span>
                    <span class="ms-2 text-muted">{{this.cultivador.nombre + ' ' + this.cultivador.apellidos}}</span>
                  </div>
                  <div class="d-flex">
                    <span class="fw-medium">Correo: </span>
                    <span class="ms-2 text-muted">{{this.cultivador.correo}}</span>
                  </div>
                  <div class="d-flex">
                    <span class="fw-medium">Teléfono: </span>
                    <span class="ms-2 text-muted">{{this.cultivador.telefono}}</span>
                  </div>
                  <div class="d-flex">
                    <span class="fw-medium">Fecha de nacimiento: </span>
                    <span class="ms-2 text-muted">{{this.cultivador.fechaNacimiento}}</span>
                  </div>
                  <div class="d-flex">
                    <span class="fw-medium">Departamento: </span>
                    <span class="ms-2 text-muted">{{this.cultivador.departamentoDato.nombre}}</span>
                  </div>
                  <div class="d-flex">
                    <span class="fw-medium">Ubicación: </span>
                    <span class="ms-2 text-muted">
                      <a :href="`https://www.google.com/maps?q=${this.cultivador.latitud},${this.cultivador.longitud}`" target="_blank">abrir</a>
                    </span>
                  </div>
                  <div class="d-flex">
                    <span class="fw-medium me-2">Tipo de registro: </span>
                    <span v-if="this.cultivador.redSocial == null" class="badge text-bg-info">normal</span>
                    <span v-else-if="this.cultivador.redSocial === 'Gmail'" class="badge text-bg-danger">Gmail</span>
                  </div>
                  <div class="d-flex">
                    <span class="fw-medium me-2">Estado: </span>
                    <span v-if="this.cultivador.verificado" class="badge text-bg-success">verificado</span>
                    <span v-else class="badge text-bg-danger">sin verificar</span>
                  </div>
                </div>
                <div class="d-flex">
                    <span class="text-muted">Registrado el {{this.cultivador.fechaHoraRegistrado}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-6">
          <usuario-cambiar-estado-component :persona="{id: this.$route.params.id}"
                                            :verificado="this.cultivador.verificado" @onCultivadorVerificado="onCultivadorVerificado"/>
        </div>
        <div class="col-12 col-md-6">
          <usuario-cambiar-contrasena-component :persona="{id: this.$route.params.id}" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Service from "@/utils/Service";
import {route} from "@/utils/Route";
import UsuarioCambiarContrasenaComponent
  from "@/modules/administracion/components/cultivador/usuario/CambiarContrasenaComponent.vue";
import UsuarioCambiarEstadoComponent
  from "@/modules/administracion/components/cultivador/usuario/CambiarEstadoComponent.vue";
import router from "@/router";
import MensajeAlertaComponent from "@/components/utils/MensajeAlerta.vue";

export default {
  name: 'CultivadorMostrarView',
  components: {MensajeAlertaComponent, UsuarioCambiarEstadoComponent, UsuarioCambiarContrasenaComponent},
  data() {
    return {
      error: {
        mostrar: false,
        mensaje: null,
      },
      cultivador: {
        nombre: '',
        apellidos: '',
        fechaNacimiento: '',
        urlFoto: '',
        correo: '',
        telefono: '',
        redSocial: null,
        verificado: false,
        fechaHoraRegistrado: '',
        departamentoDato: {},
        ubicacionDato: {},
        usuarioDato: {
          personaDato: {
            nombre: '',
            apellidos: ''
          }
        }
      }
    }
  },
  methods: {
    router() {
      return router
    },
    mostrarCultivador() {
      this.ocultarError();
      Service.get(route('cultivador.mostrar', this.getParametrosMostrar())).then((data) => {
        this.cultivador = data.data;
      }).catch((error) => {
        this.mostrarError(error);
      });
    },

    getParametrosMostrar() {
      return [this.$route.params.id]
    },

    onCultivadorVerificado(verificado) {
      this.cultivador.verificado = verificado;
    },

    ocultarError() {
      this.error.mostrar = false;
      this.error.mensaje = '';
    },
    mostrarError(error) {
      this.error.mostrar = true;
      this.error.mensaje = error;
    },
  },
  mounted() {
    this.mostrarCultivador();
  }
}
</script>