<template>
  <div class="card">
    <div class="card-body">
      <div class="card-toolbar d-flex flex-nowrap justify-content">
        <div class="position-relative me-2 my-4">
          <span class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6">
            <i class="bi bi-search" />
          </span>
          <input v-model="this.busqueda" type="text" class="form-control ps-10" placeholder="Buscar">
        </div>
        <div class="my-4">
          <button @click="filtrarBusqueda()" class="btn btn-primary">
            Buscar
          </button>
        </div>
      </div>

      <div class="table-responsive table-card">
        <table class="table table-hover table-nowrap mb-0">
          <thead>
          <tr>
            <th scope="col">Nombre</th>
            <th scope="col">Correo</th>
            <th scope="col" class="text-center">Teléfono</th>
            <th scope="col" class="text-center">Departamento</th>
            <th scope="col" class="text-center">Gasto</th>
            <th scope="col" class="text-center">Venta</th>
            <th scope="col" class="text-center">Tipo de registro</th>
            <th scope="col" class="text-center">Estado</th>
            <th scope="col" class="text-center">Registrado</th>
            <th scope="col" class="text-center">Acciones</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="cultivador in this.filas" :key="cultivador.id">
            <td class="text-start">
              <span class="text-dark fs-6">{{cultivador.nombre + ' ' + cultivador.apellidos}}</span>
            </td>
            <td class="text-start">
              <span class="text-dark fs-6">{{cultivador.correo}}</span>
            </td>
            <td class="text-start">
              <span class="text-dark fs-6">{{cultivador.telefono}}</span>
            </td>
            <td class="text-center">
              <span class="text-dark fs-6">{{cultivador.departamentoDato.nombre}}</span>
            </td>
            <td class="text-center">
              <span class="text-dark fs-6">{{cultivador.gasto + ' BOB'}}</span>
            </td>
            <td class="text-center">
              <span class="text-dark fs-6">{{cultivador.venta + ' BOB'}}</span>
            </td>
            <td class="text-center">
              <span v-if="cultivador.redSocial === null" class="fs-6 badge text-bg-info">Normal</span>
              <span v-else-if="cultivador.redSocial === 'Gmail'" class="fs-6 badge text-bg-danger">Gmail</span>
            </td>
            <td class="text-center">
              <span v-if="cultivador.verificado" class="fs-6 badge text-bg-success">Verificado</span>
              <span v-else class="fs-6 badge text-bg-danger">Sin verificar</span>
            </td>
            <td class="text-center">
              <span class="text-dark fs-6">{{cultivador.fechaHoraRegistrado}}</span>
            </td>
            <td class="text-center">
              <div class="dropdown">
                <a href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="mdi mdi-dots-vertical"></i>
                </a>
                <ul class="dropdown-menu dropdown-menu-end" data-popper-placement="bottom-end">
                  <li>
                    <router-link :to="{name: 'cultivador.mostrar', params: {id: cultivador.id}}" class="dropdown-item">
                      <i class="mdi mdi-eye align-bottom me-2 text-muted"></i> Detalle
                    </router-link>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <Paginacion ref="tablaCultivadorPaginacion" @calcularFilasMostrar="calcularFilasMostrar()"/>
    </div>
  </div>

</template>
<script>
import Paginacion from "@/components/utils/Paginacion.vue";
import TablaFila from "@/utils/TablaFila";
export default {
  name: 'TablaCultivadorListarComponent',
  components: {Paginacion},
  data() {
    return {
      cultivadores: [],
      filtro: [],
      filas: [],
      busqueda: "",
    };
  },
  watch: {
    busqueda: function () {
      this.filtrarBusqueda();
    }
  },

  methods: {
    setCultivadores(cultivadores) {
      this.cultivadores = cultivadores;
      this.filtro = cultivadores;
      this.$refs.tablaCultivadorPaginacion.reiniciarPaginacion(this.filtro.length);
      this.calcularFilasMostrar();
    },
    calcularFilasMostrar() {
      let pagina = this.$refs.tablaCultivadorPaginacion.getPagina();
      let cantidad = this.$refs.tablaCultivadorPaginacion.getCantidad()
      this.filas = TablaFila.getFilasPagina(this.filtro, pagina, cantidad);
    },
    filtrarBusqueda() {
      this.filtro = TablaFila.getBusquedaFila(this.cultivadores, this.busqueda, ['nombre', 'correo']);
      this.$refs.tablaCultivadorPaginacion.reiniciarPaginacion(this.filtro.length);
      this.calcularFilasMostrar();
    },
  },

}
</script>
