import store from '../store';

export default class Permission {

    hasCasoUso(casoUso) {
        let permisos = store.getters.getPermisos;
        for(let i = 0; i < permisos.length; i++) {
            let permiso = permisos[i];
            if (permiso.slug.includes(casoUso)) {
                return true;
            }
        }
        return false;
    }

    hasAccion(casoUso, accion) {
        let permisos = store.getters.getPermisos;
        for(let i = 0; i < permisos.length; i++) {
            let permiso = permisos[i];
            if (permiso.slug === (casoUso + '.' + accion)) {
                return true;
            }
        }
        return false;
    }
}
