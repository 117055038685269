<template>
  <div class="container">
    <h3 class="mb-2">Registrar un administrador</h3>
    <div class="card">
      <div class="card-body">
        <form v-on:submit.prevent="registrarAdministrador()" id="form">
          <div class="d-flex flex-column">
            <mensaje-alerta-component :error="this.error" class="mb-2" />
            <div class="row mb-2">
              <div class="col-12 col-md-6">
                <label for="nombre" class="form-label">Nombre <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <input v-model="this.form.nombre" id="nombre" type="text" class="form-control" maxlength="25" :disabled="!this.form.activo" required>
              </div>
              <div class="col-12 col-md-6 mt-2 mt-md-0">
                <label for="apellidos" class="form-label">Apellidos <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <input v-model="this.form.apellidos" id="apellidos" type="text" class="form-control" maxlength="50" :disabled="!this.form.activo" required>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12 col-md-6">
                <label for="fechaNacimiento" class="form-label">Fecha de nacimiento <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <input v-model="this.form.fechaNacimiento" id="fechaNacimiento" type="date" class="form-control" :disabled="!this.form.activo" required>
              </div>
              <div class="col-12 col-md-6 mt-2 mt-md-0">
                <label for="carnetIdentidad" class="form-label">Carnet de identidad <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <input v-model="this.form.carnetIdentidad" id="carnetIdentidad" type="number" class="form-control" maxlength="10" :disabled="!this.form.activo" required>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12 col-md-6">
                <label for="correo" class="form-label">Correo <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <input v-model="this.form.correo" id="correo" type="text" class="form-control" maxlength="50" :disabled="!this.form.activo" required>
              </div>
              <div class="col-12 col-md-6 mt-2 mt-md-0">
                <label for="telefono" class="form-label">Teléfono <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <input v-model="this.form.telefono" id="telefono" type="number" class="form-control" maxlength="10" :disabled="!this.form.activo" required>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-12 col-md-6">
                <label for="foto" class="form-label">Foto de perfil (512 x 512 pixeles) <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <input @change="onChangeInputFoto($event)" id="foto" class="form-control" type="file" accept="image/jpeg,image/png"
                       :disabled="!this.form.activo" required>
              </div>
            </div>
          </div>
          <div class="d-flex flex-row-reverse">
            <button :disabled="!this.form.activo" type="submit" class="btn btn-primary waves-effect waves-light">Registrar</button>
            <a @click="router().back()" class="btn btn-soft-secondary waves-effect waves-light me-2">Atrás</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import Service from "@/utils/Service";
import {route} from "@/utils/Route";
import Toast from "@/utils/Toast";
import router from "@/router";
import MensajeAlertaComponent from "@/components/utils/MensajeAlerta.vue";

export default {
  name: 'AdministradorCrearView',
  components: {MensajeAlertaComponent},
  data() {
    return {
      error: {
        mostrar: false,
        mensaje: null,
      },
      form: {
        activo: true,
        nombre: '',
        apellidos: '',
        fechaNacimiento: '',
        carnetIdentidad: '',
        correo: '',
        telefono: '',
        foto: null,
      }
    }
  },
  methods: {
    router() {
      return router
    },
    registrarAdministrador() {
      this.desactivarForm();
      this.ocultarError();
      Service.postFormData(route('administrador.registrar'), this.getParametros()).then( (data)=>{
        Toast.showSuccess(data.mensaje)
        router.push({name: 'administrador.listar'});
      }).catch( error => {
        this.mostrarError(error);
      }). finally( () => {
        this.activarForm();
      });
    },

    onChangeInputFoto(event) {
      this.form.foto = event.target.files[0];
    },

    getParametros() {
      let formData = new FormData();
      formData.append('nombre', this.form.nombre);
      formData.append('apellidos', this.form.apellidos);
      formData.append('fechaNacimiento', this.form.fechaNacimiento);
      formData.append('correo', this.form.correo);
      formData.append('telefono', this.form.telefono);
      formData.append('carnetIdentidad', this.form.carnetIdentidad);
      formData.append('foto', this.form.foto);

      return formData;
    },

    desactivarForm() {
      this.form.activo = false;
    },
    activarForm() {
      this.form.activo = true;
    },

    ocultarError() {
      this.error.mostrar = false;
      this.error.mensaje = '';
    },
    mostrarError(error) {
      this.error.mostrar = true;
      this.error.mensaje = error;
    },
  }
}
</script>