<template>
  <div class="d-flex">
    <div class="flex-fill">
      <label for="tipoRiego" class="form-label">Tipo de Riego</label>
      <select v-model="this.form.tipoRiego" @change="onChangeTipoRiego($event)" id="tipoRiego"
              class="form-select">
        <option value="0">Todos</option>
        <option v-for="tipoRiego in this.lista.tipoRiegos" :key="tipoRiego.id"
                :value="tipoRiego.id">{{tipoRiego.nombre}}
        </option>
      </select>
    </div>
  </div>
</template>
<script>
import Service from "@/utils/Service";
import {route} from "@/utils/Route";
import router from "@/router";

export default {
  name: 'SelectTipoRiegoComponent',
  data() {
    return {
      lista: {
        tipoRiegos: []
      },
      form: {
        tipoRiego: 0
      }
    }
  },
  methods: {
    listarTipoRiego() {
      Service.get(route('recurso.tipo_riego')).then( data => {
        this.lista.tipoRiegos = data.data;
      }).catch( error => {
        console.log(error);
      });
    },
    onChangeTipoRiego(event) {
      if (event.target.value > 0) {
        let params = {...this.$route.query, tipoRiego: this.form.tipoRiego};
        router.push({ path: this.$route.path, query: params });
      }
    },
  },
  mounted() {
    this.listarTipoRiego();
    if (this.$route.query.tipoRiego != undefined)
      this.form.tipoRiego = this.$route.query.tipoRiego;
  }
}
</script>