<template>
  <div class="card">
    <div class="card-body">
      <div class="card-toolbar d-flex flex-nowrap justify-content">
        <div class="position-relative me-2 my-4">
          <span class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6">
            <i class="bi bi-search" />
          </span>
          <input v-model="this.busqueda" type="text" class="form-control ps-10" placeholder="Buscar">
        </div>
        <div class="my-4">
          <button @click="filtrarBusqueda()" class="btn btn-primary">
            Buscar
          </button>
        </div>
      </div>

      <div class="table-responsive table-card">
        <table class="table table-hover table-nowrap mb-0">
          <thead>
          <tr>
            <th scope="col">Red Social</th>
            <th scope="col">Nombre</th>
            <th scope="col" class="text-center">Departamento</th>
            <th scope="col" class="text-start">Enlace</th>
            <th scope="col" class="text-center">Acciones</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="redSocial in this.filas" :key="redSocial.id">
            <td class="text-start">
              <span class="text-dark fs-6">{{redSocial.tipoRedSocialDato.nombre}}</span>
            </td>
            <td class="text-start">
              <span class="text-dark fs-6">{{ redSocial.nombre }}</span>
            </td>
            <td class="text-center">
              <span class="text-dark fs-6">{{ redSocial.departamentoDato.nombre }}</span>
            </td>
            <td class="text-start">
              <a :href="redSocial.url" target="_blank" class="fs-6">{{ redSocial.url }}</a>
            </td>
            <td class="text-center">
              <div class="dropdown">
                <a href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="mdi mdi-dots-vertical"></i>
                </a>
                <ul class="dropdown-menu dropdown-menu-end" data-popper-placement="bottom-end">
                  <li>
                    <router-link :to="{name: 'redsocial.editar', params: {id: redSocial.id}}" class="dropdown-item">
                      <i class="mdi mdi-file-document-edit-outline align-bottom me-2 text-muted"></i> Editar
                    </router-link>
                  </li>
                  <li class="dropdown-divider"></li>
                  <li><a @click="onRedSocialEliminar(redSocial)" class="dropdown-item" href="javascript:void(0)"
                         data-bs-toggle="modal" data-bs-target="#modalRedSocialEliminar">
                    <i class="mdi mdi-delete align-bottom me-2 text-muted"></i> Eliminar</a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <Paginacion ref="tablaRedSocialPaginacion" @calcularFilasMostrar="calcularFilasMostrar()"/>
    </div>
  </div>

</template>
<script>
import Paginacion from "@/components/utils/Paginacion.vue";
import TablaFila from "@/utils/TablaFila";
export default {
  name: 'TablaRedSocialListarComponent',
  components: {Paginacion},
  data() {
    return {
      redSociales: [],
      filtro: [],
      filas: [],
      busqueda: "",
    };
  },
  watch: {
    busqueda: function () {
      this.filtrarBusqueda();
    }
  },

  methods: {
    setRedSociales(redSociales) {
      this.redSociales = redSociales;
      this.filtro = redSociales;
      this.$refs.tablaRedSocialPaginacion.reiniciarPaginacion(this.filtro.length);
      this.calcularFilasMostrar();
    },
    calcularFilasMostrar() {
      let pagina = this.$refs.tablaRedSocialPaginacion.getPagina();
      let cantidad = this.$refs.tablaRedSocialPaginacion.getCantidad()
      this.filas = TablaFila.getFilasPagina(this.filtro, pagina, cantidad);
    },
    filtrarBusqueda() {
      this.filtro = TablaFila.getBusquedaFila(this.redSociales, this.busqueda, ['nombre']);
      this.$refs.tablaRedSocialPaginacion.reiniciarPaginacion(this.filtro.length);
      this.calcularFilasMostrar();
    },

    onRedSocialEliminar(redSocial) {
      this.$emit('onRedSocialEliminar', redSocial);
    }
  },

}
</script>
