<template>
  <div>
    <div class="mb-2"><h3>Listar Guía de Riego</h3></div>
    <div class="d-flex flex-column flex-xxl-row">
      <div class="d-flex flex-column flex-sm-row">
        <select-departamento-component class="m-md-1"/>
        <select-tipo-produccion-component class="m-md-1"/>
      </div>
      <div class="d-flex flex-column flex-md-row justify-content-between flex-fill">
        <div class="d-flex flex-column flex-sm-row">
          <select-tipo-cultivo-component class="m-md-1 mb-2 mb-sm-0" />
          <select-tipo-riego-component class="m-md-1" />
        </div>
        <div class="d-flex align-items-center justify-content-end mt-2">
          <div class="dropdown">
            <button class="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
              Exportar
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" :href="routeQuery('reporte.guia.riego.excel', this.$route.query)" target="_blank">Excel</a>
            </div>
          </div>
          <router-link :to="{name: 'guia.riego.crear'}" class="btn btn-success waves-effect waves-light ms-2">
            Agregar Nuevo
          </router-link>
        </div>
      </div>
    </div>
    <mensaje-alerta-component :error="this.error" class="my-2" />
    <tabla-guia-riego-listar ref="tablaGuiaRiegoListar" @onGuiaRiegoEliminar="onGuiaRiegoEliminar" class="mt-2 mt-xxl-0"/>
    <modal-guia-riego-eliminar :id="this.guiaRiego.id" :titulo="this.guiaRiego.titulo" @onGuiaRiegoEliminado="onGuiaRiegoEliminado"/>
  </div>
</template>
<script>
import TablaGuiaRiegoListar from '@/modules/guia/components/riego/TablaListarComponent.vue';
import SelectDepartamentoComponent from '@/modules/guia/components/utils/SelectDepartamentoComponent.vue';
import SelectTipoProduccionComponent from "@/modules/guia/components/utils/SelectTipoProduccionComponent.vue";
import SelectTipoCultivoComponent from "@/modules/guia/components/utils/SelectTipoCultivoComponent.vue";
import SelectTipoRiegoComponent from "@/modules/guia/components/utils/SelectTipoRiegoComponent.vue";
import Service from "@/utils/Service";
import {route, routeQuery} from "@/utils/Route";
import ModalGuiaRiegoEliminar from "@/modules/guia/components/riego/ModalEliminarComponent.vue";
import MensajeAlertaComponent from "@/components/utils/MensajeAlerta.vue";

export default {
  name: 'GuiaRiegoListarView',
  components: {
    MensajeAlertaComponent,
    ModalGuiaRiegoEliminar,
    SelectTipoCultivoComponent, SelectTipoProduccionComponent, SelectDepartamentoComponent,
    SelectTipoRiegoComponent, TablaGuiaRiegoListar
  },
  data() {
    return {
      error: {
        mostrar: false,
        mensaje: null,
      },
      guias: [],
      guiaRiego: {
        id: 0,
        titulo: ''
      }
    }
  },
  methods: {
    routeQuery,
    listarGuiaRiego() {
      this.ocultarError();
      Service.get(this.getRutaParams()).then((data) => {
        this.guias = data.data;
        this.$refs.tablaGuiaRiegoListar.setGuias(this.guias);
      }).catch((error) => {
        this.mostrarError(error);
      });
    },

    getRutaParams() {
      let ruta = route('guia.riego.listar');
      let departamento = 0;
      let tipoProduccion = 0;
      let tipoCultivo = 0;
      let cultivo = 0;
      let tipoRiego = 0;
      if (this.$route.query.departamento !== undefined) {
        departamento = this.$route.query.departamento;
      }
      if (this.$route.query.tipoProduccion !== undefined) {
        tipoProduccion = this.$route.query.tipoProduccion;
      }
      if (this.$route.query.tipoCultivo !== undefined) {
        tipoCultivo = this.$route.query.tipoCultivo;
      }
      if (this.$route.query.cultivo !== undefined) {
        cultivo = this.$route.query.cultivo;
      }
      if (this.$route.query.tipoRiego !== undefined) {
        tipoRiego = this.$route.query.tipoRiego;
      }
      return ruta + '?tipoProduccion=' + tipoProduccion +'&departamento=' + departamento + '&tipoCultivo='
          + tipoCultivo + '&cultivo=' + cultivo + '&tipoRiego=' + tipoRiego;
    },

    onGuiaRiegoEliminar(id, titulo) {
      this.guiaRiego.id = id;
      this.guiaRiego.titulo = titulo;
    },

    onGuiaRiegoEliminado() {
      this.listarGuiaRiego();
    },

    ocultarError() {
      this.error.mostrar = false;
      this.error.mensaje = '';
    },
    mostrarError(error) {
      this.error.mostrar = true;
      this.error.mensaje = error;
    }
  },

  mounted() {
    this.listarGuiaRiego();
  },
  updated() {
    this.listarGuiaRiego();
  }
}
</script>