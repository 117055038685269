<template>
  <div v-if="this.error.mostrar" class="row">
    <div class="col-12">
      <div class="alert alert-borderless alert-danger" role="alert">
        <div v-if="typeof this.error.mensaje === 'string' ">
          {{this.error.mensaje}}
        </div>
        <div v-else-if="this.error.mensaje instanceof Array">
          <ul>
            <li v-for="(m, index) in this.error.mensaje" v-bind:key="index"> {{m}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MensajeAlertaComponent',
  props: {
    error: {
      mostrar: {
        type: Boolean,
        required: true
      },
      mensaje: {
        type: Object,
        required: true
      }
    }
  }
}
</script>