<template>
  <header id="page-topbar" class="bg-white">
    <div class="layout-width border-bottom">
      <div class="navbar-header d-flex justify-content-between">
        <div>
          <button id="topnav-hamburger-icon" type="button"
                  class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger d-sm-none"
            data-bs-toggle="offcanvas" data-bs-target="#navBarMenu">
            <span class="hamburger-icon">
                <span></span>
                <span></span>
                <span></span>
            </span>
          </button>
        </div>
        <div class="d-flex align-items-center">
          <div class="position-relative p-0">
            <router-link :to="{name: 'chat.listar'}" class="btn btn-icon btn-topbar btn-ghost-dark rounded-circle">
              <i class="ri-message-line f-22"></i>
            </router-link>
            <span v-if="this.salas.length > 0" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{{this.salas.length}} <span class="visually-hidden">unread messages</span></span>
          </div>

          <div v-if="$store.getters.estaAutenticado" class="dropdown ms-sm-3 header-item topbar-user">
            <button type="button" class="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span class="d-flex align-items-center">
                <img class="rounded-circle header-profile-user" :src="$store.getters.getUsuario.urlFoto" alt="Header Avatar">
                <span class="text-start ms-xl-2">
                    <span v-text="$store.getters.getUsuario.nombre" class="d-none d-xl-inline-block ms-1 fw-medium user-name-text"></span>
                    <span v-text="$store.getters.getUsuario.correo" class="d-none d-xl-block ms-1 fs-6 text-muted user-name-sub-text"></span>
                </span>
              </span>
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <h6 class="dropdown-header">Bienvenido</h6>
              <a class="dropdown-item" href="#"><i class="ri-account-circle-line text-muted fs-16 align-middle me-1"></i> <span class="align-middle">Perfil</span></a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#"><i class="ri-logout-box-r-line text-muted fs-16 align-middle me-1"></i> <span class="align-middle" data-key="t-logout">Cerrar Sesión</span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import PocketBase from "pocketbase";
import {CHAT_SERVER_URL} from "@/utils/config";

export default {
  name: 'NavBar',
  data() {
    return {
      salas: [],
    }
  },
  methods: {
    async escucharSalas() {
      const pb = new PocketBase(CHAT_SERVER_URL);
      const self = this;
      await pb.collection('room').subscribe('*', function ()  {
        self.consultarSalas();
      }).then(r => {
        console.log(r);
      });

    },
    async consultarSalas() {
      const pb = new PocketBase(CHAT_SERVER_URL);
      this.salas = await pb.collection('room').getFullList({
        filter: 'hasNewUserMessage = true',
      });
    },
  },
  mounted() {
    this.consultarSalas();
    this.escucharSalas();
  }
}
</script>
