export const URL_API = process.env.VUE_APP_URL_API
export const URL_REPORTE = process.env.VUE_APP_URL_REPORTE
export const GOOGLE_MAP_KEY = process.env.VUE_APP_GOOGLE_MAP_KEY
export const CHAT_SERVER_URL = process.env.VUE_APP_CHAT_SERVER_URL

export const tinyMCE = {
    key: process.env.VUE_APP_TINYMCE_KEY,
    init: {
        plugins: process.env.VUE_APP_TINYMCE_PLUGINS,
        toolbar: process.env.VUE_APP_TINYMCE_TOOLBAR,
        images_upload_url: process.env.VUE_APP_TINYMCE_IMAGES_UPLOAD_URL,
        automatic_uploads: process.env.VUE_APP_TINYMCE_AUTOMATIC_UPLOADS
    }
}

