<template>
  <div class="d-flex">
    <div class="flex-fill">
      <label for="zona" class="form-label">Zona</label>
      <select v-model="this.form.zona" @change="onChangeZona($event)" id="zona"
              class="form-select">
        <option value="0">Todos</option>
        <option v-for="zona in this.lista.zonas" :key="zona.id"
                :value="zona.id">{{zona.nombre}}
        </option>
      </select>
    </div>
  </div>
</template>
<script>
import Service from "@/utils/Service";
import {route} from "@/utils/Route";
import router from "@/router";


export default {
  name: 'SelectZonaComponent',
  data() {
    return {
      lista: {
        zonas: []
      },
      form: {
        zona: 0
      }
    }
  },
  methods: {
    listarZona() {
      Service.get(route('recurso.zona')).then( data => {
        this.lista.zonas = data.data;
      }).catch( error => {
        console.log(error);
      });
    },
    onChangeZona(event) {
      if (event.target.value > 0) {
        let params = {...this.$route.query, zona: this.form.zona};
        router.push({ path: this.$route.path, query: params });
      }
    },
  },
  mounted() {
    this.listarZona();
    if (this.$route.query.zona !== undefined)
      this.form.zona = this.$route.query.zona;
  }
}
</script>