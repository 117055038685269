<template>
  <div>
    <div class="app-menu navbar-menu d-none d-sm-block">
      <div class="navbar-brand-box p-0" style="background-color: #09733C">
        <router-link to="/home" class="logo logo-dark w-100">
          <span class="d-block d-md-none">
            <img src="assets/images/logo-sm.png" class="w-100" alt="MiPuntoVerde">
          </span>
          <span class="d-none d-md-block">
            <img src="assets/images/logo-dark.png" class="w-100" alt="MiPuntoVerde">
          </span>
        </router-link>

        <router-link to="/home" class="logo logo-light w-100">
        <span class="d-block d-md-none">
          <img src="assets/images/logo-sm.png" class="w-100" alt="MiPuntoVerde">
        </span>
          <span class="d-none d-md-block">
          <img src="assets/images/logo-light.png" class="w-100" alt="MiPuntoVerde" >
        </span>
        </router-link>
      </div>

      <div id="scrollbar">
        <div class="container-fluid">
          <div id="two-column-menu"></div>
          <ul class="navbar-nav" id="navbar-nav">
            <li class="menu-title align-self-start"><span data-key="t-menu">Menu</span></li>

            <li class="nav-item">
              <router-link :to="{name: 'home'}" class="nav-link menu-link" data-key="t-crm">
                <i class="ri-home-line"></i> <span data-key="t-dashboards">Principal</span>
              </router-link>
            </li>

            <li class="nav-item">
              <a class="nav-link menu-link" href="#sidebarHuerto" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarHuerto">
                <i class="mdi mdi-select-marker"></i> <span data-key="t-guias">Huerto</span>
              </a>
              <div class="collapse menu-dropdown" id="sidebarHuerto">
                <ul class="nav nav-sm flex-column">
                  <li v-if="this.permiso.hasAccion('huerto', 'l')" class="nav-item">
                    <router-link :to="{name: 'huerto.listar'}" class="nav-link menu-link" data-key="t-crm">
                      <span data-key="t-huerto">Listar</span>
                    </router-link>
                  </li>
                  <li v-if="this.permiso.hasAccion('huerto', 'l')" class="nav-item">
                    <router-link :to="{name: 'huerto.mapa'}" class="nav-link menu-link" data-key="t-huerto-mapa">
                      <span data-key="t-huerto-mapa">Mapa</span>
                    </router-link>
                  </li>
                </ul>
              </div>
            </li>

            <li class="nav-item">
              <a class="nav-link menu-link" href="#sidebarGuia" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarGuia">
                <i class="ri-booklet-line"></i> <span data-key="t-guias">Guías</span>
              </a>
              <div class="collapse menu-dropdown" id="sidebarGuia">
                <ul class="nav nav-sm flex-column">
                  <li v-if="this.permiso.hasAccion('guia_produccion', 'l')" class="nav-item">
                    <router-link :to="{name: 'guia.produccion.listar'}" class="nav-link" data-key="t-produccion">
                      Guía de Producción
                    </router-link>
                  </li>
                  <li v-if="this.permiso.hasAccion('guia_riego', 'l')" class="nav-item">
                    <router-link :to="{name: 'guia.riego.listar'}" class="nav-link" data-key="t-riego">
                      Guía de Riego
                    </router-link>
                  </li>
                  <li v-if="this.permiso.hasAccion('guia_plaga', 'l')" class="nav-item">
                    <router-link :to="{name: 'guia.plaga.listar'}" class="nav-link" data-key="t-plaga">
                      Guía de Plaga
                    </router-link>
                  </li>
                  <li v-if="this.permiso.hasAccion('guia_tratamiento', 'l')" class="nav-item">
                    <router-link :to="{name: 'guia.tratamiento.listar'}" class="nav-link" data-key="t-tratamiento">
                      Guía de Tratamiento
                    </router-link>
                  </li>
                  <li v-if="this.permiso.hasAccion('guia_planificacion', 'l')" class="nav-item">
                    <router-link :to="{name: 'guia.planificacion.listar'}" class="nav-link" data-key="t-planificacion">
                      Guía de Planificación
                    </router-link>
                  </li>
                </ul>
              </div>
            </li>

            <li class="nav-item">
              <a class="nav-link menu-link" href="#sidebarActividad" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarActividad">
                <i class="mdi mdi-calendar-clock-outline"></i> <span data-key="t-actividad">Actividades</span>
              </a>
              <div class="collapse menu-dropdown" id="sidebarActividad">
                <ul class="nav nav-sm flex-column">
                  <li v-if="this.permiso.hasAccion('actividad', 'l')" class="nav-item">
                    <router-link :to="{name: 'actividad.listar'}" class="nav-link" data-key="t-actividad">
                      Evento y Feria
                    </router-link>
                  </li>
                  <li v-if="this.permiso.hasAccion('alerta', 'l')" class="nav-item">
                    <router-link :to="{name: 'alerta.listar'}" class="nav-link" data-key="t-alerta">
                      Alertas
                    </router-link>
                  </li>
                </ul>
              </div>
            </li>

            <li class="nav-item">
              <a class="nav-link menu-link" href="#sidebarAdministracion" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarAdministracion">
                <i class="mdi mdi-account-lock-outline"></i> <span data-key="t-administracion">Administración</span>
              </a>
              <div class="collapse menu-dropdown" id="sidebarAdministracion">
                <ul class="nav nav-sm flex-column">
                  <li v-if="this.permiso.hasAccion('administrador', 'l')" class="nav-item">
                    <router-link :to="{name: 'administrador.listar'}" class="nav-link menu-link" data-key="t-administrador">
                      Administrador
                    </router-link>
                  </li>
                  <li v-if="this.permiso.hasAccion('cultivador', 'l')" class="nav-item">
                    <router-link :to="{name: 'cultivador.listar'}" class="nav-link menu-link" data-key="t-cultivador">
                      Cultivador
                    </router-link>
                  </li>
                  <li v-if="this.permiso.hasAccion('chat', 'l')" class="nav-item">
                    <router-link :to="{name: 'chat.listar'}" class="nav-link menu-link" data-key="t-cultivo">
                      Chat
                    </router-link>
                  </li>
                  <li v-if="this.permiso.hasAccion('cultivo', 'l')" class="nav-item">
                    <router-link :to="{name: 'cultivo.listar'}" class="nav-link menu-link" data-key="t-cultivo">
                      Cultivo
                    </router-link>
                  </li>
                  <li v-if="this.permiso.hasAccion('red_social', 'l')" class="nav-item">
                    <router-link :to="{name: 'redsocial.listar'}" class="nav-link menu-link" data-key="t-redsocial">
                      Redes Sociales
                    </router-link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="sidebar-background"></div>
    </div>

    <div class="d-block d-sm-none offcanvas offcanvas-start" tabindex="-1" id="navBarMenu">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasExampleLabel">Menu</h5>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <ul class="navbar-nav" id="navbar-nav">
          <li class="nav-item" data-bs-dismiss="offcanvas">
            <router-link :to="{name: 'home'}" class="nav-link menu-link" data-key="t-crm">
              <i class="ri-home-line"></i> <span data-key="t-dashboards">Principal</span>
            </router-link>
          </li>

          <li class="nav-item">
            <a class="nav-link menu-link" href="#sidebarHuerto" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarHuerto">
              <i class="mdi mdi-select-marker"></i> <span data-key="t-guias">Huerto</span>
            </a>
            <div class="collapse menu-dropdown" id="sidebarHuerto">
              <ul class="nav nav-sm flex-column">
                <li v-if="this.permiso.hasAccion('huerto', 'l')" class="nav-item" data-bs-dismiss="offcanvas">
                  <router-link :to="{name: 'huerto.listar'}" class="nav-link menu-link" data-key="t-crm">
                    <span data-key="t-huerto">Listar</span>
                  </router-link>
                </li>
                <li v-if="this.permiso.hasAccion('huerto', 'l')" class="nav-item" data-bs-dismiss="offcanvas">
                  <router-link :to="{name: 'huerto.mapa'}" class="nav-link menu-link" data-key="t-huerto-mapa">
                    <span data-key="t-huerto-mapa">Mapa</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li class="nav-item">
            <a class="nav-link menu-link" href="#sidebarGuia" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarGuia">
              <i class="ri-booklet-line"></i> <span data-key="t-guias">Guías</span>
            </a>
            <div class="collapse menu-dropdown" id="sidebarGuia">
              <ul class="nav nav-sm flex-column">
                <li v-if="this.permiso.hasAccion('guia_produccion', 'l')" class="nav-item" data-bs-dismiss="offcanvas">
                  <router-link :to="{name: 'guia.produccion.listar'}" class="nav-link" data-key="t-produccion">
                    Guía de Producción
                  </router-link>
                </li>
                <li v-if="this.permiso.hasAccion('guia_riego', 'l')" class="nav-item" data-bs-dismiss="offcanvas">
                  <router-link :to="{name: 'guia.riego.listar'}" class="nav-link" data-key="t-riego">
                    Guía de Riego
                  </router-link>
                </li>
                <li v-if="this.permiso.hasAccion('guia_plaga', 'l')" class="nav-item" data-bs-dismiss="offcanvas">
                  <router-link :to="{name: 'guia.plaga.listar'}" class="nav-link" data-key="t-plaga">
                    Guía de Plaga
                  </router-link>
                </li>
                <li v-if="this.permiso.hasAccion('guia_tratamiento', 'l')" class="nav-item" data-bs-dismiss="offcanvas">
                  <router-link :to="{name: 'guia.tratamiento.listar'}" class="nav-link" data-key="t-tratamiento">
                    Guía de Tratamiento
                  </router-link>
                </li>
                <li v-if="this.permiso.hasAccion('guia_planificacion', 'l')" class="nav-item" data-bs-dismiss="offcanvas">
                  <router-link :to="{name: 'guia.planificacion.listar'}" class="nav-link" data-key="t-planificacion">
                    Guía de Planificación
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li class="nav-item">
            <a class="nav-link menu-link" href="#sidebarActividad" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarActividad">
              <i class="mdi mdi-calendar-clock-outline"></i> <span data-key="t-actividad">Actividades</span>
            </a>
            <div class="collapse menu-dropdown" id="sidebarActividad">
              <ul class="nav nav-sm flex-column">
                <li v-if="this.permiso.hasAccion('actividad', 'l')" class="nav-item" data-bs-dismiss="offcanvas">
                  <router-link :to="{name: 'actividad.listar'}" class="nav-link" data-key="t-actividad">
                    Evento y Feria
                  </router-link>
                </li>
                <li v-if="this.permiso.hasAccion('alerta', 'l')" class="nav-item" data-bs-dismiss="offcanvas">
                  <router-link :to="{name: 'alerta.listar'}" class="nav-link" data-key="t-alerta">
                    Alertas
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li class="nav-item">
            <a class="nav-link menu-link" href="#sidebarAdministracion" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarAdministracion">
              <i class="mdi mdi-account-lock-outline"></i> <span data-key="t-administracion">Administración</span>
            </a>
            <div class="collapse menu-dropdown" id="sidebarAdministracion">
              <ul class="nav nav-sm flex-column">
                <li v-if="this.permiso.hasAccion('administrador', 'l')" class="nav-item" data-bs-dismiss="offcanvas">
                  <router-link :to="{name: 'administrador.listar'}" class="nav-link menu-link" data-key="t-administrador">
                    Administrador
                  </router-link>
                </li>
                <li v-if="this.permiso.hasAccion('cultivador', 'l')" class="nav-item" data-bs-dismiss="offcanvas">
                  <router-link :to="{name: 'cultivador.listar'}" class="nav-link menu-link" data-key="t-cultivador">
                    Cultivador
                  </router-link>
                </li>
                <li v-if="this.permiso.hasAccion('chat', 'l')" class="nav-item" data-bs-dismiss="offcanvas">
                  <router-link :to="{name: 'chat.listar'}" class="nav-link menu-link" data-key="t-cultivo">
                    Chat
                  </router-link>
                </li>
                <li v-if="this.permiso.hasAccion('cultivo', 'l')" class="nav-item" data-bs-dismiss="offcanvas">
                  <router-link :to="{name: 'cultivo.listar'}" class="nav-link menu-link" data-key="t-cultivo">
                    Cultivo
                  </router-link>
                </li>
                <li v-if="this.permiso.hasAccion('red_social', 'l')" class="nav-item" data-bs-dismiss="offcanvas">
                  <router-link :to="{name: 'redsocial.listar'}" class="nav-link menu-link" data-key="t-redsocial">
                    Redes Sociales
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>

  </div>
</template>
<script>
import Permission from "@/utils/Permission";

export default {
  name: 'AsideNav',
  data() {
    return {
      permiso: new Permission()
    }
  },
  mounted() {
  },
  methods: {
    onCerrarSesion() {
    },

    isActivo(ruta) {
      let path = String(this.$route.path);
      return path.includes(ruta) ? 'active' : '';
    },

    isListaActivo(rutas) {
      let path = String(this.$route.path);
      for (let i = 0; i < rutas.length; i++) {
        if(path.includes(rutas[i])) {
          return 'hover show';
        }
      }
    },
  },

}
</script>
