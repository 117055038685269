<template>
  <div>
    <div class="mb-2"><h3>Listar guía de tratamiento</h3></div>
    <div class="d-flex flex-column flex-xxl-row">
      <div class="d-flex flex-column flex-sm-row">
        <select-departamento-component class="m-md-1"/>
        <select-tipo-produccion-component class="m-md-1"/>
      </div>
      <div class="d-flex flex-column flex-md-row justify-content-between flex-fill">
        <div class="d-flex flex-column flex-sm-row">
          <select-tipo-cultivo-component class="m-md-1 mb-2 mb-sm-0" />
          <div class="d-flex">
            <select-tipo-plaga-component class="flex-fill m-md-1 mb-sm-0" />
            <select-tipo-tratamiento-component class="flex-fill m-md-1 ms-2" />
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-end mt-2">
          <div class="dropdown">
            <button class="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
              Exportar
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" :href="routeQuery('reporte.guia.tratamiento.excel', this.$route.query)" target="_blank">Excel</a>
            </div>
          </div>
          <router-link :to="{name: 'guia.tratamiento.crear'}" class="btn btn-success waves-effect waves-light ms-2">
            Agregar Nuevo
          </router-link>
        </div>
      </div>
    </div>
    <mensaje-alerta-component :error="this.error" class="my-2" />
    <tabla-guia-tratamiento-listar ref="tablaGuiaTratamientoListar" @onGuiaTratamientoEliminar="onGuiaTratamientoEliminar" class="mt-2 mt-xxl-0"/>
    <modal-guia-tratamiento-eliminar :id="this.guiaTratamiento.id" :titulo="this.guiaTratamiento.titulo"
                                     @onGuiaTratamientoEliminado="onGuiaTratamientoEliminado"/>
  </div>
</template>
<script>
import TablaGuiaTratamientoListar from '@/modules/guia/components/tratamiento/TablaListarComponent.vue';
import SelectDepartamentoComponent from '@/modules/guia/components/utils/SelectDepartamentoComponent.vue';
import SelectTipoProduccionComponent from "@/modules/guia/components/utils/SelectTipoProduccionComponent.vue";
import SelectTipoCultivoComponent from "@/modules/guia/components/utils/SelectTipoCultivoComponent.vue";
import SelectTipoPlagaComponent from "@/modules/guia/components/utils/SelectTipoPlagaComponent.vue";
import SelectTipoTratamientoComponent from "@/modules/guia/components/utils/SelectTipoTratamientoComponent.vue";
import Service from "@/utils/Service";
import {route, routeQuery} from "@/utils/Route";
import ModalGuiaTratamientoEliminar from "@/modules/guia/components/tratamiento/ModalEliminarComponent.vue";
import MensajeAlertaComponent from "@/components/utils/MensajeAlerta.vue";

export default {
  name: 'GuiaTratamientoListarView',
  components: {
    MensajeAlertaComponent,
    ModalGuiaTratamientoEliminar,
    SelectTipoCultivoComponent, SelectTipoProduccionComponent, SelectDepartamentoComponent,
    SelectTipoPlagaComponent, SelectTipoTratamientoComponent, TablaGuiaTratamientoListar
  },
  data() {
    return {
      error: {
        mostrar: false,
        mensaje: null,
      },
      guias: [],
      guiaTratamiento: {
        id: 0,
        titulo: ''
      }
    }
  },
  methods: {
    routeQuery,
    listarGuiaTratamiento() {
      this.ocultarError();
      Service.get(this.getRutaParams()).then((data) => {
        this.guias = data.data;
        this.$refs.tablaGuiaTratamientoListar.setGuias(this.guias);
      }).catch((error) => {
        this.mostrarError(error);
      });
    },

    getRutaParams() {
      let ruta = route('guia.tratamiento.listar');
      let departamento = 0;
      let tipoProduccion = 0;
      let tipoCultivo = 0;
      let cultivo = 0;
      let tipoPlaga = 0;
      let tipoTratamiento = 0;
      if (this.$route.query.departamento !== undefined) {
        departamento = this.$route.query.departamento;
      }
      if (this.$route.query.tipoProduccion !== undefined) {
        tipoProduccion = this.$route.query.tipoProduccion;
      }
      if (this.$route.query.tipoCultivo !== undefined) {
        tipoCultivo = this.$route.query.tipoCultivo;
      }
      if (this.$route.query.cultivo !== undefined) {
        cultivo = this.$route.query.cultivo;
      }
      if (this.$route.query.tipoPlaga !== undefined) {
        tipoPlaga = this.$route.query.tipoPlaga;
      }
      if (this.$route.query.tipoTratamiento !== undefined) {
        tipoTratamiento = this.$route.query.tipoTratamiento;
      }
      return ruta + '?tipoProduccion=' + tipoProduccion +'&departamento=' + departamento + '&tipoCultivo='
          + tipoCultivo + '&cultivo=' + cultivo + '&tipoPlaga=' + tipoPlaga + '&tipoTratamiento=' + tipoTratamiento;
    },

    onGuiaTratamientoEliminar(id, titulo) {
      this.guiaTratamiento.id = id;
      this.guiaTratamiento.titulo = titulo;
    },

    onGuiaTratamientoEliminado() {
      this.listarGuiaTratamiento();
    },

    ocultarError() {
      this.error.mostrar = false;
      this.error.mensaje = '';
    },
    mostrarError(error) {
      this.error.mostrar = true;
      this.error.mensaje = error;
    }
  },

  mounted() {
    this.listarGuiaTratamiento();
  },
  updated() {
    this.listarGuiaTratamiento();
  }
}
</script>