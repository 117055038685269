<template>
  <div>
    <div class="mb-2"><h3>Listar guía de planificación</h3></div>
    <div class="d-flex flex-column flex-xxl-row">
      <div class="d-flex flex-column flex-sm-row">
        <select-departamento-component class="m-md-1"/>
        <select-tipo-produccion-component class="m-md-1"/>
      </div>
      <div class="d-flex flex-column flex-md-row justify-content-between flex-fill">
        <div class="d-flex flex-column flex-sm-row">
          <select-tipo-cultivo-component class="m-md-1 mb-2 mb-sm-0" />
          <div class="d-flex">
            <select-tipo-siembra-component class="flex-fill m-md-1 mb-sm-0" />
            <select-zona-component class="flex-fill m-md-1 ms-2" />
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-end mt-2">
          <div class="dropdown">
            <button class="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
              Exportar
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" :href="routeQuery('reporte.guia.planificacion.excel', this.$route.query)" target="_blank">Excel</a>
            </div>
          </div>
          <router-link :to="{name: 'guia.planificacion.crear'}" class="btn btn-success waves-effect waves-light ms-2">
            Agregar Nuevo
          </router-link>
        </div>
      </div>
    </div>
    <mensaje-alerta-component :error="this.error" class="my-2" />
    <tabla-guia-planificacion-listar ref="tablaGuiaPlanificacionListar" @onGuiaPlanificacionEliminar="onGuiaPlanificacionEliminar" class="mt-2 mt-xxl-0"/>
    <modal-guia-planificacion-eliminar :id="this.guiaPlanificacion.id" :titulo="this.guiaPlanificacion.titulo"
                                     @onGuiaPlanificacionEliminado="onGuiaPlanificacionEliminado"/>
  </div>
</template>
<script>
import TablaGuiaPlanificacionListar from '@/modules/guia/components/planificacion/TablaListarComponent.vue';
import ModalGuiaPlanificacionEliminar from "@/modules/guia/components/planificacion/ModalEliminarComponent.vue";
import SelectDepartamentoComponent from '@/modules/guia/components/utils/SelectDepartamentoComponent.vue';
import SelectTipoProduccionComponent from "@/modules/guia/components/utils/SelectTipoProduccionComponent.vue";
import SelectTipoCultivoComponent from "@/modules/guia/components/utils/SelectTipoCultivoComponent.vue";
import SelectTipoSiembraComponent from "@/modules/guia/components/utils/SelectTipoSiembraComponent.vue";
import SelectZonaComponent from "@/modules/guia/components/utils/SelectZonaComponent.vue";
import Service from "@/utils/Service";
import {route, routeQuery} from "@/utils/Route";
import MensajeAlertaComponent from "@/components/utils/MensajeAlerta.vue";

export default {
  name: 'GuiaPlanificacionListarView',
  components: {
    MensajeAlertaComponent,
    SelectZonaComponent, SelectTipoSiembraComponent, ModalGuiaPlanificacionEliminar, SelectTipoCultivoComponent,
    SelectTipoProduccionComponent, SelectDepartamentoComponent, TablaGuiaPlanificacionListar
  },
  data() {
    return {
      error: {
        mostrar: false,
        mensaje: null,
      },
      guias: [],
      guiaPlanificacion: {
        id: 0,
        titulo: ''
      }
    }
  },
  methods: {
    routeQuery,
    listarGuiaPlanificacion() {
      this.ocultarError();
      Service.get(this.getRutaParams()).then((data) => {
        this.guias = data.data;
        this.$refs.tablaGuiaPlanificacionListar.setGuias(this.guias);
      }).catch((error) => {
        this.mostrarError(error);
      });
    },

    getRutaParams() {
      let ruta = route('guia.planificacion.listar');
      let departamento = 0;
      let tipoProduccion = 0;
      let tipoCultivo = 0;
      let cultivo = 0;
      let tipoSiembra = 0;
      let zona = 0;
      if (this.$route.query.departamento !== undefined) {
        departamento = this.$route.query.departamento;
      }
      if (this.$route.query.tipoProduccion !== undefined) {
        tipoProduccion = this.$route.query.tipoProduccion;
      }
      if (this.$route.query.tipoCultivo !== undefined) {
        tipoCultivo = this.$route.query.tipoCultivo;
      }
      if (this.$route.query.cultivo !== undefined) {
        cultivo = this.$route.query.cultivo;
      }
      if (this.$route.query.tipoSiembra !== undefined) {
        tipoSiembra = this.$route.query.tipoSiembra;
      }
      if (this.$route.query.zona !== undefined) {
        zona = this.$route.query.zona;
      }
      return ruta + '?tipoProduccion=' + tipoProduccion +'&departamento=' + departamento + '&tipoCultivo='
          + tipoCultivo + '&cultivo=' + cultivo + '&tipoSiembra=' + tipoSiembra + '&zona=' + zona;
    },

    onGuiaPlanificacionEliminar(id, titulo) {
      this.guiaPlanificacion.id = id;
      this.guiaPlanificacion.titulo = titulo;
    },

    onGuiaPlanificacionEliminado() {
      this.listarGuiaPlanificacion();
    },

    ocultarError() {
      this.error.mostrar = false;
      this.error.mensaje = '';
    },
    mostrarError(error) {
      this.error.mostrar = true;
      this.error.mensaje = error;
    }
  },

  mounted() {
    this.listarGuiaPlanificacion();
  },
  updated() {
    this.listarGuiaPlanificacion();
  }
}
</script>