import { createStore } from 'vuex'
import Service from "@/utils/Service";
import router from "@/router";
import {route} from "@/utils/Route";

export default createStore({
  state: {
    usuario: {
      id: '',
      nombre: '',
      apellidos: '',
      fechaNacimiento: '',
      urlFoto: '',
      correo: '',
      telefono: '',
      carnetIdentidad: '',
      permisoDatos: []
    },
    autenticado: false
  },
  getters: {
    getUsuario(state) {
      return state.usuario;
    },
    getPermisos(state) {
      return state.usuario.permisoDatos;
    },
    estaAutenticado(state) {
      return state.autenticado;
    },
  },
  mutations: {
    SET_USUARIO(state, usuario) {
      if(usuario != null) {
        state.usuario.id = usuario.id;
        state.usuario.nombre = usuario.nombre;
        state.usuario.apellidos = usuario.apellidos;
        state.usuario.fechaNacimiento = usuario.fechaNacimiento;
        state.usuario.urlFoto = usuario.urlFoto;
        state.usuario.correo = usuario.correo;
        state.usuario.telefono = usuario.telefono;
        state.usuario.carnetIdentidad = usuario.carnetIdentidad;
        state.usuario.permisoDatos = usuario.permisoDatos;
        state.autenticado = true;
      }
    }
  },
  actions: {
    getUsuarioAutenticado({ commit }) {
      Service.get(route('usuario')).then( (data) => {
        commit('SET_USUARIO', data.data);
        if (window.location.pathname === '/') {
          router.push({name: 'home'}).then(() => {});
        }
      }).catch( () => {
        commit('SET_USUARIO', null);
        router.push({name: 'inicio.sesion'}).then(() => {});
      });
    },
  },
  modules: {
  }
})
