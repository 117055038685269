<template>
  <div class="container">
    <h3 class="mb-2">Modificar una alerta</h3>
    <div class="card">
      <div class="card-body">
        <form v-on:submit.prevent="modificarAlerta()" id="form">
          <div class="d-flex flex-column">
            <mensaje-alerta-component :error="this.error" class="mb-2" />
            <div class="row mb-2">
              <div class="col-12 col-md-6">
                <label for="departamento" class="form-label">Departamento <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <select v-model="this.form.departamento" id="departamento" class="form-select"
                        :disabled="!this.form.activo" required>
                  <option value="1">Santa Cruz</option>
                  <option value="2">Beni</option>
                  <option value="3">Pando</option>
                  <option value="4">Cochabamba</option>
                  <option value="5">Chuquisaca</option>
                  <option value="6">Tarija</option>
                  <option value="7">La Paz</option>
                  <option value="8">Oruro</option>
                  <option value="9">Potosi</option>
                </select>
              </div>
              <div class="col-12 col-md-6 mt-2 mt-md-0">
                <label for="titulo" class="form-label">Título <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <input v-model="this.form.titulo" id="titulo" type="text" class="form-control" maxlength="100" :disabled="!this.form.activo" required>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12">
                <label for="descripcion" class="form-label">Descripción <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <textarea v-model="this.form.descripcion" id="descripcion" type="text" class="form-control"
                          maxlength="255" rows="3" :disabled="!this.form.activo" required>
              </textarea>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-12 col-md-6">
                <label for="fecha" class="form-label">Fecha de la alerta <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <input v-model="this.form.fecha" id="fecha" class="form-control" type="date" :disabled="!this.form.activo">
              </div>
              <div class="col-12 col-md-6 mt-2 mt-md-0">
                <label for="hora" class="form-label">Fecha de la alerta <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <input v-model="this.form.hora" id="hora" class="form-control" type="time" :disabled="!this.form.activo">
              </div>
            </div>
          </div>
          <div class="d-flex flex-row-reverse">
            <button :disabled="!this.form.activo" type="submit" class="btn btn-primary waves-effect waves-light">Modificar</button>
            <a @click="router().back()" class="btn btn-soft-secondary waves-effect waves-light me-2">Atrás</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import Service from "@/utils/Service";
import {route} from "@/utils/Route";
import Toast from "@/utils/Toast";
import router from "@/router";
import MensajeAlertaComponent from "@/components/utils/MensajeAlerta.vue";

export default {
  name: 'AlertaCrearView',
  components: {MensajeAlertaComponent},
  data() {
    return {
      error: {
        mostrar: false,
        mensaje: null,
      },
      form: {
        activo: true,
        titulo: '',
        fecha: '',
        hora: '',
        descripcion: '',
        departamento: 1
      }
    }
  },
  methods: {
    router() {
      return router
    },
    mostrarAlerta() {
      this.ocultarError();
      Service.get(route('alerta.mostrar', this.getParametrosMostrar())).then( data => {
        this.form.titulo = data.data.titulo;
        this.form.fecha = data.data.fecha;
        this.form.hora = data.data.hora;
        this.form.descripcion = data.data.descripcion;
        this.form.departamento = data.data.departamento;
      }).catch( error => {
        this.mostrarError(error);
      });
    },
    modificarAlerta() {
      this.desactivarForm();
      this.ocultarError();
      Service.put(route('alerta.modificar', this.getParametrosMostrar()), this.getParametros()).then( (data)=>{
        Toast.showSuccess(data.mensaje)
        router.push({name: 'alerta.listar'});
      }).catch( error => {
        this.mostrarError(error);
      }). finally( () => {
        this.activarForm();
      });
    },
    getParametrosMostrar() {
      return [this.$route.params.id]
    },
    getParametros() {
      return {
        titulo: this.form.titulo,
        fecha: this.form.fecha,
        hora: this.form.hora,
        descripcion: this.form.descripcion,
        departamento: this.form.departamento
      }
    },
    desactivarForm() {
      this.form.activo = false;
    },
    activarForm() {
      this.form.activo = true;
    },

    ocultarError() {
      this.error.mostrar = false;
      this.error.mensaje = '';
    },
    mostrarError(error) {
      this.error.mostrar = true;
      this.error.mensaje = error;
    },
  },
  mounted() {
    this.mostrarAlerta();
  }
}
</script>