<template>
  <div>
    <div class="mb-2"><h3>Listar de huertos</h3></div>
    <div class="d-flex flex-column flex-md-row justify-content-between">
      <div class="d-flex flex-column flex-md-row">
        <select-departamento-component class="m-md-1"/>
        <select-tipo-produccion-component class="m-md-1"/>
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <div class="dropdown">
          <button class="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
            Exportar
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" :href="routeQuery('reporte.huerto.excel', this.$route.query)" target="_blank">Excel</a>
          </div>
        </div>
        <router-link :to="{name: 'huerto.crear'}" class="btn btn-success waves-effect waves-light ms-2">
          Agregar Nuevo
        </router-link>
      </div>
    </div>
    <mensaje-alerta-component :error="this.error" class="my-2" />
    <tabla-huerto-listar ref="tablaHuertoListar" @onHuertoEliminar="onHuertoEliminar" class="mt-2 mt-md-0"/>
    <modal-huerto-eliminar :id="this.huerto.id" :cultivador="this.huerto.cultivador" @onHuertoEliminado="onHuertoEliminado"/>
  </div>
</template>
<script>
import SelectDepartamentoComponent from '@/modules/huerto/components/utils/SelectDepartamentoComponent.vue';
import SelectTipoProduccionComponent from "@/modules/huerto/components/utils/SelectTipoProduccionComponent.vue";
import TablaHuertoListar from '@/modules/huerto/components/huerto/TablaListarComponent.vue';
import ModalHuertoEliminar from "@/modules/huerto/components/huerto/ModalEliminarComponent.vue";
import Service from "@/utils/Service";
import {route, routeQuery} from "@/utils/Route";
import MensajeAlertaComponent from "@/components/utils/MensajeAlerta.vue";
export default {
  name: 'HuertoListarView',
  components: {
    MensajeAlertaComponent,
    ModalHuertoEliminar, SelectTipoProduccionComponent,
    SelectDepartamentoComponent, TablaHuertoListar
  },
  data() {
    return {
      error: {
        mostrar: false,
        mensaje: null,
      },
      huertos: [],
      huerto: {
        id: 0,
        cultivador: {
          nombre: '',
          apellidos: ''
        }
      }
    }
  },
  methods: {
    routeQuery,
    listarHuerto() {
      this.ocultarError();
      Service.get(this.getRutaParams()).then((data) => {
        this.huertos = data.data;
        this.$refs.tablaHuertoListar.setHuertos(this.huertos);
      }).catch((error) => {
        this.mostrarError(error);
      });
    },

    getRutaParams() {
      let ruta = route('huerto.listar');
      let departamento = 0;
      let tipoProduccion = 0;
      if (this.$route.query.departamento !== undefined) {
        departamento = this.$route.query.departamento;
      }
      if (this.$route.query.tipoProduccion !== undefined) {
        tipoProduccion = this.$route.query.tipoProduccion;
      }
      return ruta + '?tipoProduccion=' + tipoProduccion +'&departamento=' + departamento;
    },

    onHuertoEliminar(id, cultivador) {
      this.huerto.id = id;
      this.huerto.cultivador = cultivador;
    },

    onHuertoEliminado() {
      this.listarHuerto();
    },

    ocultarError() {
      this.error.mostrar = false;
      this.error.mensaje = '';
    },
    mostrarError(error) {
      this.error.mostrar = true;
      this.error.mensaje = error;
    }
  },
  mounted() {
    this.listarHuerto();
  },
  updated() {
    this.listarHuerto();
  }
}
</script>