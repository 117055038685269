<template>
  <div>
    <div class="container chat-container">
      <div class="">
        <div class="p-3">
          <div class="row align-items-center">
            <div class="col-sm-4 col-8">
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0 me-2">
                  <img :src="'https://pb.elroir.cloud/api/files/_pb_users_auth_/mjbm74qq91t90is/' + this.sala.expand.client.avatar"
                       alt="perfil de cultivador mi punto verde" class="avatar-xs rounded-circle">
                </div>
                <div class="flex-grow-1">
                  <h5 class="text-truncate mb-0 fs-16"><a class="text-reset" href="javascript:void(0)"
                  >{{this.sala.expand.client.name + ' ' + this.sala.expand.client.lastName}}</a></h5>
                  <p class="text-truncate text-muted fs-14 mb-0 userStatus"><small>{{this.sala.expand.client.email}}</small></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="chat-box" id="chat-box" ref="chatBox">
        <div v-for="mensaje in this.mensajes" v-bind:key="mensaje.id" class="message-content">
          <div v-if="mensaje.author === this.sala.admin" class="message user-message">
            <img v-if="mensaje.type === 'image'" @click="this.onMensajeImagen(mensaje)"
                 :src="'https://pb.elroir.cloud/api/files/'+ mensaje.collectionId +'/'+ mensaje.id + '/'+ mensaje.uri"
                 alt="mmi punto verde" style="max-width: 100%; height: auto; border-radius: 5px;">
            <div v-else >{{mensaje.text}}</div>
            <div class="message-details">
              <div class="message-time">{{mensaje.created}}</div>
            </div>
          </div>
          <div v-else-if="mensaje.author === this.sala.client" class="message other-message">
            <img v-if="mensaje.type === 'image'" @click="this.onMensajeImagen(mensaje)"
                 :src="'https://pb.elroir.cloud/api/files/'+ mensaje.collectionId +'/'+ mensaje.id + '/'+ mensaje.uri"
                 alt="mmi punto verde" style="max-width: 100%; height: auto; border-radius: 5px;">
            <div v-else >{{mensaje.text}}</div>
            <div class="message-details">
              <div class="message-time">{{mensaje.created}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="input-group">
        <textarea v-model="this.form.mensaje" class="form-control" id="message-input" rows="2" placeholder="Escribe un mensaje..."></textarea>
        <input @change="onChangeInputImagen($event)" type="file" id="file-input" style="display: none;" accept="image/jpeg,image/png">
        <button @click="this.onEnviarImagen" class="btn btn-outline-secondary">
          <i class="ri-camera-line"></i>
        </button>
        <button class="btn btn-success" @click="this.sendMessage">
          <i class="ri-send-plane-2-line"></i>
        </button>
      </div>
    </div>

    <button id="btnMostrarModal" type="button" data-bs-toggle="modal" data-bs-target="#modalImagen" style="display: none"></button>
    <div id="modalImagen" class="modal modal-xl fade" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
          </div>
          <div class="modal-body d-flex justify-content-center">
            <img id="imgModal" src="" alt="Imagen Ampliada" class="modal-img">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="css">
  .chat-container {
    max-width: 600px;
    margin: 50px auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
  }
  .chat-box {
    height: 600px;
    overflow-y: auto;
    padding: 10px;
  }

  .message {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
  }

  .user-message {
    justify-content: flex-end;
    text-align: right;
    color: #155724;
    background-color: #d4edda;
  }

  .other-message {
    justify-content: flex-start;
    text-align: left;
    color: #0c5460;
    background-color: white;
    border: solid 1px #adb5bd
  }

  .message-content {
    flex: 1;
    margin-left: 10px;
    word-wrap: break-word;
  }

  .message img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;
  }

  .message-details {
    display: flex;
    align-items: center;
  }

  .user-message .message-details {
    justify-content: flex-end;
  }

  .other-message .message-details {
    justify-content: flex-start;
  }

  .message-time {
    font-size: 0.8em;
    color: #6c757d;
    margin-top: 5px;
  }

  .input-group {
    margin: 10px;
    width: auto !important;
  }

  #message-input {
    resize: vertical;
  }

  /*.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    max-width: 80%;
    max-height: 80%;
  }*/

  .modal-img {
    max-width: 100%;
    max-height: 100%;
  }
</style>
<script>

import PocketBase from 'pocketbase';
import {CHAT_SERVER_URL} from "@/utils/config";

export default {
  name: 'ChatMostrarView',
  data() {
    return {
      form: {
        mensaje: ''
      },
      sala: {
        admin: '',
        client: '',
        collectionId: '',
        collectionName: '',
        created: '',
        expand: {
          client: {
          avatar: '',
          collectionId: '',
          collectionName: '',
          created: '',
          email: '',
          emailVisibility: true,
          externalId: '',
          id: '',
          lastName: '',
          lastSeen: '',
          name: '',
          updated: '',
          username: '',
          verified: true
          }
        },
        id: '',
        messages: [],
        type: '',
        updated: ''
      },
      mensajes: []
    }
  },
  methods: {

    async mostrarChat() {
      const pb = new PocketBase(CHAT_SERVER_URL);

      //consultando informacion de la sala
      this.sala = await pb.collection('room').getFirstListItem('id="' + this.$route.params.id + '"', {
        expand: 'client',
      });

      //consultando la lista de mensajes de la sala
      this.mensajes = await pb.collection('message').getFullList({
        sort: 'created',
        filter: 'room="' + this.$route.params.id + '"'
      });
      this.scrollToBottom();

      //actualizando el estado de la sala en leido
      const data = {
        "hasNewUserMessage": false
      };
      await pb.collection('room').update(this.$route.params.id, data);

      //iniciando el escuchador de mensaje en tiempo real
      const sala = this.sala;
      const self = this;
      await pb.collection('message').subscribe('*', function (e)  {
        if (e.record.room === sala.id) {
          self.mensajes = [...self.mensajes, e.record];
          self.scrollToBottom();
        }
      }).then(r => {
        console.log(r);
      });

    },

    async sendMessage() {
      const pb = new PocketBase(CHAT_SERVER_URL);
      const data = {
        "text": this.form.mensaje,
        "status": "seen",
        "type": "text",
        "author": this.sala.admin,
        "room": this.$route.params.id
      };

      const record = await pb.collection('message').create(data);
      this.mensajes.push(record);
      this.scrollToBottom();
      this.form.mensaje = '';
    },

    async enviarImagen(imagen) {
      const pb = new PocketBase(CHAT_SERVER_URL);
      let data = new FormData();
      data.append('text', '');
      data.append('status', 'seen');
      data.append('type', 'image');
      data.append('status', 'seen');
      data.append('author', this.sala.admin);
      data.append('room', this.$route.params.id);
      data.append('uri', imagen);

      const record = await pb.collection('message').create(data);
      console.log(record);
      this.mensajes.push(record);
      this.scrollToBottom();
    },

    scrollToBottom() {
      this.$nextTick(() => {
        this.$refs.chatBox.scrollTop = this.$refs.chatBox.scrollHeight;
      });
    },

    onEnviarImagen() {
      let fileInput = document.getElementById('file-input');
      fileInput.click();
    },
    onChangeInputImagen(event) {
      let imagen = event.target.files[0];
      this.enviarImagen(imagen);
    },
    onMensajeImagen(mensaje) {
      let url = CHAT_SERVER_URL + '/api/files/'+ mensaje.collectionId +'/'+ mensaje.id + '/'+ mensaje.uri
      let btnMostrarModal = document.getElementById('btnMostrarModal');
      btnMostrarModal.click();
      this.mostrarModal(url);
    },

    mostrarModal(url) {
      let imgModal = document.getElementById('imgModal');
      imgModal.src = url;
    },


  },
  mounted() {
    console.log(CHAT_SERVER_URL);
    this.mostrarChat();
  }
}
</script>