<template>
  <div class="flex-fill">
    <label for="tipoRedSocial" class="form-label">Tipo de Red Social</label>
    <select v-model="this.form.tipoRedSocial" @change="onChangeTipoRedSocial($event)" id="tipoRedSocial"
            class="form-select">
      <option value="0">Todos</option>
      <option v-for="tipoRedSocial in this.lista.tipoRedSociales" :key="tipoRedSocial.id"
              :value="tipoRedSocial.id">{{tipoRedSocial.nombre}}
      </option>
    </select>
  </div>
</template>
<script>
import router from "@/router";
import Service from "@/utils/Service";
import {route} from "@/utils/Route";

export default {
  name: 'SelectTipoRedSocialComponent',
  data() {
    return {
      lista: {
        tipoRedSociales: []
      },
      form: {
        tipoRedSocial: 0
      }
    }
  },
  methods: {
    listarTipoRedSocial() {
      Service.get(route('recurso.tipo_red_social')).then( data => {
        this.lista.tipoRedSociales = data.data;
      }).catch( error => {
        console.log(error);
      });
    },
    onChangeTipoRedSocial(event) {
      if (event.target.value >= 0) {
        let params = {...this.$route.query, tipoRedSocial: this.form.tipoRedSocial};
        router.push({ path: this.$route.path, query: params });
      }
    },
  },
  mounted() {
    this.listarTipoRedSocial();
    if (this.$route.query.tipoRedSocial !== undefined)
      this.form.tipoRedSocial = this.$route.query.tipoRedSocial;
  }
}
</script>