<template>
  <div class="container d-flex flex-column">
    <mensaje-alerta-component :error="this.error" class="my-2" />
    <div class="mb-2">
      <a @click="router().back()" class="btn btn-soft-secondary waves-effect waves-light me-2">Atrás</a>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="d-flex flex-column flex-md-row">
          <div class="d-flex justify-content-center">
            <img :src="this.guia.urlImagen" class="flex-shrink-0 me-3 avatar-xl rounded" alt="mi punto verde imagen guia produccion" />
          </div>
          <div class="d-flex flex-column mt-2 mt-md-0">
            <h6 class="card-title">{{this.guia.titulo}}</h6>

            <div class="d-flex">
              <span class="fw-medium">Departamento: </span>
              <span class="ms-2 text-muted">{{this.guia.departamentoDato.nombre}}</span>
            </div>
            <div class="d-flex">
              <span class="fw-medium">Visualizaciones: </span>
              <span class="ms-2 text-muted">{{this.guia.visualizaciones}}</span>
            </div>
            <div class="d-flex">
              <span class="fw-medium">Tipo producción: </span>
              <span class="ms-2 text-muted">{{ this.guia.tipoProduccion === 1 ? 'Comercial' : 'Autoconsumo' }}</span>
            </div>
            <div class="d-flex">
              <span class="fw-medium">Registrado </span>
              <span class="ms-2 text-muted">{{this.guia.fechaHoraRegistrado}}</span>
            </div>

            <div class="d-flex mt-1">
              <div class="p-2">
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                      <div class="avatar-title bg-light text-danger rounded fs-24">
                        <i class="mdi mdi-youtube"></i>
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1 overflow-hidden">
                    <h5 class="fs-13 mb-1"><a :href="this.guia.urlVideo" class="text-body text-truncate d-block" target="_blank">Youtube</a></h5>
                  </div>
                </div>
              </div>
              <div class="p-2">
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                      <div class="avatar-title bg-light text-danger rounded fs-24">
                        <i class="mdi mdi-file-pdf-box"></i>
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1 overflow-hidden">
                    <h5 class="fs-13 mb-1"><a :href="this.guia.urlPdf" class="text-body text-truncate d-block" target="_blank">PDF</a></h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <h6 class="card-title mb-0">Descripción</h6>
        <div v-html="this.guia.descripcion"></div>
      </div>
    </div>

    <div>
      <div class="d-flex flex-row-reverse">
        <a class="btn btn-success waves-effect waves-light" href="javascript:void(0)"
           data-bs-toggle="modal" data-bs-target="#modalGuiaCultivoAdicionar">Agregar Cultivo
        </a>
      </div>
    </div>

    <div class="mt-2">
      <mensaje-alerta-component :error="this.errorMostrar" class="my-2" />
      <ul class="list-group">
        <li v-for="cultivo in this.cultivos" :key="cultivo.id" class="list-group-item">
          <div class="d-flex flex-column ms-2">
            <span class="fw-medium">{{cultivo.nombre}}</span>
            <div class="d-flex justify-content-between">
              <span class="text-muted">{{cultivo.tipoCultivoDato.nombre}}</span>
              <a @click="onGuiaCultivoEliminar(cultivo)" class="badge bg-danger" href="javascript:void(0)"
                 data-bs-toggle="modal" data-bs-target="#modalGuiaCultivoEliminar">
                <span>Eliminar</span>
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <modal-guia-cultivo-adicionar-component :guia="this.guia.id" @onGuiaCultivoAgregado="onGuiaCultivoAgregado" />
    <modal-guia-cultivo-eliminar-component :guia="this.guia.id" :cultivo="this.cultivo" @onGuiaCultivoEliminado="onGuiaCultivoEliminado" />
  </div>
</template>
<script>
import Service from "@/utils/Service";
import {route} from "@/utils/Route";
import ModalGuiaCultivoAdicionarComponent from "@/modules/guia/components/cultivo/ModalAdicionarComponent.vue";
import ModalGuiaCultivoEliminarComponent from "@/modules/guia/components/cultivo/ModalEliminarComponent.vue";
import MensajeAlertaComponent from "@/components/utils/MensajeAlerta.vue";
import router from "@/router";

export default {
  name: 'GuiaProduccionMostrarView',
  components: {MensajeAlertaComponent, ModalGuiaCultivoEliminarComponent, ModalGuiaCultivoAdicionarComponent},
  data() {
    return {
      error: {
        mostrar: false,
        mensaje: null,
      },
      errorMostrar: {
        mostrar: false,
        mensaje: null,
      },
      guia: {
        departamentoDato: {},
      },
      cultivos: [],
      cultivo: {}
    }
  },
  mounted() {
    this.mostrarGuiaProduccion();
    this.listarGuiaCultivo();
  },
  methods: {
    router() {
      return router
    },
    mostrarGuiaProduccion() {
      this.ocultarError();
      Service.get(route('guia.produccion.mostrar', this.getParametros())).then((data) => {
        this.guia = data.data;
      }).catch((error) => {
        this.mostrarError(error);
      });
    },
    listarGuiaCultivo() {
      this.ocultarErrorMostrar();
      Service.get(route('guia.cultivo.listar', this.getParametros())).then((data) => {
        this.cultivos = data.data;
      }).catch((error) => {
        this.mostrarErrorMostrar(error);
      });
    },

    onGuiaCultivoAgregado() {
      this.listarGuiaCultivo();
    },
    onGuiaCultivoEliminado() {
      this.listarGuiaCultivo();
    },
    onGuiaCultivoEliminar(cultivo) {
      this.cultivo = cultivo;
    },

    ocultarError() {
      this.error.mostrar = false;
      this.error.mensaje = '';
    },
    mostrarError(error) {
      this.error.mostrar = true;
      this.error.mensaje = error;
    },
    ocultarErrorMostrar() {
      this.errorMostrar.mostrar = false;
      this.errorMostrar.mensaje = '';
    },
    mostrarErrorMostrar(error) {
      this.errorMostrar.mostrar = true;
      this.errorMostrar.mensaje = error;
    },
    getParametros() {
      return [this.$route.params.id];
    },
  },
}
</script>