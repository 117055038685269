<template>
  <div>
    <div id="modalAlertaEliminar" class="modal fade" tabindex="-1" aria-labelledby="modalLabelAlertaEliminar" aria-hidden="true" style="display: none;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalTituloAlertaEliminar">Eliminar una alerta</h5>
            <button id="btnCerrarModalAlertaEliminar" ref="btnCerrarModalAlertaEliminar"
                    type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
          </div>
          <div class="modal-body">
            <mensaje-alerta-component :error="this.error" class="mt-2" />
            <p class="text-muted">Desea eliminar la alerta:
              <span class="fw-bolder fs-15">{{this.alerta.titulo}}</span>
            </p>
          </div>
          <div class="modal-footer">
            <button :disabled="!this.form.activo" type="button" class="btn btn-light" data-bs-dismiss="modal">Cerrar</button>
            <button :disabled="!this.form.activo" @click="eliminarAlerta" type="button" class="btn btn-danger">Si, Eliminar</button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Service from "@/utils/Service";
import {route} from "@/utils/Route";
import Toast from "@/utils/Toast";
import MensajeAlertaComponent from "@/components/utils/MensajeAlerta.vue";

export default {
  name: 'ModalAlertaEliminarComponent',
  components: {MensajeAlertaComponent},
  props: {
    alerta: {
      id: Number,
      titulo: String,
    }
  },
  data() {
    return {
      error: {
        mostrar: false,
        mensaje: null,
      },
      form: {
        activo: true
      },
    }
  },
  methods: {
    eliminarAlerta() {
      this.desactivarForm();
      this.ocultarError();
      Service.delete(route('alerta.eliminar', [this.alerta.id])).then( (data) => {
        Toast.showSuccess(data.mensaje)
        this.$emit('onAlertaEliminado');
        this.$refs.btnCerrarModalAlertaEliminar.click();
      }).catch((error) => {
        this.mostrarError(error);
      }).finally(()=>{
        this.activarForm();
      });
    },

    desactivarForm() {
      this.form.activo = false;
    },
    activarForm() {
      this.form.activo = true;
    },

    ocultarError() {
      this.error.mostrar = false;
      this.error.mensaje = '';
    },
    mostrarError(error) {
      this.error.mostrar = true;
      this.error.mensaje = error;
    },
  }

}
</script>