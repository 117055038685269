<template>
  <div>
    <div id="modalGuiaCultivoAdicionar" class="modal fade" tabindex="-1" aria-labelledby="modalLabelGuiaCultivoAdicionar" aria-hidden="true" style="display: none;">
      <div class="modal-dialog">
        <div class="modal-content">
          <form v-on:submit.prevent="agregarGuiaCultivo()" id="form">
            <div class="modal-header">
              <h5 class="modal-title" id="modalTituloGuiaCultivoAdicionar">Adicionar un cultivo</h5>
              <button id="btnCerrarModalGuiaCultivoAdicionar" ref="btnCerrarModalGuiaCultivoAdicionar"
                      type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <label for="tipoCultivo" class="form-label">Tipo Cultivo</label>
                  <select v-model="this.form.tipoCultivo" @change="onChangeTipoCultivo($event)" id="tipoCultivo"
                          class="form-select mb-3" :disabled="!this.form.activo" required>
                    <option value="0">Seleccione un tipo de cultivo</option>
                    <option v-for="tipoCultivo in this.lista.tipoCultivos" :key="tipoCultivo.id"
                            :value="tipoCultivo.id">{{tipoCultivo.nombre}}
                    </option>
                  </select>
                </div>
                <div class="col-12">
                  <label for="cultivo" class="form-label">Cultivo</label>
                  <select v-model="this.form.cultivo" id="cultivo" class="form-select mb-3"
                          :disabled="!this.form.activo" required>
                    <option value="0">Seleccione un cultivo</option>
                    <option v-for="cultivo in this.lista.cultivos" :key="cultivo.id"
                            :value="cultivo.id">{{cultivo.nombre}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button :disabled="!this.form.activo" type="submit" class="btn btn-primary waves-effect waves-light">Agregar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Service from "@/utils/Service";
import {route} from "@/utils/Route";
import Toast from "@/utils/Toast";

export default {
  name: 'ModalGuiaCultivoAdicionarComponent',
  props: {
    guia: Number
  },
  data() {
    return {
      form: {
        activo: true,
        tipoCultivo: 0,
        cultivo: 0
      },
      lista: {
        tipoCultivos: [],
        cultivos: []
      }
    }
  },
  methods: {
    agregarGuiaCultivo() {
      this.desactivarForm();
      Service.post(route('guia.cultivo.registrar', [this.guia]),
          this.getParametros()).then( (data) => {
        Toast.showSuccess(data.mensaje)
        this.$emit('onGuiaCultivoAgregado');
        this.$refs.btnCerrarModalGuiaCultivoAdicionar.click();
      }).catch((error) => {
        console.log(error)
      }).finally(()=>{
        this.activarForm();
      });
    },
    listarTipoCultivo() {
      Service.get(route('recurso.tipo_cultivo')).then( data => {
        this.lista.tipoCultivos = data.data;
      }).catch( error => {
        console.log(error);
      });
    },
    listarCultivo(tipoCultivo) {
      Service.get(route('recurso.tipo_cultivo.cultivo', [tipoCultivo])).then( data => {
        this.lista.cultivos = data.data;
      }).catch( error => {
        console.log(error);
      });
    },

    onChangeTipoCultivo(event) {
      if (event.target.value > 0) {
        this.listarCultivo(event.target.value)
      } else {
        this.lista.cultivos = [];
        this.form.cultivo = 0;
      }
    },

    getParametros() {
        return {
          cultivo: this.form.cultivo
        }
    },
    desactivarForm() {
      this.form.activo = false;
    },
    activarForm() {
      this.form.activo = true;
    },
  },
  mounted() {
    this.listarTipoCultivo();
  }

}
</script>