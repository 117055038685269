<template>
  <div>
    <label for="selectDepartamento" class="form-label">Departamento</label>
    <select v-model="this.departamento" id="selectDepartamento" class="form-select" aria-label="Seleccione un Departamento">
      <option selected value="0">Todos</option>
      <option value="1">Santa Cruz</option>
      <option value="2">Beni</option>
      <option value="3">Pando</option>
      <option value="4">Cochabamba</option>
      <option value="5">Chuquisaca</option>
      <option value="6">Tarija</option>
      <option value="7">La Paz</option>
      <option value="8">Oruro</option>
      <option value="9">Potosi</option>
    </select>
  </div>
</template>
<script>
import router from "@/router";

export default {
  name: 'SelectDepartamentoComponent.vue',
  data() {
    return {
      departamento: 0,
    }
  },
  watch: {
    departamento: function (nuevo) {
      let params = {...this.$route.query, departamento: nuevo};
      router.push({ path: this.$route.path, query: params });
    }
  },
  mounted() {
    if (this.$route.query.departamento !== undefined)
      this.departamento = this.$route.query.departamento;
  }
}
</script>