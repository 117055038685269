<template>
  <div>
    <div id="modalHuertoEliminar" class="modal fade" tabindex="-1" aria-labelledby="modalLabelHuertoEliminar" aria-hidden="true" style="display: none;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalTituloHuertoEliminar">Eliminar huerto</h5>
            <button id="btnCerrarModalHuertoEliminar" ref="btnCerrarModalHuertoEliminar"
                    type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
          </div>
          <div class="modal-body">
            <mensaje-alerta-component :error="this.error" class="mt-2" />
            <p class="text-muted">Desea eliminar el huerto :
              <span class="fw-bolder fs-15">{{this.id}}</span>
              que pertenece al cultivador: <span class="fw-bolder fs-15">{{this.cultivador.nombre + ' ' + this.cultivador.apellidos}}</span>
            </p>
          </div>
          <div class="modal-footer">
            <button :disabled="!this.form.activo" type="button" class="btn btn-light" data-bs-dismiss="modal">Cerrar</button>
            <button :disabled="!this.form.activo" @click="eliminarHuerto" type="button" class="btn btn-danger">Si, Eliminar</button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Service from "@/utils/Service";
import {route} from "@/utils/Route";
import Toast from "@/utils/Toast";
import MensajeAlertaComponent from "@/components/utils/MensajeAlerta.vue";

export default {
  name: 'ModalHuertoEliminarComponent',
  components: {MensajeAlertaComponent},
  props: {
    cultivador: {
      nombre: String,
      apellidos: String
    },
    id: Number
  },
  data() {
    return {
      error: {
        mostrar: false,
        mensaje: null,
      },
      form: {
        activo: true
      },
    }
  },
  methods: {
    eliminarHuerto() {
      this.ocultarError();
      this.desactivarForm();
      Service.delete(route('huerto.eliminar', [this.id])).then( (data) => {
        Toast.showSuccess(data.mensaje);
        this.$emit('onHuertoEliminado');
        this.cerrarModal();
      }).catch((error) => {
        this.mostrarError(error);
      }).finally(()=>{
        this.activarForm();
      });
    },
    cerrarModal() {
      this.$refs.btnCerrarModalHuertoEliminar.click();
    },
    desactivarForm() {
      this.form.activo = false;
    },
    activarForm() {
      this.form.activo = true;
    },

    ocultarError() {
      this.error.mostrar = false;
      this.error.mensaje = '';
    },
    mostrarError(error) {
      this.error.mostrar = true;
      this.error.mensaje = error;
    },
  }

}
</script>