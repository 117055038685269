<template>
  <div class="container">
    <div class="d-flex flex-column">
      <mensaje-alerta-component :error="this.error" class="mb-2" />
      <div class="mb-2">
        <a @click="router().back()" class="btn btn-soft-secondary waves-effect waves-light me-2">Atrás</a>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-md-2">
              <div class="d-flex justify-content-center">
                <img :src="this.cultivo.urlImagen" class="flex-shrink-0 avatar-xl rounded" alt="mi punto verde imagen guia produccion" />
              </div>
            </div>
            <div class="col-12 col-md-10 mt-2 mt-sm-0">
              <div class="d-flex flex-column">
                <div><h6 class="card-title mb-0">{{this.cultivo.nombre}}</h6></div>
                <div class="d-flex mt-3">
                  <span class="fw-medium">Nombre científico: </span>
                  <span class="ms-2 text-muted">{{this.cultivo.nombreCientifico}}</span>
                </div>
                <div class="d-flex">
                  <span class="fw-medium">Tipo de cultivo: </span>
                  <span class="ms-2 text-muted">{{this.cultivo.tipoCultivoDato.nombre}}</span>
                </div>

                <div class="d-flex">
                  <div v-if="this.cultivo.urlPdf != null" class="d-flex flex-fill align-items-center">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar-sm">
                        <div class="avatar-title bg-light text-danger rounded fs-24">
                          <i class="mdi mdi-file-pdf-box"></i>
                        </div>
                      </div>
                    </div>
                    <div class="flex-grow-1 overflow-hidden">
                      <h5 class="fs-13 mb-1"><a :href="this.cultivo.urlPdf" class="text-body text-truncate d-block" target="_blank">Receta</a></h5>
                    </div>
                  </div>
                  <div  v-if="this.cultivo.urlCartilla != null" class="d-flex flex-fill align-items-center ms-2">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar-sm">
                        <div class="avatar-title bg-light text-danger rounded fs-24">
                          <i class="mdi mdi-file-pdf-box"></i>
                        </div>
                      </div>
                    </div>
                    <div class="flex-grow-1 overflow-hidden">
                      <h5 class="fs-13 mb-1"><a :href="this.cultivo.urlCartilla" class="text-body text-truncate d-block" target="_blank">Cartilla</a></h5>
                    </div>
                  </div>
                </div>
                <div class="d-flex">
                    <span class="text-muted">Registrado el {{this.cultivo.fechaHoraRegistrado}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card mt-2">
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-md-4 col-xl-3">
              <img :src="this.cultivo.urlPortada" class="rounded img-fluid w-100 h-100" alt="cultivo punto-verde"/>
            </div>
            <div class="col-12 col-md-8 col-xl-9 mt-2 mt-sm-0">
              <div class="d-flex flex-column">
                <div><h6 class="card-title mb-0">Descripción</h6></div>
                <div>{{this.cultivo.descripcion}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Service from "@/utils/Service";
import {route} from "@/utils/Route";
import router from "@/router";
import MensajeAlertaComponent from "@/components/utils/MensajeAlerta.vue";

export default {
  name: 'CultivoMostrarView',
  components: {MensajeAlertaComponent},
  data() {
    return {
      error: {
        mostrar: false,
        mensaje: null,
      },
      cultivo: {
        id: 0,
        nombre: '',
        nombreCientifico: '',
        descripcion: '',
        fechaHoraRegistrado: '',
        tipoCultivo: 0,
        usuario: 0,
        urlImagen: '',
        urlPortada: '',
        tipoCultivoDato: {
          id: 0,
          nombre: ''
        }
      }
    }
  },
  methods: {
    router() {
      return router
    },
    mostrarCultivo() {
      this.ocultarError();
      Service.get(route('cultivo.mostrar', this.getParametrosMostrar())).then((data) => {
        this.cultivo = data.data;
      }).catch((error) => {
        this.mostrarError(error);
      });
    },

    getParametrosMostrar() {
      return [this.$route.params.id]
    },

    ocultarError() {
      this.error.mostrar = false;
      this.error.mensaje = '';
    },
    mostrarError(error) {
      this.error.mostrar = true;
      this.error.mensaje = error;
    },
  },

  mounted() {
    this.mostrarCultivo();
  }

}
</script>