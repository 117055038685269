<template>
  <div class="container">
    <h3 class="mb-2">Modificar una actividad</h3>
    <div class="card">
      <div class="card-body">
        <form v-on:submit.prevent="modificarActividad()" id="form">
          <div class="d-flex flex-column">
            <mensaje-alerta-component :error="this.error" class="mb-2" />
            <div class="row mb-2">
              <div class="col-12 col-md-6">
                <label for="departamento" class="form-label">Departamento</label>
                <select v-model="this.form.departamento" id="departamento" class="form-select"
                        :disabled="!this.form.activo" required>
                  <option value="1">Santa Cruz</option>
                  <option value="2">Beni</option>
                  <option value="3">Pando</option>
                  <option value="4">Cochabamba</option>
                  <option value="5">Chuquisaca</option>
                  <option value="6">Tarija</option>
                  <option value="7">La Paz</option>
                  <option value="8">Oruro</option>
                  <option value="9">Potosi</option>
                </select>
              </div>
              <div class="col-12 col-md-6 mt-2 mt-md-0">
                <label for="tipo" class="form-label">Tipo</label>
                <select v-model="this.form.tipo" id="tipo" class="form-select"
                        :disabled="!this.form.activo" required>
                  <option value="1">Evento</option>
                  <option value="2">Feria</option>
                </select>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12">
                <label for="titulo" class="form-label">Título</label>
                <input v-model="this.form.titulo" id="titulo" type="text" class="form-control" maxlength="100" :disabled="!this.form.activo" required>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12 col-md-4">
                <label for="fechaInicio" class="form-label">Fecha de inicio</label>
                <input v-model="this.form.fechaInicio" id="fechaInicio" class="form-control" type="date" :disabled="!this.form.activo">
              </div>
              <div class="col-12 col-md-4 mt-2 mt-md-0">
                <label for="fechaFinalizacion" class="form-label">Fecha de finalización</label>
                <input v-model="this.form.fechaFinalizacion" id="fechaFinalizacion" class="form-control" type="date" :disabled="!this.form.activo">
              </div>
              <div class="col-12 col-md-4 mt-2 mt-md-0">
                <label for="fechaRecordar" class="form-label">Fecha de recordatorio</label>
                <input v-model="this.form.recordar" id="fechaRecordar" class="form-control" type="date" :disabled="!this.form.activo">
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12 col-md-6">
                <label for="contacto" class="form-label">Contacto</label>
                <input v-model="this.form.contacto" id="contacto" type="number" class="form-control" maxlength="10" :disabled="!this.form.activo" required>
              </div>
              <div class="col-12 col-md-6 mt-2 mt-md-0">
                <label for="urlVideo" class="form-label">Enlace del Vídeo en Youtube</label>
                <input v-model="this.form.urlVideo" id="urlVideo" type="text" class="form-control"
                       placeholder="https://youtube.com/" maxlength="255" :disabled="!this.form.activo" required>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12 col-md-6">
                <label for="imagen" class="form-label">Imagen
                  <a v-if="this.form.urlImagen != null" :href="this.form.urlImagen" target="_blank">ver actual</a>
                </label>
                <input @change="onChangeInputImagen($event)" class="form-control" type="file" id="imagen" accept="image/jpeg,image/png"
                       :disabled="!this.form.activo">
              </div>
              <div class="col-12 col-md-6 mt-2 mt-md-0">
                <label for="pdf" class="form-label">Pdf
                  <a v-if="this.form.urlPdf != null" :href="this.form.urlPdf" target="_blank">ver actual</a>
                </label>
                <input @change="onChangeInputPdf($event)" class="form-control" type="file" id="pdf" accept="application/pdf"
                       :disabled="!this.form.activo">
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label">Seleccione la ubicación de la actividad</label>
                <GoogleMap
                    v-if="this.googleMap.visible"
                    :api-key="this.googleMap.key"
                    style="width: 100%; height: 400px"
                    :center="this.googleMap.center"
                    :zoom="12"
                    :disabled="!this.form.activo">
                  <Marker
                      :options="{ position: this.googleMap.marker, draggable: true, clickable: true }"
                      @dragend="onGeolocalizacionActualizar($event)" />
                </GoogleMap>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-12">
                <label class="form-label">Descripción</label>
                <Editor :api-key="tiny.key" :init="tiny.init" v-model="this.form.descripcion" :disabled="!this.form.activo"/>
              </div>
            </div>
          </div>
          <div class="d-flex flex-row-reverse">
            <button :disabled="!this.form.activo" type="submit" class="btn btn-primary waves-effect waves-light">Modificar</button>
            <a @click="router(s).back()" class="btn btn-soft-secondary waves-effect waves-light me-2">Atrás</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import {GoogleMap, Marker} from "vue3-google-map";
import Service from "@/utils/Service";
import {route} from "@/utils/Route";
import {GOOGLE_MAP_KEY} from "@/utils/config";
import Toast from "@/utils/Toast";
import router from "@/router";
import Editor from "@tinymce/tinymce-vue";
import {tinyMCE} from "@/utils/config";
import MensajeAlertaComponent from "@/components/utils/MensajeAlerta.vue";

export default {
  name: 'ActividadEditarView',
  components: {MensajeAlertaComponent, Editor, Marker, GoogleMap},
  data() {
    return {
      error: {
        mostrar: false,
        mensaje: null,
      },
      tiny: tinyMCE,
      form: {
        activo: true,
        tipo: 1,
        titulo: '',
        fechaInicio: '',
        fechaFinalizacion: '',
        descripcion: '',
        contacto: '',
        urlVideo: '',
        imagen: null,
        pdf: null,
        latitud: 0,
        longitud: 0,
        recordar: null,
        departamento: 1
      },
      googleMap: {
        visible: false,
        center: {lat: -17.783602, lng: -63.182327},
        marker: {lat: -17.783602, lng: -63.182327},
        key: GOOGLE_MAP_KEY
      },
    }
  },
  methods: {
    router() {
      return router
    },
    mostrarActividad() {
      this.ocultarError();
      this.googleMap.visible = false;
      Service.get(route('actividad.mostrar', this.getParametrosMostrar())).then((data) => {
        this.form.tipo = data.data.tipo;
        this.form.titulo = data.data.titulo;
        this.form.fechaInicio = data.data.fechaInicio;
        this.form.fechaFinalizacion = data.data.fechaFinalizacion;
        this.form.recordar = data.data.recordar;
        this.form.descripcion = data.data.descripcion;
        this.form.contacto = data.data.contacto;
        this.form.urlVideo = data.data.urlVideo;
        this.form.urlImagen = data.data.urlImagen;
        this.form.urlPdf = data.data.urlPdf;
        this.form.latitud = data.data.latitud;
        this.form.longitud = data.data.longitud;
        this.form.departamento = data.data.departamento;
        this.googleMap.center.lat = Number(data.data.latitud);
        this.googleMap.center.lng = Number(data.data.longitud);
        this.googleMap.marker.lat = Number(data.data.latitud);
        this.googleMap.marker.lng = Number(data.data.longitud);
      }).catch((error) => {
        this.mostrarError(error);
      }).finally( () => {
        this.googleMap.visible = true;
      });
    },
    modificarActividad() {
      this.desactivarForm();
      this.ocultarError();
      Service.putFormData(route('actividad.modificar', this.getParametrosMostrar()), this.getParametros()).then( (data)=>{
        Toast.showSuccess(data.mensaje)
        router.push({name: 'actividad.listar'});
      }).catch( error => {
        this.mostrarError(error);
      }). finally( () => {
        this.activarForm();
      });
    },

    onGeolocalizacionActualizar(event) {
      this.form.latitud = event.latLng.lat();
      this.form.longitud = event.latLng.lng();
    },
    onChangeInputImagen(event) {
      this.form.imagen = event.target.files[0];
    },
    onChangeInputPdf(event) {
      this.form.pdf = event.target.files[0];
    },

    getParametrosMostrar() {
      return [this.$route.params.id];
    },
    getParametros() {
      let formData = new FormData();
      formData.append('tipo', this.form.tipo);
      formData.append('titulo', this.form.titulo);
      formData.append('fechaInicio', this.form.fechaInicio);
      formData.append('fechaFinalizacion', this.form.fechaFinalizacion);
      formData.append('recordar', this.form.recordar);
      formData.append('descripcion', this.form.descripcion);
      formData.append('contacto', this.form.contacto);
      formData.append('latitud', this.form.latitud);
      formData.append('longitud', this.form.longitud);
      formData.append('urlVideo', this.form.urlVideo);
      formData.append('imagen', this.form.imagen);
      formData.append('pdf', this.form.pdf);
      formData.append('departamento', this.form.departamento);

      return formData;
    },
    desactivarForm() {
      this.form.activo = false;
    },
    activarForm() {
      this.form.activo = true;
    },

    ocultarError() {
      this.error.mostrar = false;
      this.error.mensaje = '';
    },
    mostrarError(error) {
      this.error.mostrar = true;
      this.error.mensaje = error;
    },
  },
  mounted() {
    this.mostrarActividad();
  }
}
</script>