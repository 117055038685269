import AppHome from "@/views/template/AppHome";
import AdministradorListarView from "@/modules/administracion/views/administrador/ListarView.vue";
import AdministradorMostrarView from "@/modules/administracion/views/administrador/MostrarView.vue";
import AdministradorCrearView from "@/modules/administracion/views/administrador/CrearView.vue";
import AdministradorEditarView from "@/modules/administracion/views/administrador/EditarView.vue";
import CultivadorListarView from "@/modules/administracion/views/cultivador/ListarView.vue";
import CultivadorMostrarView from "@/modules/administracion/views/cultivador/MostrarView.vue";
import ChatListarView from "@/modules/administracion/views/chat/ListarView.vue";
import ChatMostrarView from "@/modules/administracion/views/chat/MostrarView.vue";
import CultivoListarView from "@/modules/administracion/views/cultivo/ListarView.vue";
import CultivoCrearView from "@/modules/administracion/views/cultivo/CrearView.vue";
import CultivoEditarView from "@/modules/administracion/views/cultivo/EditarView.vue";
import CultivoMostrarView from "@/modules/administracion/views/cultivo/MostrarView.vue";
import RedSocialListarView from "@/modules/administracion/views/redsocial/ListarView.vue";
import RedSocialCrearView from "@/modules/administracion/views/redsocial/CrearView.vue";
import RedSocialEditarView from "@/modules/administracion/views/redsocial/EditarView.vue";

const moduleRoute = {
    path: "/administrador",
    component: AppHome,
    children: [
        {
            path: "/administrador/",
            name: "administrador.listar",
            components: { content: AdministradorListarView},
        },
        {
            path: "/administrador/crear",
            name: "administrador.crear",
            components: { content: AdministradorCrearView},
        },
        {
            path: "/administrador/:id/editar",
            name: "administrador.editar",
            components: { content: AdministradorEditarView},
        },
        {
            path: "/administrador/:id",
            name: "administrador.mostrar",
            components: { content: AdministradorMostrarView},
        },
        {
            path: "/cultivador/",
            name: "cultivador.listar",
            components: { content: CultivadorListarView},
        },
        {
            path: "/cultivador/:id",
            name: "cultivador.mostrar",
            components: { content: CultivadorMostrarView},
        },
        {
            path: "/chat/",
            name: "chat.listar",
            components: { content: ChatListarView},
        },
        {
            path: "/chat/:id",
            name: "chat.mostrar",
            components: { content: ChatMostrarView},
        },
        {
            path: "/cultivo/",
            name: "cultivo.listar",
            components: { content: CultivoListarView},
        },
        {
            path: "/cultivo/crear",
            name: "cultivo.crear",
            components: { content: CultivoCrearView},
        },
        {
            path: "/cultivo/:id/editar",
            name: "cultivo.editar",
            components: { content: CultivoEditarView},
        },
        {
            path: "/cultivo/:id/mostrar",
            name: "cultivo.mostrar",
            components: { content: CultivoMostrarView},
        },
        {
            path: "/redsocial",
            name: "redsocial.listar",
            components: { content: RedSocialListarView},
        },
        {
            path: "/redsocial/crear",
            name: "redsocial.crear",
            components: { content: RedSocialCrearView},
        },
        {
            path: "/redsocial/:id/editar",
            name: "redsocial.editar",
            components: { content: RedSocialEditarView},
        },
    ]
};

export default router => {
    router.addRoute(moduleRoute);
};
