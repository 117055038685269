<template>
  <div>
    <div id="modalGuiaPlanificacionImagenAdicionar" class="modal fade" tabindex="-1"
         aria-labelledby="modalLabelGuiaPlanificacionImagenAdicionar" aria-hidden="true" style="display: none;">
      <div class="modal-dialog">
        <div class="modal-content">
          <form v-on:submit.prevent="agregarGuiaPlanificacionImagen()" id="form">
            <div class="modal-header">
              <h5 class="modal-title" id="modalTituloGuiaPlanificacionImagenEliminar">Adicionar una imagen</h5>
              <button id="btnCerrarModalGuiaPlanificacionImagenAdicionar" ref="btnCerrarModalGuiaPlanificacionImagenAdicionar"
                      type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <label for="imagen" class="form-label">Seleccione una Imagen</label>
                  <input @change="onChangeInputImagen($event)" class="form-control" type="file" id="imagen" accept="image/jpeg,image/png"
                         :disabled="!this.form.activo" required>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button :disabled="!this.form.activo" type="submit" class="btn btn-primary waves-effect waves-light">Agregar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Service from "@/utils/Service";
import {route} from "@/utils/Route";
import Toast from "@/utils/Toast";

export default {
  name: 'ModalGuiaPlanificacionImagenAdicionarComponent',
  props: {
    guiaPlanificacion: Number
  },
  data() {
    return {
      form: {
        activo: true,
        imagen: null
      }
    }
  },
  methods: {
    agregarGuiaPlanificacionImagen() {
      this.desactivarForm();
      Service.postFormData(route('guia.planificacion.imagen.registrar', [this.guiaPlanificacion]),
          this.getParametros()).then( (data) => {
        Toast.showSuccess(data.mensaje)
        this.$emit('onGuiaPlanificacionImagenAgregado');
        this.$refs.btnCerrarModalGuiaPlanificacionImagenAdicionar.click();
      }).catch((error) => {
        console.log(error)
      }).finally(()=>{
        this.activarForm();
      });
    },
    onChangeInputImagen(event) {
      this.form.imagen = event.target.files[0];
    },
    getParametros() {
      let formData = new FormData();
      formData.append('imagen', this.form.imagen);
      return formData;
    },
    desactivarForm() {
      this.form.activo = false;
    },
    activarForm() {
      this.form.activo = true;
    },
  }
}
</script>