<template>
  <div>
    <div id="modalActividadEliminar" class="modal fade" tabindex="-1" aria-labelledby="modalLabelActividadEliminar" aria-hidden="true" style="display: none;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalTituloActividadEliminar">Eliminar una actividad</h5>
            <button id="btnCerrarModalActividadEliminar" ref="btnCerrarModalActividadEliminar"
                    type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
          </div>
          <div class="modal-body">
            <mensaje-alerta-component :error="this.error" class="mt-2" />
            <p class="text-muted">Desea eliminar
              <span class="fw-bolder fs-15">{{this.actividad.tipo === 1 ? 'el Evento ' : 'la Feria '}}</span>
              <span class="fw-bolder fs-15">{{this.actividad.titulo}}</span>
            </p>
          </div>
          <div class="modal-footer">
            <button :disabled="!this.form.activo" type="button" class="btn btn-light" data-bs-dismiss="modal">Cerrar</button>
            <button :disabled="!this.form.activo" @click="eliminarActividad" type="button" class="btn btn-danger">Si, Eliminar</button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Service from "@/utils/Service";
import {route} from "@/utils/Route";
import Toast from "@/utils/Toast";
import MensajeAlertaComponent from "@/components/utils/MensajeAlerta.vue";

export default {
  name: 'ModalActividadEliminarComponent',
  components: {MensajeAlertaComponent},
  props: {
    actividad: {
      id: Number,
      titulo: String,
    }
  },
  data() {
    return {
      error: {
        mostrar: false,
        mensaje: null,
      },
      form: {
        activo: true
      },
    }
  },
  methods: {
    eliminarActividad() {
      this.desactivarForm();
      this.ocultarError();
      Service.delete(route('actividad.eliminar', [this.actividad.id])).then( (data) => {
        Toast.showSuccess(data.mensaje)
        this.$emit('onActividadEliminado');
        this.$refs.btnCerrarModalActividadEliminar.click();
      }).catch((error) => {
        this.mostrarError(error);
      }).finally(()=>{
        this.activarForm();
      });
    },

    desactivarForm() {
      this.form.activo = false;
    },
    activarForm() {
      this.form.activo = true;
    },

    ocultarError() {
      this.error.mostrar = false;
      this.error.mensaje = '';
    },
    mostrarError(error) {
      this.error.mostrar = true;
      this.error.mensaje = error;
    },
  }

}
</script>