<template>
  <div class="container">
    <div class="d-flex flex-column">
      <mensaje-alerta-component :error="this.error" class="mb-2" />
      <div class="mb-2">
        <a @click="router().back()" class="btn btn-soft-secondary waves-effect waves-light me-2">Atrás</a>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="d-flex position-relative">
            <div class="row">
              <div class="col-12">
                <div class="d-flex flex-column">
                  <h6 class="card-title mb-0">Alerta N° {{this.alerta.id}}</h6>
                  <div class="d-flex mt-3">
                    <span class="fw-medium">Departamento: </span>
                    <span class="ms-2 text-muted">{{this.alerta.departamentoDato.nombre}}</span>
                  </div>
                  <div class="d-flex">
                    <span class="fw-medium">Fecha: </span>
                    <span class="ms-2 text-muted">{{this.alerta.fecha}}</span>
                  </div>
                  <div class="d-flex">
                    <span class="fw-medium">Hora: </span>
                    <span class="ms-2 text-muted">{{this.alerta.hora}}</span>
                  </div>
                  <div class="d-flex">
                    <span class="fw-medium">Estado: </span>
                    <div class="ms-2">
                      <span v-if="this.alerta.ejecutado" class="badge text-bg-success">Ejecutado</span>
                      <span v-else class="badge text-bg-danger">Pendiente</span>
                    </div>
                  </div>
                  <div class="d-flex">
                  <span class="text-muted">Registrado por {{this.alerta.usuarioDato.personaDato.apellidos + ' ' + this.alerta.usuarioDato.personaDato.nombre}}
                    el {{this.alerta.fechaHoraRegistrado}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <h6 class="card-title mb-0">{{this.alerta.titulo}}</h6>
          <div>{{this.alerta.descripcion}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Service from "@/utils/Service";
import {route} from "@/utils/Route";
import router from "@/router";
import MensajeAlertaComponent from "@/components/utils/MensajeAlerta.vue";

export default {
  name: 'AlertaMostrarView',
  components: {MensajeAlertaComponent},
  data() {
    return {
      error: {
        mostrar: false,
        mensaje: null,
      },
      alerta: {
        titulo: '',
        fecha: '',
        hora: '',
        descripcion: '',
        departamentoDato: {
          nombre: ''
        },
        usuarioDato: {
          personaDato: {
            nombre: '',
            apellidos: ''
          }
        }
      }
    }
  },
  methods: {
    router() {
      return router
    },
    mostrarAlerta() {
      this.ocultarError();
      Service.get(route('alerta.mostrar', this.getParametrosMostrar())).then( data => {
        this.alerta = data.data;
      }).catch( error => {
        this.mostrarError(error);
      });
    },

    getParametrosMostrar() {
      return [this.$route.params.id];
    },

    ocultarError() {
      this.error.mostrar = false;
      this.error.mensaje = '';
    },
    mostrarError(error) {
      this.error.mostrar = true;
      this.error.mensaje = error;
    },
  },
  mounted() {
    this.mostrarAlerta();
  }
}
</script>