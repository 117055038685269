<template>
  <div class="d-flex">
    <div class="flex-fill">
      <label for="tipoSiembra" class="form-label">Tipo de Siembra</label>
      <select v-model="this.form.tipoSiembra" @change="onChangeTipoSiembra($event)" id="tipoSiembra"
              class="form-select">
        <option value="0">Todos</option>
        <option v-for="tipoSiembra in this.lista.tipoSiembras" :key="tipoSiembra.id"
                :value="tipoSiembra.id">{{tipoSiembra.nombre}}
        </option>
      </select>
    </div>
  </div>
</template>
<script>
import Service from "@/utils/Service";
import {route} from "@/utils/Route";
import router from "@/router";

export default {
  name: 'SelectTipoSiembraComponent',
  data() {
    return {
      lista: {
        tipoSiembras: []
      },
      form: {
        tipoSiembra: 0
      }
    }
  },
  methods: {
    listarTipoSiembra() {
      Service.get(route('recurso.tipo_siembra')).then( data => {
        this.lista.tipoSiembras = data.data;
      }).catch( error => {
        console.log(error);
      });
    },
    onChangeTipoSiembra(event) {
      if (event.target.value > 0) {
        let params = {...this.$route.query, tipoSiembra: this.form.tipoSiembra};
        router.push({ path: this.$route.path, query: params });
      }
    },
  },
  mounted() {
    this.listarTipoSiembra();
    if (this.$route.query.tipoSiembra !== undefined)
      this.form.tipoSiembra = this.$route.query.tipoSiembra;
  }
}
</script>