<template>
  <div class="card">
    <div class="card-header">
      <h6 class="card-title mb-0">Cambiar estado</h6>
    </div>
    <div class="card-body">
      <mensaje-alerta-component :error="this.error" class="mb-2" />
      <form v-if="!this.form.verificar"  v-on:submit.prevent="verificarUsuario()" id="formVerificar">
        <div class="d-flex flex-column">
          <div class="d-flex flex-row-reverse">
            <button :disabled="!this.form.activo" type="submit" class="btn btn-success waves-effect waves-light">Verificar</button>
          </div>
        </div>
      </form>
      <form v-if="this.form.verificar"  v-on:submit.prevent="desverificarUsuario()" id="formDesverificar">
        <div class="d-flex flex-column">
          <div class="d-flex flex-row-reverse">
            <button :disabled="!this.form.activo" type="submit" class="btn btn-danger waves-effect waves-light">Desverificar</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Service from "@/utils/Service";
import {route} from "@/utils/Route";
import Toast from "@/utils/Toast";
import MensajeAlertaComponent from "@/components/utils/MensajeAlerta.vue";

export default {
  name: 'CultivadorUsuarioCambiarEstadoComponent',
  components: {MensajeAlertaComponent},
  props: {
    persona: {
      id: Number
    },
    verificado: Boolean
  },
  data() {
    return {
      error: {
        mostrar: false,
        mensaje: null,
      },
      form: {
        verificar: this.verificado,
        activo: true
      }
    }
  },
  methods: {
    verificarUsuario() {
      this.desactivarForm();
      this.ocultarError();
      Service.put(route('cultivador.usuario.verificar', [this.persona.id])).then((data) => {
        Toast.showSuccess(data.mensaje)
        this.form.verificar = true;
        this.$emit('onCultivadorVerificado', true);
      }).catch( (error) => {
        this.mostrarError(error);
      }).finally( () => {
        this.activarForm();
      });
    },

    desverificarUsuario() {
      this.desactivarForm();
      this.ocultarError();
      Service.put(route('cultivador.usuario.desverificar', [this.persona.id])).then((data) => {
        Toast.showSuccess(data.mensaje)
        this.form.verificar = false;
        this.$emit('onCultivadorVerificado', false);
      }).catch( (error) => {
        this.mostrarError(error);
      }).finally( () => {
        this.activarForm();
      });
    },

    desactivarForm() {
      this.form.activo = false;
    },
    activarForm() {
      this.form.activo = true;
    },

    ocultarError() {
      this.error.mostrar = false;
      this.error.mensaje = '';
    },
    mostrarError(error) {
      this.error.mostrar = true;
      this.error.mensaje = error;
    },
  },
  watch: {
    verificado: function (nuevo) {
      this.form.verificar = nuevo;
    }
  }

}
</script>