<template>
  <div>
    <div class="mb-2"><h3>Lista de redes sociales</h3></div>
    <div class="d-flex flex-column flex-md-row justify-content-between">
      <div class="d-flex flex-row">
        <select-departamento-component class="m-md-1"/>
        <select-tipo-red-social-component class="m-md-1 ms-2"/>
      </div>
      <div class="d-flex align-items-center justify-content-end pt-2">
        <div class="dropdown">
          <button class="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
            Exportar
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" :href="routeQuery('reporte.administracion.redsocial.excel', this.$route.query)" target="_blank">Excel</a>
          </div>
        </div>
        <router-link :to="{name: 'redsocial.crear'}" class="btn btn-success waves-effect waves-light ms-2">
          Agregar Nuevo
        </router-link>
      </div>
    </div>
    <mensaje-alerta-component :error="this.error" class="my-2" />
    <tabla-red-social-listar-component ref="tablaRedSocialListar" @onRedSocialEliminar="onRedSocialEliminar" class="mt-2 mt-md-0"/>
    <modal-red-social-eliminar-component :redSocial="this.redSocial" @onRedSocialEliminado="onRedSocialEliminado"/>
  </div>
</template>
<script>
import SelectDepartamentoComponent from '@/modules/administracion/components/redsocial/SelectDepartamentoComponent.vue';
import SelectTipoRedSocialComponent from "@/modules/administracion/components/redsocial/SelectTipoRedSocialComponent.vue";
import TablaRedSocialListarComponent from "@/modules/administracion/components/redsocial/TablaListarComponent.vue";
import ModalRedSocialEliminarComponent from "@/modules/administracion/components/redsocial/ModalEliminarComponent.vue";
import Service from "@/utils/Service";
import {route, routeQuery} from "@/utils/Route";
import MensajeAlertaComponent from "@/components/utils/MensajeAlerta.vue";
export default {
  name: 'RedSocialListarView',
  components: {
    MensajeAlertaComponent,
    ModalRedSocialEliminarComponent, TablaRedSocialListarComponent,
    SelectTipoRedSocialComponent, SelectDepartamentoComponent,
  },
  data() {
    return {
      error: {
        mostrar: false,
        mensaje: null,
      },
      redSociales: [],
      redSocial: {
        id: 0,
        nombre: '',
        tipoRedSocialDato: {
          nombre: ''
        }
      }
    }
  },
  methods: {
    routeQuery,
    listarRedSocial() {
      this.ocultarError();
      Service.get(this.getRutaParametros()).then((data) => {
        this.redSociales = data.data;
        this.$refs.tablaRedSocialListar.setRedSociales(this.redSociales);
      }).catch((error) => {
        this.mostrarError(error);
      });
    },

    getRutaParametros() {
      let ruta = route('red_social.listar');
      let departamento = 0;
      let tipoRedSocial = 0;
      if (this.$route.query.departamento !== undefined) {
        departamento = this.$route.query.departamento;
      }
      if (this.$route.query.tipoRedSocial !== undefined) {
        tipoRedSocial = this.$route.query.tipoRedSocial;
      }
      return ruta + '?tipoRedSocial=' + tipoRedSocial +'&departamento=' + departamento;
    },

    onRedSocialEliminar(redSocial) {
      this.redSocial = redSocial;
    },

    onRedSocialEliminado() {
      this.listarRedSocial();
    },

    ocultarError() {
      this.error.mostrar = false;
      this.error.mensaje = '';
    },
    mostrarError(error) {
      this.error.mostrar = true;
      this.error.mensaje = error;
    }
  },
  mounted() {
    this.listarRedSocial();
  },
  updated() {
    this.listarRedSocial();
  }
}
</script>