<template>
  <div class="container">
    <div class="d-flex flex-column">
      <mensaje-alerta-component :error="this.error" class="mb-2" />
      <div class="mb-2">
        <a @click="router().back()" class="btn btn-soft-secondary waves-effect waves-light me-2">Atrás</a>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="d-flex position-relative">
            <div class="row">
              <div class="col-12 col-md-4 col-xl-3">
                <img :src="this.actividad.urlImagen" class="rounded img-fluid" alt="actividad punto-verde"/>
              </div>
              <div class="col-12 col-md-8 col-xl-9 mt-2 mt-md-0">
                <div class="d-flex flex-column">
                  <h6 class="card-title mb-0">{{this.actividad.titulo}}</h6>
                  <div class="text-muted">{{this.actividad.tipo === 1 ? 'Evento' : 'Feria'}}</div>
                  <div class="d-flex mt-3">
                    <span class="fw-medium">Departamento: </span>
                    <span class="ms-2 text-muted">{{this.actividad.departamentoDato.nombre}}</span>
                  </div>
                  <div class="d-flex">
                    <span class="fw-medium">Fecha: </span>
                    <span class="ms-2 text-muted">{{this.actividad.fechaInicio}} al {{this.actividad.fechaFinalizacion}}</span>
                  </div>
                  <div class="d-flex">
                    <span class="fw-medium">Fecha de recordatorio: </span>
                    <span v-if="this.actividad.recordar != null" class="ms-2 text-muted">{{this.actividad.recordar}}</span>
                    <span v-else class="ms-2 text-muted">Sin definir</span>
                  </div>
                  <div class="d-flex">
                    <span class="fw-medium">Ubicación: </span>
                    <span class="ms-2 text-muted">
                      <a :href="`https://www.google.com/maps?q=${this.actividad.latitud},${this.actividad.longitud}`" target="_blank">abrir</a>
                    </span>
                  </div>
                  <div class="d-flex align-items-center">
                    <span class="fw-medium">Recursos: </span>
                    <div class="d-flex">
                      <a v-if="actividad.urlVideo != null" :href="actividad.urlVideo" target="_blank" class="ms-2">
                        <i class="mdi mdi-youtube text-danger fs-24"></i>
                      </a>
                      <a v-if="actividad.urlPdf != null" :href="actividad.urlPdf" target="_blank" class="ms-2">
                        <i class="mdi mdi-file-pdf-box text-danger fs-24"></i>
                      </a>
                    </div>
                  </div>
                  <div class="d-flex">
                    <span class="text-muted">Registrado por {{this.actividad.usuarioDato.personaDato.apellidos + ' ' + this.actividad.usuarioDato.personaDato.nombre}}
                      el {{this.actividad.fechaHoraRegistrado}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <h6 class="card-title mb-0">Descripción</h6>
          <div v-html="this.actividad.descripcion"></div>
        </div>
      </div>

      <div>
        <div class="d-flex">
          <a class="btn btn-success waves-effect waves-light" href="javascript:void(0)"
             data-bs-toggle="modal" data-bs-target="#modalActividadImagenAdicionar">Agregar Imagen
          </a>
        </div>
      </div>

      <div class="mt-2">
        <div class="row">
          <div v-for="imagen in this.imagenes" :key="imagen.id" class="card col-12 col-md-6 col-xl-4 col-xxl-3 ms-md-2">
            <figure class="figure">
              <img :src="imagen.url" class="figure-img img-fluid rounded" alt="punto_verde">
              <figcaption class="figure-caption text-end">
                <a @click="onActividadImagenEliminar(imagen)" class="badge bg-danger" href="javascript:void(0)"
                   data-bs-toggle="modal" data-bs-target="#modalActividadImagenEliminar">
                  <span>Eliminar</span>
                </a>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </div>
    <modal-actividad-imagen-adicionar-component :actividad="this.actividad.id" @onActividadImagenAgregado="onActividadImagenAgregado" />
    <modal-actividad-imagen-eliminar-component :actividad="this.actividad.id" :imagen="this.imagen" @onActividadImagenEliminado="onActividadImagenEliminado" />
  </div>
</template>
<script>
import Service from "@/utils/Service";
import {route} from "@/utils/Route";
import ModalActividadImagenAdicionarComponent from "@/modules/actividad/components/actividad/imagen/ModalAdicionarComponent.vue";
import ModalActividadImagenEliminarComponent from "@/modules/actividad/components/actividad/imagen/ModalEliminarComponent.vue";
import router from "@/router";
import MensajeAlertaComponent from "@/components/utils/MensajeAlerta.vue";

export default {
  name: 'ActividadMostrarView',
  components: {MensajeAlertaComponent, ModalActividadImagenEliminarComponent, ModalActividadImagenAdicionarComponent},
  data() {
    return {
      error: {
        mostrar: false,
        mensaje: null,
      },
      actividad: {
        departamentoDato: {
          nombre: ''
        },
        usuarioDato: {
          personaDato: {
            nombre: '',
            apellidos: ''
          }
        }
      },
      imagenes: [],
      imagen: {id:0}
    }
  },
  methods: {
    router() {
      return router
    },
    mostrarActividad() {
      this.ocultarError();
      Service.get(route('actividad.mostrar', this.getParametrosMostrar())).then((data) => {
        this.actividad = data.data;
      }).catch((error) => {
        this.mostrarError(error);
      });
    },
    listarActividadImagen() {
      this.ocultarError();
      Service.get(route('actividad.imagen.listar', this.getParametrosMostrar())).then((data) => {
        this.imagenes = data.data;
      }).catch((error) => {
        this.mostrarError(error);
      });
    },

    onActividadImagenEliminar( img ) {
      this.imagen = img;
    },
    onActividadImagenAgregado() {
      this.listarActividadImagen();
    },
    onActividadImagenEliminado() {
      this.listarActividadImagen();
    },
    getParametrosMostrar() {
      return [this.$route.params.id]
    },

    ocultarError() {
      this.error.mostrar = false;
      this.error.mensaje = '';
    },
    mostrarError(error) {
      this.error.mostrar = true;
      this.error.mensaje = error;
    },
  },
  mounted() {
    this.mostrarActividad();
    this.listarActividadImagen();
  }
}
</script>