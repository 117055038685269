import {URL_API} from "@/utils/config";
import {URL_REPORTE} from "@/utils/config";

export function __getRoute(name) {
    switch (name) {
        case 'iniciar_sesion': return URL_API + '/administrador/autenticacion/login';
        case 'usuario': return URL_API + '/administrador/autenticacion/usuario';
        case 'home': return URL_API + '/administrador/home';

        case 'huerto.listar': return URL_API + '/administrador/huerto';
        case 'huerto.mostrar': return URL_API + '/administrador/huerto/{id}';
        case 'huerto.registrar': return URL_API + '/administrador/huerto';
        case 'huerto.modificar': return URL_API + '/administrador/huerto/{id}';
        case 'huerto.eliminar': return URL_API + '/administrador/huerto/{id}';
        case 'huerto.cultivo.listar': return URL_API + '/administrador/huerto/{huerto}/cultivo';
        case 'huerto.cultivo.registrar': return URL_API + '/administrador/huerto/{huerto}/cultivo';
        case 'huerto.cultivo.eliminar': return URL_API + '/administrador/huerto/{huerto}/cultivo/{cultivo}';

        case 'guia.produccion.listar': return URL_API + '/administrador/guia/produccion';
        case 'guia.produccion.mostrar': return URL_API + '/administrador/guia/produccion/{id}';
        case 'guia.produccion.registrar': return URL_API + '/administrador/guia/produccion';
        case 'guia.produccion.modificar': return URL_API + '/administrador/guia/produccion/{id}';
        case 'guia.produccion.eliminar': return URL_API + '/administrador/guia/produccion/{id}';

        case 'guia.riego.listar': return URL_API + '/administrador/guia/riego';
        case 'guia.riego.mostrar': return URL_API + '/administrador/guia/riego/{id}';
        case 'guia.riego.registrar': return URL_API + '/administrador/guia/riego';
        case 'guia.riego.modificar': return URL_API + '/administrador/guia/riego/{id}';
        case 'guia.riego.eliminar': return URL_API + '/administrador/guia/riego/{id}';

        case 'guia.plaga.listar': return URL_API + '/administrador/guia/plaga';
        case 'guia.plaga.mostrar': return URL_API + '/administrador/guia/plaga/{id}';
        case 'guia.plaga.registrar': return URL_API + '/administrador/guia/plaga';
        case 'guia.plaga.modificar': return URL_API + '/administrador/guia/plaga/{id}';
        case 'guia.plaga.eliminar': return URL_API + '/administrador/guia/plaga/{id}';

        case 'guia.tratamiento.listar': return URL_API + '/administrador/guia/tratamiento';
        case 'guia.tratamiento.mostrar': return URL_API + '/administrador/guia/tratamiento/{id}';
        case 'guia.tratamiento.registrar': return URL_API + '/administrador/guia/tratamiento';
        case 'guia.tratamiento.modificar': return URL_API + '/administrador/guia/tratamiento/{id}';
        case 'guia.tratamiento.eliminar': return URL_API + '/administrador/guia/tratamiento/{id}';

        case 'guia.planificacion.listar': return URL_API + '/administrador/guia/planificacion';
        case 'guia.planificacion.mostrar': return URL_API + '/administrador/guia/planificacion/{id}';
        case 'guia.planificacion.registrar': return URL_API + '/administrador/guia/planificacion';
        case 'guia.planificacion.modificar': return URL_API + '/administrador/guia/planificacion/{id}';
        case 'guia.planificacion.eliminar': return URL_API + '/administrador/guia/planificacion/{id}';
        case 'guia.planificacion.imagen.listar': return URL_API + '/administrador/guia/planificacion/{guiaPlanificacion}/imagen';
        case 'guia.planificacion.imagen.registrar': return URL_API + '/administrador/guia/planificacion/{guiaPlanificacion}/imagen';
        case 'guia.planificacion.imagen.eliminar': return URL_API + '/administrador/guia/planificacion/{guiaPlanificacion}/imagen/{id}';

        case 'guia.cultivo.listar': return URL_API + '/administrador/guia/{guia}/cultivo';
        case 'guia.cultivo.registrar': return URL_API + '/administrador/guia/{guia}/cultivo';
        case 'guia.cultivo.eliminar': return URL_API + '/administrador/guia/{guia}/cultivo/{cultivo}';

        case 'red_social.listar': return URL_API + '/administrador/red_social';
        case 'red_social.mostrar': return URL_API + '/administrador/red_social/{id}';
        case 'red_social.registrar': return URL_API + '/administrador/red_social';
        case 'red_social.modificar': return URL_API + '/administrador/red_social/{id}';
        case 'red_social.eliminar': return URL_API + '/administrador/red_social/{id}';

        case 'alerta.listar': return URL_API + '/administrador/alerta';
        case 'alerta.mostrar': return URL_API + '/administrador/alerta/{id}';
        case 'alerta.registrar': return URL_API + '/administrador/alerta';
        case 'alerta.modificar': return URL_API + '/administrador/alerta/{id}';
        case 'alerta.eliminar': return URL_API + '/administrador/alerta/{id}';

        case 'actividad.listar': return URL_API + '/administrador/actividad';
        case 'actividad.mostrar': return URL_API + '/administrador/actividad/{id}';
        case 'actividad.registrar': return URL_API + '/administrador/actividad';
        case 'actividad.modificar': return URL_API + '/administrador/actividad/{id}';
        case 'actividad.eliminar': return URL_API + '/administrador/actividad/{id}';
        case 'actividad.imagen.listar': return URL_API + '/administrador/actividad/{actividad}/imagen';
        case 'actividad.imagen.registrar': return URL_API + '/administrador/actividad/{actividad}/imagen';
        case 'actividad.imagen.eliminar': return URL_API + '/administrador/actividad/{actividad}/imagen/{id}';

        case 'administrador.listar': return URL_API + '/administrador/administrador';
        case 'administrador.mostrar': return URL_API + '/administrador/administrador/{id}';
        case 'administrador.registrar': return URL_API + '/administrador/administrador';
        case 'administrador.modificar': return URL_API + '/administrador/administrador/{id}';
        case 'administrador.eliminar': return URL_API + '/administrador/administrador/{id}';
        case 'administrador.usuario.permiso.crear': return URL_API + '/administrador/administrador/{id}/usuario/permiso/crear';
        case 'administrador.usuario.permiso.registrar': return URL_API + '/administrador/administrador/{id}/usuario/permiso';
        case 'administrador.usuario.contrasena.modificar': return URL_API + '/administrador/administrador/{id}/usuario/contrasena';
        case 'administrador.usuario.habilitar': return URL_API + '/administrador/administrador/{id}/usuario/activar';
        case 'administrador.usuario.inhabilitar': return URL_API + '/administrador/administrador/{id}/usuario/desactivar';

        case 'cultivador.listar': return URL_API + '/administrador/cultivador';
        case 'cultivador.mostrar': return URL_API + '/administrador/cultivador/{id}';
        case 'cultivador.usuario.contrasena.modificar': return URL_API + '/administrador/cultivador/{id}/usuario/contrasena';
        case 'cultivador.usuario.verificar': return URL_API + '/administrador/cultivador/{id}/usuario/verificar';
        case 'cultivador.usuario.desverificar': return URL_API + '/administrador/cultivador/{id}/usuario/desverificar';

        case 'cultivo.listar': return URL_API + '/administrador/cultivo';
        case 'cultivo.mostrar': return URL_API + '/administrador/cultivo/{id}';
        case 'cultivo.registrar': return URL_API + '/administrador/cultivo';
        case 'cultivo.modificar': return URL_API + '/administrador/cultivo/{id}';
        case 'cultivo.eliminar': return URL_API + '/administrador/cultivo/{id}';

        case 'recurso.tipo_cultivo': return URL_API + '/recurso/tipo_cultivo';
        case 'recurso.tipo_cultivo.cultivo': return URL_API + '/recurso/tipo_cultivo/{id}/cultivo';
        case 'recurso.tipo_riego': return URL_API + '/recurso/tipo_riego';
        case 'recurso.tipo_plaga': return URL_API + '/recurso/tipo_plaga';
        case 'recurso.tipo_plaga.plaga': return URL_API + '/recurso/tipo_plaga/{id}/plaga';
        case 'recurso.tipo_tratamiento': return URL_API + '/recurso/tipo_tratamiento';
        case 'recurso.tipo_siembra': return URL_API + '/recurso/tipo_siembra';
        case 'recurso.zona': return URL_API + '/recurso/zona';
        case 'recurso.tipo_red_social': return URL_API + '/recurso/tipo_red_social';
        case 'recurso.cultivador': return URL_API + '/recurso/cultivador';
        case 'recurso.tinycme.imagen': return URL_API + '/recurso/ckeditor/cargar-imagen';

        case 'reporte.huerto.excel': return URL_REPORTE + '/huerto/excel';
        case 'reporte.guia.produccion.excel': return URL_REPORTE + '/guia/produccion/excel';
        case 'reporte.guia.riego.excel': return URL_REPORTE + '/guia/riego/excel';
        case 'reporte.guia.plaga.excel': return URL_REPORTE + '/guia/plaga/excel';
        case 'reporte.guia.tratamiento.excel': return URL_REPORTE + '/guia/tratamiento/excel';
        case 'reporte.guia.planificacion.excel': return URL_REPORTE + '/guia/planificacion/excel';
        case 'reporte.actividad.excel': return URL_REPORTE + '/actividad/excel';
        case 'reporte.alerta.excel': return URL_REPORTE + '/alerta/excel';
        case 'reporte.administracion.administrador.excel': return URL_REPORTE + '/administracion/administrador/excel';
        case 'reporte.administracion.cultivador.excel': return URL_REPORTE + '/administracion/cultivador/excel';
        case 'reporte.administracion.cultivo.excel': return URL_REPORTE + '/administracion/cultivo/excel';
        case 'reporte.administracion.redsocial.excel': return URL_REPORTE + '/administracion/redsocial/excel';
        default: return '';
    }
}

export function route(name, params = []) {
    let route = __getRoute(name);
    if ((route.includes('{') || route.includes('}')) === false) {
        return route;
    }

    let i = 0;
    let newRoute = '';
    for(let j = 0; j < route.length; j++) {
        let c = route.charAt(j);
        if (c !== '{') {
            newRoute = newRoute + c;
        } else {
            newRoute = newRoute + params[i];
            i = i + 1;
            while(route.charAt(j) !== '}') {
                j = j + 1;
            }
        }
    }
    return newRoute;
}

export function routeQuery(name, query) {
    let route = __getRoute(name);
    let queryParams = Object.keys(query)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`)
        .join('&');
    if (queryParams.length > 0) {
        route = route + '?' + queryParams;
    }
    return route;
}
