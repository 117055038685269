<template>
  <div>
    <div class="mb-2"><h3>Lista de cultivadores</h3></div>
    <div class="d-flex align-items-center justify-content-end">
      <div class="dropdown">
        <button class="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
          Exportar
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" :href="routeQuery('reporte.administracion.cultivador.excel', this.$route.query)" target="_blank">Excel</a>
        </div>
      </div>
    </div>
    <mensaje-alerta-component :error="this.error" class="my-2" />
    <tabla-cultivador-listar-component ref="tablaCultivadorListar" class="mt-2" />
  </div>
</template>
<script>
import TablaCultivadorListarComponent from "@/modules/administracion/components/cultivador/TablaListarComponent.vue";
import Service from "@/utils/Service";
import {route, routeQuery} from "@/utils/Route";
import MensajeAlertaComponent from "@/components/utils/MensajeAlerta.vue";
export default {
  name: 'CultivadorListarView',
  components: {
    MensajeAlertaComponent,
    TablaCultivadorListarComponent,
  },
  data() {
    return {
      error: {
        mostrar: false,
        mensaje: null,
      },
      cultivadores: [],
      cultivador: {},
    }
  },
  methods: {
    routeQuery,
    listarCultivador() {
      this.ocultarError();
      Service.get(route('cultivador.listar')).then((data) => {
        this.cultivadores = data.data;
        this.$refs.tablaCultivadorListar.setCultivadores(this.cultivadores);
      }).catch((error) => {
        this.mostrarError(error);
      });
    },

    ocultarError() {
      this.error.mostrar = false;
      this.error.mensaje = '';
    },
    mostrarError(error) {
      this.error.mostrar = true;
      this.error.mensaje = error;
    }
  },
  mounted() {
    this.listarCultivador();
  }
}
</script>