import router from "@/router";

export default class Service {

    static get(ruta) {
        const configuracion = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        };
        return new Promise( (resolve, reject) => {
            fetch(ruta, configuracion)
                .then(async response => {
                    const respuesta = await response.json();
                    if (!response.ok || response.status !== 200) {
                        if (response.status === 400)
                            reject(respuesta.data.toString());
                        if (response.status === 401)
                            router.push({name: 'inicio.sesion'});
                        reject(respuesta.mensaje);
                    }
                    resolve(respuesta);
                })
                .catch(error => {
                    reject(error);
                });
        } );
    }

    static post(ruta, dato) {
        const configuracion = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(dato),
        };
        return new Promise( (resolve, reject) => {
            fetch(ruta, configuracion)
                .then(async response => {
                    const respuesta = await response.json();
                    if (!response.ok || response.status !== 200) {
                        if (response.status === 400)
                            reject(respuesta.data.toString());
                        if (response.status === 401)
                            router.push({name: 'inicio.sesion'});
                        reject(respuesta.mensaje);
                    }
                    resolve(respuesta);
                }).catch( error => {
                    reject(error);
                });
        } );
    }

    static postFormData(ruta, dato) {
        const configuracion = {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: dato,
        };

        return new Promise( (resolve, reject) => {
            fetch(ruta, configuracion)
                .then(async response => {
                    const respuesta = await response.json();
                    if (!response.ok || response.status !== 200) {
                        if (response.status === 400)
                            reject(respuesta.data.toString());
                        if (response.status === 401)
                            router.push({name: 'inicio.sesion'});
                        reject(respuesta.mensaje);
                    }
                    resolve(respuesta);
                }).catch( error => {
                reject(error);
            });
        } );
    }

    static put(ruta, dato) {
        const configuracion = {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(dato)
        };
        return new Promise( (resolve, reject) => {
            fetch(ruta, configuracion)
                .then(async response => {
                    const respuesta = await response.json();
                    if (!response.ok || response.status !== 200) {
                        if (response.status === 400)
                            reject(respuesta.data.toString());
                        if (response.status === 401)
                            router.push({name: 'inicio.sesion'});
                        reject(respuesta.mensaje);
                    }
                    resolve(respuesta);
                }).catch( error => {
                reject(error);
            });
        } );
    }

    static putFormData(ruta, dato) {
        dato.append('_method', 'put');
        const configuracion = {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: dato,
        };

        return new Promise( (resolve, reject) => {
            fetch(ruta, configuracion)
                .then(async response => {
                    const respuesta = await response.json();
                    if (!response.ok || response.status !== 200) {
                        if (response.status === 400)
                            reject(respuesta.data.toString());
                        if (response.status === 401)
                            router.push({name: 'inicio.sesion'});
                        reject(respuesta.mensaje);
                    }
                    resolve(respuesta);
                }).catch( error => {
                reject(error);
            });
        } );
    }

    static patch(ruta, dato) {
        const configuracion = {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(dato)
        };
        return new Promise( (resolve, reject) => {
            fetch(ruta, configuracion)
                .then(async response => {
                    const respuesta = await response.json();
                    if (!response.ok || response.status !== 200) {
                        if (response.status === 400)
                            reject(respuesta.data.toString());
                        if (response.status === 401)
                            router.push({name: 'inicio.sesion'});
                        reject(respuesta.mensaje);
                    }
                    resolve(respuesta);
                }).catch( error => {
                reject(error);
            });
        } );
    }

    static delete(ruta) {
        const configuracion = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        return new Promise( (resolve, reject) => {
            fetch(ruta, configuracion)
                .then(async response => {
                    const respuesta = await response.json();
                    if (!response.ok || response.status !== 200) {
                        if (response.status === 400)
                            reject(respuesta.data.toString());
                        if (response.status === 401)
                            router.push({name: 'inicio.sesion'});
                        reject(respuesta.mensaje);
                    }
                    resolve(respuesta);
                }).catch( error => {
                reject(error);
            });
        } );
    }
}
