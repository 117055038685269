<template>
  <div class="container">
    <h3>Modificar una guía de riego</h3>

    <div class="card mt-2">
      <div class="card-body">
        <form v-on:submit.prevent="modificarGuiaRiego()" id="form">
          <div class="d-flex flex-column">
            <mensaje-alerta-component :error="this.error" class="mt-2" />
            <div class="row">
              <div class="col-12 mt-2">
                <label for="titulo" class="form-label">Título</label>
                <input v-model="this.form.titulo" id="titulo" type="text" class="form-control" maxlength="100" :disabled="!this.form.activo">
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-4 mt-2">
                <label for="tipoProduccion" class="form-label">Tipo producción</label>
                <select v-model="this.form.tipoProduccion" id="tipoProduccion" class="form-select"
                        :disabled="!this.form.activo">
                  <option value="1">Comercial</option>
                  <option value="2">Autoconsumo</option>
                </select>
              </div>
              <div class="col-12 col-md-4 mt-2">
                <label for="departamento" class="form-label">Departamento</label>
                <select v-model="this.form.departamento" id="departamento" class="form-select"
                        :disabled="!this.form.activo">
                  <option value="1">Santa Cruz</option>
                  <option value="2">Beni</option>
                  <option value="3">Pando</option>
                  <option value="4">Cochabamba</option>
                  <option value="5">Chuquisaca</option>
                  <option value="6">Tarija</option>
                  <option value="7">La Paz</option>
                  <option value="8">Oruro</option>
                  <option value="9">Potosi</option>
                </select>
              </div>
              <div class="col-12 col-md-4 mt-2">
                <label for="tipoRiego" class="form-label">Tipo de riego</label>
                <select v-model="this.form.tipoRiego" id="tipoRiego" class="form-select"
                        :disabled="!this.form.activo">
                  <option value="0">Seleccione un tipo de riego</option>
                  <option v-for="riego in this.lista.tipoRiegos" :key="riego.id"
                          :value="riego.id">{{riego.nombre}}
                  </option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-2">
                <label for="urlVideo" class="form-label">Enlace del vídeo en youtube</label>
                <input v-model="this.form.urlVideo" id="urlVideo" type="text" class="form-control"
                       placeholder="https://youtube.com/" maxlength="255" :disabled="!this.form.activo">
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6 mt-2">
                <label for="imagen" class="form-label">Imagen
                  <a v-if="this.form.urlImagen != null" :href="this.form.urlImagen" target="_blank">ver actual</a>
                </label>
                <input @change="onChangeInputImagen($event)" class="form-control" type="file" id="imagen" accept="image/jpeg,image/png"
                       :disabled="!this.form.activo">
              </div>
              <div class="col-12 col-md-6 mt-2">
                <label for="pdf" class="form-label">Pdf
                  <a v-if="this.form.urlPdf != null" :href="this.form.urlPdf" target="_blank">ver actual</a>
                </label>
                <input @change="onChangeInputPdf($event)" class="form-control" type="file" id="pdf" accept="application/pdf"
                       :disabled="!this.form.activo">
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-2">
                <span class="form-label">Descripción</span>
                <Editor name="descripcion" :api-key="tiny.key" :init="tiny.init" v-model="this.form.descripcion" :disabled="!this.form.activo"/>
              </div>
            </div>
          </div>
          <div class="d-flex flex-row-reverse mt-4">
            <button type="submit" class="btn btn-primary waves-effect waves-light">Modificar</button>
            <a @click="router().back()" class="btn btn-soft-secondary waves-effect waves-light me-2">Atrás</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import Service from "@/utils/Service";
import {route} from "@/utils/Route";
import router from "@/router";
import Toast from "@/utils/Toast";
import MensajeAlertaComponent from "@/components/utils/MensajeAlerta.vue";
import Editor from "@tinymce/tinymce-vue";
import {tinyMCE} from "@/utils/config";

export default {
  name: 'GuiaRiegoEditarView',
  components: {Editor, MensajeAlertaComponent},
  data() {
    return {
      tiny: tinyMCE,
      error: {
        mostrar: false,
        mensaje: null,
      },
      form: {
        activo: true,
        titulo: '',
        tipoProduccion: 1,
        urlVideo: '',
        departamento: 1,
        tipoRiego: 0,
        imagen: null,
        pdf: null,
        descripcion: ''
      },
      lista: {
        tipoRiegos: []
      }
    }
  },
  methods: {
    router() {
      return router
    },
    mostrarGuiaRiego() {
      Service.get(route('guia.riego.mostrar', this.getParametroId())).then((data) => {
        this.form.titulo = data.data.titulo;
        this.form.tipoProduccion = data.data.tipoProduccion;
        this.form.urlVideo = data.data.urlVideo;
        this.form.departamento = data.data.departamento;
        this.form.descripcion = data.data.descripcion;
        this.form.urlImagen = data.data.urlImagen;
        this.form.urlPdf = data.data.urlPdf;
        this.form.tipoRiego = data.data.tipoRiego;
      }).catch((error) => {
        console.log(error)
      });
    },
    listarTipoRiego() {
      Service.get(route('recurso.tipo_riego')).then( data => {
        this.lista.tipoRiegos = data.data;
      }).catch( error => {
        console.log(error);
      });
    },
    modificarGuiaRiego() {
      this.ocultarError();
      this.desactivarForm();
      Service.putFormData(route('guia.riego.modificar', this.getParametroId()), this.getParametros()).then( (data)=>{
        Toast.showSuccess(data.mensaje)
        router.push({name: 'guia.riego.listar'});
      }).catch( error => {
        this.mostrarError(error);
      }). finally( () => {
        this.activarForm();
      });
    },

    onChangeInputImagen(event) {
      this.form.imagen = event.target.files[0];
    },
    onChangeInputPdf(event) {
      this.form.pdf = event.target.files[0];
    },
    getParametroId() {
      return [this.$route.params.id];
    },
    getParametros() {
      let formData = new FormData();
      formData.append('titulo', this.form.titulo);
      formData.append('tipoProduccion', this.form.tipoProduccion);
      formData.append('urlVideo', this.form.urlVideo);
      formData.append('departamento', this.form.departamento);
      formData.append('tipoRiego', this.form.tipoRiego);
      formData.append('imagen', this.form.imagen);
      formData.append('pdf', this.form.pdf);
      formData.append('descripcion', this.form.descripcion);

      return formData;
    },
    ocultarError() {
      this.error.mostrar = false;
      this.error.mensaje = '';
    },
    mostrarError(error) {
      this.error.mostrar = true;
      this.error.mensaje = error;
    },
    desactivarForm() {
      this.form.activo = false;
    },
    activarForm() {
      this.form.activo = true;
    },
  },

  mounted() {
    this.mostrarGuiaRiego();
    this.listarTipoRiego();
  },

}
</script>