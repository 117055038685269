<template>
  <div>
    <div id="modalGuiaCultivoEliminar" class="modal fade" tabindex="-1" aria-labelledby="modalLabelGuiaCultivoEliminar" aria-hidden="true" style="display: none;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalTituloGuiaCultivoEliminar">Eliminar cultivo de una guia</h5>
            <button id="btnCerrarModalGuiaCultivoEliminar" ref="btnCerrarModalGuiaCultivoEliminar"
                    type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
          </div>
          <div class="modal-body">
            <p class="text-muted">Desea eliminar el cultivo :
              <span class="fw-bolder fs-15">{{this.cultivo.nombre}}</span>
              de la guia: <span class="fw-bolder fs-15">{{this.guia}}</span>
            </p>
          </div>
          <div class="modal-footer">
            <button :disabled="!this.form.activo" type="button" class="btn btn-light" data-bs-dismiss="modal">Cerrar</button>
            <button :disabled="!this.form.activo" @click="eliminarGuiaCultivo" type="button" class="btn btn-danger">Si, Eliminar</button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Service from "@/utils/Service";
import {route} from "@/utils/Route";
import Toast from "@/utils/Toast";

export default {
  name: 'ModalGuiaCultivoEliminarComponent',
  props: {
    cultivo: {
      id: Number,
      nombre: String,
      tipoCultivoDato: {
        nombre: String
      }
    },
    guia: Number,
  },
  data() {
    return {
      form: {
        activo: true
      },
    }
  },
  methods: {
    eliminarGuiaCultivo() {
      this.desactivarForm();
      Service.delete(route('guia.cultivo.eliminar', [this.guia, this.cultivo.id]))
          .then( (data) => {
        Toast.showSuccess(data.mensaje)
        this.$emit('onGuiaCultivoEliminado');
        this.$refs.btnCerrarModalGuiaCultivoEliminar.click();
      }).catch((error) => {
        console.log(error)
      }).finally(()=>{
        this.activarForm();
      });
    },

    desactivarForm() {
      this.form.activo = false;
    },
    activarForm() {
      this.form.activo = true;
    },
  }

}
</script>