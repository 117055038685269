<template>
  <div class="container">
    <div class="d-flex flex-column">
      <mensaje-alerta-component :error="this.error" class="mb-2" />
      <div class="mb-2">
        <a @click="router().back()" class="btn btn-soft-secondary waves-effect waves-light me-2">Atrás</a>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="d-flex position-relative">
            <div class="row">
              <div class="col-12 col-md-4 col-xl-3">
                <div class="d-flex justify-content-center">
                  <img :src="this.administrador.urlFoto" class="rounded img-fluid w-50 w-md" alt="punto-verde"/>
                </div>
              </div>
              <div class="col-12 col-md-8 col-xl-9">
                <div class="d-flex flex-column">
                  <div class="d-flex mt-3 mt-md-0">
                    <span class="fw-medium">Nombre: </span>
                    <span class="ms-2 text-muted">{{this.administrador.nombre + ' ' + this.administrador.apellidos}}</span>
                  </div>
                  <div class="d-flex">
                    <span class="fw-medium">Carnet de identidad: </span>
                    <span class="ms-2 text-muted">{{this.administrador.carnetIdentidad}}</span>
                  </div>
                  <div class="d-flex">
                    <span class="fw-medium">Fecha de nacimiento: </span>
                    <span class="ms-2 text-muted">{{this.administrador.fechaNacimiento}}</span>
                  </div>
                  <div class="d-flex">
                    <span class="fw-medium">Correo: </span>
                    <span class="ms-2 text-muted">{{this.administrador.correo}}</span>
                  </div>
                  <div class="d-flex">
                    <span class="fw-medium">Teléfono: </span>
                    <span class="ms-2 text-muted">{{this.administrador.telefono}}</span>
                  </div>
                  <div class="d-flex">
                    <span class="fw-medium me-2">Estado: </span>
                    <span v-if="this.administrador.activo" class="badge text-bg-success">habilitado</span>
                    <span v-else class="badge text-bg-danger">inhabilitado</span>
                  </div>
                </div>
                <div class="d-flex">
                    <span class="text-muted">Registrado el {{this.administrador.fechaHoraRegistrado}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-6">
          <usuario-permiso-crear-component :persona="{id: this.$route.params.id}" />
        </div>
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-12">
              <usuario-cambiar-contrasena-component :persona="{id: this.$route.params.id}" />
            </div>
            <div class="col-12">
              <usuario-cambiar-estado-component :persona="{id: this.$route.params.id}" :activo="this.administrador.activo"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Service from "@/utils/Service";
import {route} from "@/utils/Route";
import UsuarioPermisoCrearComponent from "@/modules/administracion/components/administrador/usuario/PermisoCrearComponent.vue";
import UsuarioCambiarContrasenaComponent
  from "@/modules/administracion/components/administrador/usuario/CambiarContrasenaComponent.vue";
import UsuarioCambiarEstadoComponent
  from "@/modules/administracion/components/administrador/usuario/CambiarEstadoComponent.vue";
import router from "@/router";
import MensajeAlertaComponent from "@/components/utils/MensajeAlerta.vue";

export default {
  name: 'AdministradorMostrarView',
  components: {
    MensajeAlertaComponent,
    UsuarioCambiarEstadoComponent, UsuarioCambiarContrasenaComponent, UsuarioPermisoCrearComponent},
  data() {
    return {
      error: {
        mostrar: false,
        mensaje: null,
      },
      administrador: {
        nombre: '',
        apellidos: '',
        fechaNacimiento: '',
        urlFoto: '',
        correo: '',
        carnetIdentidad: '',
        telefono: '',
        activo: true,
        fechaHoraRegistrado: '',
        usuarioDato: {
          personaDato: {
            nombre: '',
            apellidos: ''
          }
        }
      }
    }
  },
  methods: {
    router() {
      return router
    },
    mostrarAdministrador() {
      this.ocultarError();
      Service.get(route('administrador.mostrar', this.getParametrosMostrar())).then((data) => {
        this.administrador = data.data;
      }).catch((error) => {
        this.mostrarError(error);
      });
    },

    getParametrosMostrar() {
      return [this.$route.params.id]
    },

    ocultarError() {
      this.error.mostrar = false;
      this.error.mensaje = '';
    },
    mostrarError(error) {
      this.error.mostrar = true;
      this.error.mensaje = error;
    },
  },
  mounted() {
    this.mostrarAdministrador();
  }
}
</script>