<template>
  <div class="home">
    <div class="row">
      <div class="col-xl-12">
        <div class="card crm-widget">
          <div class="card-body p-0">
            <div class="row row-cols-md-3 row-cols-1">
              <div class="col col-lg border-end">
                <div class="py-4 px-3">
                  <h5 class="fs-24">Huertos
                  </h5>
                  <div class="d-flex align-items-center">
                    <div class="flex-shrink-0">
                      <img class="w-50" src="/assets/images/home/huertos.png" alt="huertos">
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <h2 class="mb-0"><span class="counter-value fs-36" :data-target="this.huertos">{{this.huertos}}</span></h2>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col-lg border-end">
                <div class="mt-3 mt-md-0 py-4 px-3">
                  <h5 class="fs-24">Total Guías
                  </h5>
                  <div class="d-flex align-items-center">
                    <div class="flex-shrink-0">
                      <img class="w-50" src="/assets/images/home/guias.png" alt="guias">
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <h2 class="mb-0"><span class="counter-value fs-36" :data-target="this.guias">{{this.guias}}</span></h2>
                    </div>
                  </div>
                </div>
              </div><!-- end col -->
              <div class="col col-lg border-end">
                <div class="mt-3 mt-md-0 py-4 px-3">
                  <h5 class="fs-24">Total Guías de Producción
                  </h5>
                  <div class="d-flex align-items-center">
                    <div class="flex-shrink-0">
                      <img class="w-50" src="/assets/images/home/guias.png" alt="guias">
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <h2 class="mb-0"><span class="counter-value fs-36" :data-target="this.guiasProduccion">{{this.guiasProduccion}}</span></h2>
                    </div>
                  </div>
                </div>
              </div><!-- end col -->
              <div class="col col-lg border-end">
                <div class="mt-3 mt-lg-0 py-4 px-3">
                  <h5 class="fs-24">Total de Guías de Riego
                  </h5>
                  <div class="d-flex align-items-center">
                    <div class="flex-shrink-0">
                      <img class="w-50" src="/assets/images/home/guiasRiego.png" alt="guias de riego">
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <h2 class="mb-0"><span class="counter-value fs-36" :data-target="this.guiasRiego">{{this.guiasRiego}}</span></h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-12">
        <div class="card crm-widget">
          <div class="card-body p-0">
            <div class="row row-cols-md-3 row-cols-1">
              <div class="col col-lg border-end">
                <div class="mt-3 mt-lg-0 py-4 px-3">
                  <h5 class="fs-24">Total de Guías de Plaga
                  </h5>
                  <div class="d-flex align-items-center">
                    <div class="flex-shrink-0">
                      <img class="w-50" src="/assets/images/home/guiasPlaga.png" alt="guias de plaga">
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <h2 class="mb-0"><span class="counter-value" :data-target="this.guiasPlaga">{{this.guiasPlaga}}</span></h2>
                    </div>
                  </div>
                </div>
              </div><!-- end col -->
              <div class="col col-lg">
                <div class="mt-3 mt-lg-0 py-4 px-3">
                  <h5 class="fs-24">Total de Guías de Tratamiento
                  </h5>
                  <div class="d-flex align-items-center">
                    <div class="flex-shrink-0">
                      <img class="w-50" src="/assets/images/home/guiasTratamiento.png" alt="guias de tratamiento">
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <h2 class="mb-0"><span class="counter-value fs-36" :data-target="this.guiasTratamiento">{{this.guiasTratamiento}}</span></h2>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col-lg">
                <div class="mt-3 mt-lg-0 py-4 px-3">
                  <h5 class="fs-24">Total de Guías de Planificación
                  </h5>
                  <div class="d-flex align-items-center">
                    <div class="flex-shrink-0">
                      <img class="w-50" src="/assets/images/home/guiasPlanificacion.png" alt="guias de planificacion">
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <h2 class="mb-0"><span class="counter-value fs-36" :data-target="this.guiasPlanificacion">{{this.guiasPlanificacion}}</span></h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Service from "@/utils/Service";
import {route} from "@/utils/Route";

export default {
  name: 'HomeView',
  data() {
    return {
      huertos: 0,
      guias: 0,
      guiasProduccion: 0,
      guiasRiego: 0,
      guiasPlaga: 0,
      guiasTratamiento: 0,
      guiasPlanificacion: 0
    }
  },
  methods: {
    mostrarHome() {
      Service.get(route('home')).then( data => {
        this.huertos = data.data.huertos;
        this.guias = data.data.guias;
        this.guiasProduccion = data.data.guiasProduccion;
        this.guiasRiego = data.data.guiasRiego;
        this.guiasPlaga = data.data.guiasPlaga;
        this.guiasTratamiento = data.data.guiasTratamiento;
        this.guiasPlanificacion = data.data.guiasPlanificacion;
      }).catch( error => {
        console.log(error);
      });
    },
  },

  mounted() {
    this.mostrarHome();
  }
}
</script>
