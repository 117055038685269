<template>
  <div class="container">
    <div class="d-flex flex-column">
      <mensaje-alerta-component :error="this.error" class="mb-2" />
      <div class="mb-2">
        <a @click="router().back()" class="btn btn-soft-secondary waves-effect waves-light me-2">Atrás</a>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="d-flex position-relative">
            <div class="row">
              <div class="col-12 col-md-4 col-xl-3">
                <img :src="this.huerto.urlImagen" class="rounded img-fluid" alt="huerto punto-verde"/>
              </div>
              <div class="col-12 col-md-8 col-xl-9">
                <div class="d-flex flex-column">
                  <h6 class="card-title mb-0">{{this.huerto.nombre}}</h6>
                  <div class="text-muted">{{this.huerto.cultivadorDato.apellidos + ' ' + this.huerto.cultivadorDato.nombre}}</div>
                  <div class="d-flex mt-3">
                    <span class="fw-medium">Departamento: </span>
                    <span class="ms-2 text-muted">{{this.huerto.departamentoDato.nombre}}</span>
                  </div>
                  <div class="d-flex">
                    <span class="fw-medium">Tipo de Producción: </span>
                    <span v-if="this.huerto.tipoProduccion === 1" class="ms-2 text-muted">Comercial</span>
                    <span v-if="this.huerto.tipoProduccion === 2" class="ms-2 text-muted">Autoconsumo</span>
                  </div>
                  <div class="d-flex">
                    <span class="fw-medium">Ubicación: </span>
                    <span class="ms-2 text-muted">
                    <a v-if="this.huerto.latitud !== 0 " :href="`https://www.google.com/maps?q=${this.huerto.latitud},${this.huerto.longitud}`" target="_blank">abrir</a>
                  </span>
                  </div>
                  <div class="d-flex">
                  <span class="text-muted">Registrado por {{this.huerto.usuarioDato.personaDato.apellidos + ' ' + this.huerto.usuarioDato.personaDato.nombre}}
                    el {{this.huerto.fechaHoraRegistrado}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <h6 class="card-title mb-0">Descripción</h6>
          <div>{{this.huerto.descripcion}}</div>
        </div>
      </div>

      <div>
        <div class="d-flex flex-row-reverse">
          <a class="btn btn-success waves-effect waves-light" href="javascript:void(0)"
             data-bs-toggle="modal" data-bs-target="#modalHuertoCultivoAdicionar">Agregar Cultivo
          </a>
        </div>
      </div>

      <div class="mt-2">
        <ul class="list-group">
          <li v-for="cultivo in this.cultivos" :key="cultivo.id" class="list-group-item">
            <div class="d-flex flex-column ms-2">
              <span class="fw-medium">{{cultivo.nombre}}</span>
              <div class="d-flex justify-content-between">
                <span class="text-muted">{{cultivo.tipoCultivoDato.nombre}}</span>
                <a @click="onHuertoCultivoEliminar(cultivo)" class="badge bg-danger" href="javascript:void(0)"
                   data-bs-toggle="modal" data-bs-target="#modalHuertoCultivoEliminar">
                  <span>Eliminar</span>
                </a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <modal-huerto-cultivo-adicionar-component :huerto="this.huerto.id" @onHuertoCultivoAgregado="onHuertoCultivoAgregado" />
    <modal-huerto-cultivo-eliminar-component :huerto="this.huerto.id" :cultivo="this.cultivo" @onHuertoCultivoEliminado="onHuertoCultivoEliminado" />
  </div>
</template>
<script>
import Service from "@/utils/Service";
import {route} from "@/utils/Route";
import ModalHuertoCultivoAdicionarComponent
  from "@/modules/huerto/components/huerto/cultivo/ModalAdicionarComponent.vue";
import ModalHuertoCultivoEliminarComponent from "@/modules/huerto/components/huerto/cultivo/ModalEliminarComponent.vue";
import MensajeAlertaComponent from "@/components/utils/MensajeAlerta.vue";
import router from "@/router";

export default {
  name: 'HuertoMostrarView',
  components: {
    MensajeAlertaComponent,
    ModalHuertoCultivoEliminarComponent,
    ModalHuertoCultivoAdicionarComponent,
  },
  data() {
    return {
      error: {
        mostrar: false,
        mensaje: null,
      },
      huerto: {
        nombre: '',
        descripcion: '',
        departamentoDato: {
          nombre: ''
        },
        cultivadorDato: {
          apellidos: '',
          nombre: ''
        },
        usuarioDato: {
          personaDato: {
            apellidos: '',
            nombre: ''
          }
        },
        latitud: 0,
        longitud: 0
      },
      cultivos: [],
      cultivo: {}
    }
  },
  mounted() {
    this.mostrarHuerto();
    this.listarHuertoCultivo();
  },
  methods: {
    router() {
      return router
    },
    mostrarHuerto() {
      this.ocultarError();
      Service.get(route('huerto.mostrar', this.getParametros())).then((data) => {
        this.huerto = data.data;
      }).catch((error) => {
        this.mostrarError(error);
      });
    },
    listarHuertoCultivo() {
      Service.get(route('huerto.cultivo.listar', this.getParametros())).then((data) => {
        this.cultivos = data.data;
      }).catch((error) => {
        console.log(error)
      });
    },
    onHuertoCultivoAgregado() {
      this.listarHuertoCultivo();
    },
    onHuertoCultivoEliminado() {
      this.listarHuertoCultivo();
    },
    onHuertoCultivoEliminar(cultivo) {
      this.cultivo = cultivo;
    },

    getParametros() {
      return [this.$route.params.id];
    },

    ocultarError() {
      this.error.mostrar = false;
      this.error.mensaje = '';
    },
    mostrarError(error) {
      this.error.mostrar = true;
      this.error.mensaje = error;
    },
  },
}
</script>