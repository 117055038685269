import { createApp } from 'vue'
import App from './App.vue'
//import './registerServiceWorker'
import router from './router'
import store from './store'
import GuiaModule from "./modules/guia";
import HuertoModule from "./modules/huerto";
import ActividadModule from "./modules/actividad";
import AdministracionModule from "./modules/administracion";
import {registerModules} from "@/registerModules";

store.dispatch('getUsuarioAutenticado');

registerModules({
    administracion: AdministracionModule,
    guia: GuiaModule,
    huerto: HuertoModule,
    actividad: ActividadModule
});

createApp(App).use(store).use(router).mount('#app')
