<template>
  <div class="container">
    <h3 class="mb-2">Modificar una red social</h3>
    <div class="card">
      <div class="card-body">
        <form v-on:submit.prevent="modificarRedSocial()" id="form">
          <div class="d-flex flex-column">
            <mensaje-alerta-component :error="this.error" class="mb-2" />
            <div class="row mb-2">
              <div class="col-12 col-md-6">
                <label for="tipoRedSocial" class="form-label">Tipo de red social <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <select v-model="this.form.tipoRedSocial" id="tipoRedSocial"
                        class="form-select" :disabled="!this.form.activo">
                  <option value="0">Seleccione un tipo de red social</option>
                  <option v-for="tipoRedSocial in this.lista.tipoRedSociales" :key="tipoRedSocial.id"
                          :value="tipoRedSocial.id">{{tipoRedSocial.nombre}}
                  </option>
                </select>
              </div>
              <div class="col-12 col-md-6 mt-2 mt-md-0">
                <label for="departamento" class="form-label">Departamento <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <select v-model="this.form.departamento" id="departamento" class="form-select"
                        :disabled="!this.form.activo" required>
                  <option value="1">Santa Cruz</option>
                  <option value="2">Beni</option>
                  <option value="3">Pando</option>
                  <option value="4">Cochabamba</option>
                  <option value="5">Chuquisaca</option>
                  <option value="6">Tarija</option>
                  <option value="7">La Paz</option>
                  <option value="8">Oruro</option>
                  <option value="9">Potosi</option>
                </select>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-12 col-xl-6">
                <label for="nombre" class="form-label">Nombre <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <input v-model="this.form.nombre" id="nombre" type="text" class="form-control" maxlength="50" :disabled="!this.form.activo" required>
              </div>
              <div class="col-12 col-xl-6 mt-2 mt-md-0">
                <label for="url" class="form-label">Enlace <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <input v-model="this.form.url" id="url" type="text" class="form-control" maxlength="255" :disabled="!this.form.activo" required>
              </div>
            </div>
          </div>
          <div class="d-flex flex-row-reverse">
            <button :disabled="!this.form.activo" type="submit" class="btn btn-primary waves-effect waves-light">Modificar</button>
            <a @click="router().back()" class="btn btn-soft-secondary waves-effect waves-light me-2">Atrás</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import Service from "@/utils/Service";
import {route} from "@/utils/Route";
import Toast from "@/utils/Toast";
import router from "@/router";
import MensajeAlertaComponent from "@/components/utils/MensajeAlerta.vue";

export default {
  name: 'RedSocialCrearView',
  components: {MensajeAlertaComponent},
  data() {
    return {
      error: {
        mostrar: false,
        mensaje: null,
      },
      form: {
        activo: true,
        nombre: '',
        url: '',
        tipoRedSocial: 0,
        departamento: 1
      },
      lista: {
        tipoRedSociales: []
      }
    }
  },
  methods: {
    router() {
      return router
    },
    listarTipoRedSocial() {
      Service.get(route('recurso.tipo_red_social')).then( data => {
        this.lista.tipoRedSociales = data.data;
      }).catch( error => {
        console.log(error);
      });
    },
    mostrarRedSocial() {
      this.ocultarError();
      Service.get(route('red_social.mostrar', this.getParametrosMostrar())).then( data => {
        this.form.nombre = data.data.nombre;
        this.form.url = data.data.url;
        this.form.tipoRedSocial = data.data.tipoRedSocial;
        this.form.departamento = data.data.departamento;
      }).catch( error => {
        this.mostrarError(error);
      });
    },
    modificarRedSocial() {
      this.desactivarForm();
      this.ocultarError();
      Service.put(route('red_social.modificar', this.getParametrosMostrar()), this.getParametros()).then( (data)=>{
        Toast.showSuccess(data.mensaje)
        router.push({name: 'red_social.listar'});
      }).catch( error => {
        this.mostrarError(error);
      }). finally( () => {
        this.activarForm();
      });
    },
    getParametrosMostrar() {
      return [this.$route.params.id];
    },
    getParametros() {
      return {
        nombre: this.form.nombre,
        url: this.form.url,
        tipoRedSocial: this.form.tipoRedSocial,
        departamento: this.form.departamento
      }
    },
    desactivarForm() {
      this.form.activo = false;
    },
    activarForm() {
      this.form.activo = true;
    },

    ocultarError() {
      this.error.mostrar = false;
      this.error.mensaje = '';
    },
    mostrarError(error) {
      this.error.mostrar = true;
      this.error.mensaje = error;
    },
  },
  mounted() {
    this.mostrarRedSocial();
    this.listarTipoRedSocial();
  }
}
</script>