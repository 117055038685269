<template>
  <div class="d-flex">
    <div class="flex-fill">
      <label for="tipoTratamiento" class="form-label">Tipo de Tratamiento</label>
      <select v-model="this.form.tipoTratamiento" @change="onChangeTipoTratamiento($event)" id="tipoTratamiento"
              class="form-select">
        <option value="0">Todos</option>
        <option v-for="tipoTratamiento in this.lista.tipoTratamientos" :key="tipoTratamiento.id"
                :value="tipoTratamiento.id">{{tipoTratamiento.nombre}}
        </option>
      </select>
    </div>
  </div>
</template>
<script>
import Service from "@/utils/Service";
import {route} from "@/utils/Route";
import router from "@/router";

export default {
  name: 'SelectTipoTratamientoComponent',
  data() {
    return {
      lista: {
        tipoTratamientos: []
      },
      form: {
        tipoTratamiento: 0
      }
    }
  },
  methods: {
    listarTipoTratamiento() {
      Service.get(route('recurso.tipo_tratamiento')).then( data => {
        this.lista.tipoTratamientos = data.data;
      }).catch( error => {
        console.log(error);
      });
    },
    onChangeTipoTratamiento(event) {
      if (event.target.value > 0) {
        let params = {...this.$route.query, tipoTratamiento: this.form.tipoTratamiento};
        router.push({ path: this.$route.path, query: params });
      }
    },
  },
  mounted() {
    this.listarTipoTratamiento();
    if (this.$route.query.tipoTratamiento !== undefined)
      this.form.tipoTratamiento = this.$route.query.tipoTratamiento;
  }
}
</script>