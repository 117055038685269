export default class TablaFila {

    /**
     *
     * @param filas {*[]}
     * @param pagina : int
     * @param cantidad : int
     * @returns {*[]}
     */
    static getFilasPagina(filas, pagina, cantidad) {
        let nuevasFilas = [];
        let i = (pagina - 1) * cantidad;
        const limite = i + cantidad;
        while(i < limite && i < filas.length) {
            nuevasFilas.push(filas[i]);
            i++;
        }
        return nuevasFilas;
    }

    /**
     *
     * @param filas {*[]}
     * @param busqueda : String
     * @param columnas {*[]}
     * @returns {*[]}
     */
    static getBusquedaFila(filas, busqueda, columnas) {
        let busquedaFila = [];
        if(busqueda.length > 0) {
            for(var i = 0; i < filas.length; i++) {
                var aux = filas[i];
                for(var j = 0; j < columnas.length; j++) {
                    if(aux[columnas[j]].includes(busqueda)){
                        busquedaFila.push(aux);
                    }
                }
            }
        } else {
            busquedaFila = filas;
        }
        return busquedaFila;
    }

    /**
     *
     * @param filas {*[]}
     * @param busqueda : String
     * @param columnas {*[]}
     * @returns {*[]}
     */
    static getBusquedaFilaV2(filas, busqueda, columnas) {
        let busquedaFila = [];
        if(busqueda.length > 0) {
            for(let i = 0; i < filas.length; i++) {
                var aux = filas[i];
                for(let j = 0; j < columnas.length; j++) {
                    let col = columnas[j];
                    if (col.includes('.')) {
                        let vCol = col.split('.');
                        let dato = aux[vCol[0]];
                        for (let f = 1; f < vCol.length; f++) {
                            dato = dato[vCol[f]];
                        }
                        if (dato.includes(busqueda)) {
                            busquedaFila.push(aux);
                        }
                    } else {
                        if(aux[columnas[j]].includes(busqueda)){
                            busquedaFila.push(aux);
                        }
                    }
                }
            }
        } else {
            busquedaFila = filas;
        }
        return busquedaFila;
    }
}
