<template>
  <div>
    <div class="mb-2"><h3>Lista de administradores</h3></div>
    <div class="d-flex justify-content-end mb-2">
      <div class="d-flex align-items-center">
        <div class="dropdown">
          <button class="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
            Exportar
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" :href="routeQuery('reporte.administracion.administrador.excel', this.$route.query)" target="_blank">Excel</a>
          </div>
        </div>
        <router-link :to="{name: 'administrador.crear'}" class="btn btn-success waves-effect waves-light ms-2">
          Agregar Nuevo
        </router-link>
      </div>
    </div>
    <mensaje-alerta-component :error="this.error" class="my-2" />
    <tabla-administrador-listar-component ref="tablaAdministradorListar" @onAdministradorEliminar="onAdministradorEliminar"/>
    <modal-administrador-eliminar-component :administrador="this.administrador" @onAdministradorEliminado="onAdministradorEliminado"/>
  </div>
</template>
<script>
import TablaAdministradorListarComponent from "@/modules/administracion/components/administrador/TablaListarComponent.vue";
import ModalAdministradorEliminarComponent from "@/modules/administracion/components/administrador/ModalEliminarComponent.vue";
import Service from "@/utils/Service";
import {route, routeQuery} from "@/utils/Route";
import MensajeAlertaComponent from "@/components/utils/MensajeAlerta.vue";
export default {
  name: 'AdministradorListarView',
  components: {
    MensajeAlertaComponent,
    TablaAdministradorListarComponent, ModalAdministradorEliminarComponent,
  },
  data() {
    return {
      error: {
        mostrar: false,
        mensaje: null,
      },
      administradores: [],
      administrador: {},
    }
  },
  methods: {
    routeQuery,
    listarAdministrador() {
      this.ocultarError();
      Service.get(route('administrador.listar')).then((data) => {
        this.administradores = data.data;
        this.$refs.tablaAdministradorListar.setAdministradores(this.administradores);
      }).catch((error) => {
        this.mostrarError(error);
      });
    },

    onAdministradorEliminar(administrador) {
      this.administrador = administrador;
    },

    onAdministradorEliminado() {
      this.listarAdministrador();
    },

    ocultarError() {
      this.error.mostrar = false;
      this.error.mensaje = '';
    },
    mostrarError(error) {
      this.error.mostrar = true;
      this.error.mensaje = error;
    }
  },
  mounted() {
    this.listarAdministrador();
  }
}
</script>