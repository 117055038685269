<template>
  <div>
    <div class="mb-2"><h3>Listar Guía de Plaga</h3></div>
    <div class="d-flex flex-column flex-xxl-row">
      <div class="d-flex flex-column flex-sm-row">
        <select-departamento-component class="m-md-1"/>
        <select-tipo-produccion-component class="m-md-1"/>
      </div>
      <div class="d-flex flex-column flex-md-row justify-content-between flex-fill">
        <div class="d-flex flex-column flex-sm-row">
          <select-tipo-cultivo-component class="m-md-1 mb-2 mb-sm-0" />
          <select-tipo-plaga-component class="m-md-1" />
        </div>
        <div class="d-flex align-items-center justify-content-end mt-2">
          <div class="dropdown">
            <button class="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
              Exportar
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" :href="routeQuery('reporte.guia.plaga.excel', this.$route.query)" target="_blank">Excel</a>
            </div>
          </div>
          <router-link :to="{name: 'guia.plaga.crear'}" class="btn btn-success waves-effect waves-light ms-2">
            Agregar Nuevo
          </router-link>
        </div>
      </div>
    </div>
    <mensaje-alerta-component :error="this.error" class="my-2" />
    <tabla-guia-plaga-listar ref="tablaGuiaPlagaListar"  @onGuiaPlagaEliminar="onGuiaPlagaEliminar" class="mt-2 mt-xxl-0"/>
    <modal-guia-plaga-eliminar :id="this.guiaPlaga.id" :titulo="this.guiaPlaga.titulo" @onGuiaPlagaEliminado="onGuiaPlagaEliminado"/>
  </div>
</template>
<script>
import TablaGuiaPlagaListar from '@/modules/guia/components/plaga/TablaListarComponent.vue';
import SelectDepartamentoComponent from '@/modules/guia/components/utils/SelectDepartamentoComponent.vue';
import SelectTipoProduccionComponent from "@/modules/guia/components/utils/SelectTipoProduccionComponent.vue";
import SelectTipoCultivoComponent from "@/modules/guia/components/utils/SelectTipoCultivoComponent.vue";
import SelectTipoPlagaComponent from "@/modules/guia/components/utils/SelectTipoPlagaComponent.vue";
import Service from "@/utils/Service";
import {route, routeQuery} from "@/utils/Route";
import ModalGuiaPlagaEliminar from "@/modules/guia/components/plaga/ModalEliminarComponent.vue";
import MensajeAlertaComponent from "@/components/utils/MensajeAlerta.vue";

export default {
  name: 'GuiaRiegoListarView',
  components: {
    MensajeAlertaComponent,
    ModalGuiaPlagaEliminar,
    SelectTipoCultivoComponent, SelectTipoProduccionComponent, SelectDepartamentoComponent,
    SelectTipoPlagaComponent, TablaGuiaPlagaListar
  },
  data() {
    return {
      error: {
        mostrar: false,
        mensaje: null,
      },
      guias: [],
      guiaPlaga: {
        id: 0,
        titulo: ''
      }
    }
  },
  methods: {
    routeQuery,
    listarGuiaPlaga() {
      Service.get(this.getRutaParams()).then((data) => {
        this.guias = data.data;
        this.$refs.tablaGuiaPlagaListar.setGuias(this.guias);
      }).catch((error) => {
        console.log(error)
      });
    },

    getRutaParams() {
      let ruta = route('guia.plaga.listar');
      let departamento = 0;
      let tipoProduccion = 0;
      let tipoCultivo = 0;
      let cultivo = 0;
      let tipoPlaga = 0;
      if (this.$route.query.departamento !== undefined) {
        departamento = this.$route.query.departamento;
      }
      if (this.$route.query.tipoProduccion !== undefined) {
        tipoProduccion = this.$route.query.tipoProduccion;
      }
      if (this.$route.query.tipoCultivo !== undefined) {
        tipoCultivo = this.$route.query.tipoCultivo;
      }
      if (this.$route.query.cultivo !== undefined) {
        cultivo = this.$route.query.cultivo;
      }
      if (this.$route.query.tipoPlaga !== undefined) {
        tipoPlaga = this.$route.query.tipoPlaga;
      }
      return ruta + '?tipoProduccion=' + tipoProduccion +'&departamento=' + departamento + '&tipoCultivo='
          + tipoCultivo + '&cultivo=' + cultivo + '&tipoPlaga=' + tipoPlaga;
    },

    onGuiaPlagaEliminar(id, titulo) {
      this.guiaPlaga.id = id;
      this.guiaPlaga.titulo = titulo;
    },

    onGuiaPlagaEliminado() {
      this.listarGuiaPlaga();
    },

    ocultarError() {
      this.error.mostrar = false;
      this.error.mensaje = '';
    },
    mostrarError(error) {
      this.error.mostrar = true;
      this.error.mensaje = error;
    }
  },

  mounted() {
    this.listarGuiaPlaga();
  },
  updated() {
    this.listarGuiaPlaga();
  }
}
</script>