import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"

export default class Toast {

    constructor() {
        console.log("constructor");
    }

    static showError(message) {
        Toastify({
            text: message,
            duration: 3000,
            newWindow: true,
            close: true,
            gravity: "top", // `top` or `bottom`
            position: "center", // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
                background: "#e8214f",
            },
            onClick: function(){} // Callback after click
        }).showToast();
    }

    static showSuccess(message) {
        Toastify({
            text: message,
            duration: 3000,
            newWindow: true,
            close: true,
            gravity: "top", // `top` or `bottom`
            position: "center", // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
                background: "#00b09b",
            },
            onClick: function(){} // Callback after click
        }).showToast();
    }
}
