<template>
  <div class="container">
    <h3>Modificar una guía de planificación</h3>

    <div class="card mt-2">
      <div class="card-body">
        <form v-on:submit.prevent="modificarGuiaPlanificacion()" id="form">
          <div class="d-flex flex-column">
            <mensaje-alerta-component :error="this.error" class="mt-2" />
            <div class="row">
              <div class="col-12 mt-2">
                <label for="titulo" class="form-label">Título <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <input v-model="this.form.titulo" id="titulo" type="text" class="form-control"
                       maxlength="100" :disabled="!this.form.activo" required>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6 mt-2">
                <label for="tipoProduccion" class="form-label">Tipo producción <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <select v-model="this.form.tipoProduccion" id="tipoProduccion" class="form-select"
                        :disabled="!this.form.activo" required>
                  <option value="1">Comercial</option>
                  <option value="2">Autoconsumo</option>
                </select>
              </div>
              <div class="col-12 col-md-6 mt-2">
                <label for="departamento" class="form-label">Departamento <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <select v-model="this.form.departamento" id="departamento" class="form-select"
                        :disabled="!this.form.activo" required>
                  <option value="1">Santa Cruz</option>
                  <option value="2">Beni</option>
                  <option value="3">Pando</option>
                  <option value="4">Cochabamba</option>
                  <option value="5">Chuquisaca</option>
                  <option value="6">Tarija</option>
                  <option value="7">La Paz</option>
                  <option value="8">Oruro</option>
                  <option value="9">Potosi</option>
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6 mt-2">
                <label for="tipoSiembra" class="form-label">Tipo de siembra <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <select v-model="this.form.tipoSiembra" id="tipoSiembra" class="form-select"
                        :disabled="!this.form.activo" required>
                  <option value="0">Seleccione un tipo de siembra</option>
                  <option v-for="tipoSiembra in this.lista.tipoSiembras" :key="tipoSiembra.id"
                          :value="tipoSiembra.id">{{tipoSiembra.nombre}}
                  </option>
                </select>
              </div>
              <div class="col-12 col-md-6 mt-2">
                <label for="zona" class="form-label">Zona <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <select v-model="this.form.zona" id="zona" class="form-select"
                        :disabled="!this.form.activo" required>
                  <option value="0">Seleccione una zona</option>
                  <option v-for="zona in this.lista.zonas" :key="zona.id"
                          :value="zona.id">{{zona.nombre}}
                  </option>
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6 col-lg-3 mt-2">
                <label for="fechaInicioSiembra" class="form-label">Inicio de siembra <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <input v-model="this.form.fechaInicioSiembra" id="fechaInicioSiembra" class="form-control"
                       type="date" :disabled="!this.form.activo" required>
              </div>
              <div class="col-12 col-md-6 col-lg-3 mt-2">
                <label for="fechaFinalizacionSiembra" class="form-label">Finalización de siembra <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <input v-model="this.form.fechaFinalizacionSiembra" id="fechaFinalizacionSiembra"
                       class="form-control" type="date" :disabled="!this.form.activo" required>
              </div>
              <div class="col-12 col-md-6 col-lg-3 mt-2">
                <label for="fechaInicioCosecha" class="form-label">Inicio de cosecha <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <input v-model="this.form.fechaInicioCosecha" id="fechaInicioCosecha" class="form-control"
                       type="date" :disabled="!this.form.activo" required>
              </div>
              <div class="col-12 col-md-6 col-lg-3 mt-2">
                <label for="fechaFinalizacionCosecha" class="form-label">Finalización de cosecha <span class="fs-12 text-danger">(Obligatorio)</span></label>
                <input v-model="this.form.fechaFinalizacionCosecha" id="fechaFinalizacionCosecha" class="form-control"
                       type="date" :disabled="!this.form.activo" required>
              </div>
            </div>

            <div class="row">
              <div class="col-12 mt-2">
                <label for="urlVideo" class="form-label">Enlace del vídeo en youtube</label>
                <input v-model="this.form.urlVideo" id="urlVideo" type="text" class="form-control"
                       placeholder="https://youtube.com/" maxlength="255" :disabled="!this.form.activo">
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6 mt-2">
                <label for="imagen" class="form-label">Imagen
                  <a v-if="this.form.urlImagen != null" :href="this.form.urlImagen" target="_blank">ver actual</a>
                </label>
                <input @change="onChangeInputImagen($event)" class="form-control" type="file" id="imagen" accept="image/jpeg,image/png"
                       :disabled="!this.form.activo">
              </div>
              <div class="col-12 col-md-6 mt-2">
                <label for="pdf" class="form-label">Pdf
                  <a v-if="this.form.urlPdf != null" :href="this.form.urlPdf" target="_blank">ver actual</a>
                </label>
                <input @change="onChangeInputPdf($event)" class="form-control" type="file" id="pdf" accept="application/pdf"
                       :disabled="!this.form.activo">
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-2">
                <span class="form-label">Descripción</span>
                <Editor name="descripcion" :api-key="tiny.key" :init="tiny.init" v-model="this.form.descripcion" :disabled="!this.form.activo"/>
              </div>
            </div>
          </div>
          <div class="d-flex flex-row-reverse mt-4">
            <button type="submit" class="btn btn-primary waves-effect waves-light">Modificar</button>
            <a @click="router().back()" class="btn btn-soft-secondary waves-effect waves-light me-2">Atrás</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import Service from "@/utils/Service";
import {route} from "@/utils/Route";
import router from "@/router";
import Toast from "@/utils/Toast";
import MensajeAlertaComponent from "@/components/utils/MensajeAlerta.vue";
import Editor from "@tinymce/tinymce-vue";
import {tinyMCE} from "@/utils/config";

export default {
  name: 'GuiaPlanificacionEditarView',
  components: {Editor, MensajeAlertaComponent},
  data() {
    return {
      tiny: tinyMCE,
      error: {
        mostrar: false,
        mensaje: null,
      },
      form: {
        activo: true,
        titulo: '',
        tipoProduccion: 1,
        urlVideo: '',
        departamento: 1,
        tipoSiembra: 0,
        zona: 0,
        fechaInicioSiembra: '',
        fechaFinalizacionSiembra: '',
        fechaInicioCosecha: '',
        fechaFinalizacionCosecha: '',
        imagen: null,
        pdf: null,
        descripcion: '',
      },
      lista: {
        tipoPlagas: [],
        zonas: [],
        tipoSiembras: []
      }
    }
  },
  methods: {
    router() {
      return router
    },
    mostrarGuiaPlanificacion() {
      Service.get(route('guia.planificacion.mostrar', this.getParametrosId())).then((data) => {
        this.form.titulo = data.data.titulo;
        this.form.tipoProduccion = data.data.tipoProduccion;
        this.form.urlVideo = data.data.urlVideo;
        this.form.departamento = data.data.departamento;
        this.form.descripcion = data.data.descripcion;
        this.form.fechaInicioSiembra = data.data.fechaInicioSiembra;
        this.form.fechaFinalizacionSiembra = data.data.fechaFinalizacionSiembra;
        this.form.fechaInicioCosecha = data.data.fechaInicioCosecha;
        this.form.fechaFinalizacionCosecha = data.data.fechaFinalizacionCosecha;
        this.form.urlImagen = data.data.urlImagen;
        this.form.urlPdf = data.data.urlPdf;
        this.form.zona = data.data.zona;
        this.form.tipoSiembra = data.data.tipoSiembra;
        this.form.tipoTratamiento = data.data.tipoTratamiento;
      }).catch((error) => {
        console.log(error)
      });
    },
    listarTipoSiembra() {
      Service.get(route('recurso.tipo_siembra')).then( data => {
        this.lista.tipoSiembras = data.data;
      }).catch( error => {
        console.log(error);
      });
    },
    listarZona() {
      Service.get(route('recurso.zona')).then( data => {
        this.lista.zonas = data.data;
      }).catch( error => {
        console.log(error);
      });
    },
    modificarGuiaPlanificacion() {
      this.ocultarError();
      this.desactivarForm();
      Service.putFormData(route('guia.planificacion.modificar', this.getParametrosId()), this.getParametros()).then( (data)=>{
        Toast.showSuccess(data.mensaje)
        router.push({name: 'guia.planificacion.listar'});
      }).catch( error => {
        this.mostrarError(error);
      }). finally( () => {
        this.activarForm();
      });
    },

    onChangeInputImagen(event) {
      this.form.imagen = event.target.files[0];
    },
    onChangeInputPdf(event) {
      this.form.pdf = event.target.files[0];
    },
    getParametrosId() {
      return [this.$route.params.id];
    },
    getParametros() {
      let formData = new FormData();
      formData.append('titulo', this.form.titulo);
      formData.append('tipoProduccion', this.form.tipoProduccion);
      formData.append('urlVideo', this.form.urlVideo);
      formData.append('departamento', this.form.departamento);
      formData.append('tipoPlaga', this.form.tipoPlaga);
      formData.append('zona', this.form.zona);
      formData.append('tipoSiembra', this.form.tipoSiembra);
      formData.append('fechaInicioSiembra', this.form.fechaInicioSiembra);
      formData.append('fechaFinalizacionSiembra', this.form.fechaFinalizacionSiembra);
      formData.append('fechaInicioCosecha', this.form.fechaInicioCosecha);
      formData.append('fechaFinalizacionCosecha', this.form.fechaFinalizacionCosecha);
      formData.append('imagen', this.form.imagen);
      formData.append('pdf', this.form.pdf);
      formData.append('descripcion', this.form.descripcion);

      return formData;
    },
    ocultarError() {
      this.error.mostrar = false;
      this.error.mensaje = '';
    },
    mostrarError(error) {
      this.error.mostrar = true;
      this.error.mensaje = error;
    },
    desactivarForm() {
      this.form.activo = false;
    },
    activarForm() {
      this.form.activo = true;
    },
  },

  mounted() {
    this.mostrarGuiaPlanificacion();
    this.listarTipoSiembra();
    this.listarZona();
  },

}
</script>