import AppHome from "@/views/template/AppHome";
import HuertoListarView from "@/modules/huerto/views/huerto/ListarView.vue";
import HuertoCrearView from "@/modules/huerto/views/huerto/CrearView.vue";
import HuertoEditarView from "@/modules/huerto/views/huerto/EditarView.vue";
import HuertoMostrarView from "@/modules/huerto/views/huerto/MostrarView.vue";
import HuertoMapaView from "@/modules/huerto/views/huerto/MapaView.vue";

const moduleRoute = {
    path: "/huerto",
    component: AppHome,
    /*beforeEnter: (to, from, next) => {

    },*/
    children: [
        {
            path: "/huerto/",
            name: "huerto.listar",
            components: { content: HuertoListarView },
        },
        {
            path: "/huerto/crear",
            name: "huerto.crear",
            components: { content: HuertoCrearView },
        },
        {
            path: "/huerto/mapa",
            name: "huerto.mapa",
            components: { content: HuertoMapaView },
        },
        {
            path: "/huerto/:id/editar",
            name: "huerto.editar",
            components: { content: HuertoEditarView },
        },
        {
            path: "/huerto/:id",
            name: "huerto.mostrar",
            components: { content: HuertoMostrarView },
        },
    ]
};

export default router => {
    router.addRoute(moduleRoute);
};
