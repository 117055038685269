<template>
  <div class="card">
    <div class="card-header">
      <h6 class="card-title mb-0">Cambiar contraseña</h6>
    </div>
    <div class="card-body">
      <form  v-on:submit.prevent="modificarContrasena()" id="formCambiarContrasena">
        <div class="d-flex flex-column">
          <mensaje-alerta-component :error="this.error" class="mb-2" />
          <div class="mb-2">
            <label for="contrasena" class="form-label">Nueva contraseña</label>
            <input v-model="this.form.password" type="password" class="form-control" id="contrasena"
                   maxlength="25" minlength="4" :disabled="!this.form.activo" required>
          </div>
          <div class="mb-2">
            <label for="repetirContrasena" class="form-label">Repetir contraseña</label>
            <input v-model="this.form.repeatPassword" type="password" class="form-control" id="repetirContrasena"
                   maxlength="25" minlength="4" :disabled="!this.form.activo" required>
          </div>
          <div class="d-flex flex-row-reverse">
            <button :disabled="!this.form.activo" type="submit" class="btn btn-primary waves-effect waves-light">Modificar</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Service from "@/utils/Service";
import {route} from "@/utils/Route";
import Toast from "@/utils/Toast";
import MensajeAlertaComponent from "@/components/utils/MensajeAlerta.vue";

export default {
  name: 'UsuarioCambiarContrasenaComponent',
  components: {MensajeAlertaComponent},
  props: {
    persona: {
      id: Number
    }
  },
  data() {
    return {
      error: {
        mostrar: false,
        mensaje: null,
      },
      form: {
        activo: true,
        password: '',
        repeatPassword: ''
      }
    }
  },
  methods: {
    modificarContrasena() {
      if (this.form.password !== this.form.repeatPassword) {
        Toast.showError("Las contraseñas no coinciden");
        return;
      }

      this.desactivarForm();
      this.ocultarError();
      Service.put(route('administrador.usuario.contrasena.modificar', [this.persona.id]),
          this.getParametros()).then((data) => {
        Toast.showSuccess(data.mensaje)
      }).catch( (error) => {
        this.mostrarError(error);
      }).finally( () => {
        this.activarForm();
      })
    },

    getParametros() {
      return {
        password: this.form.password
      }
    },

    desactivarForm() {
      this.form.activo = false;
    },
    activarForm() {
      this.form.activo = true;
    },

    ocultarError() {
      this.error.mostrar = false;
      this.error.mensaje = '';
    },
    mostrarError(error) {
      this.error.mostrar = true;
      this.error.mensaje = error;
    },
  },
  mounted() {

  }
}
</script>