import AppHome from "@/views/template/AppHome";
import AlertaListarView from "@/modules/actividad/views/alerta/ListarView.vue";
import AlertaMostrarView from "@/modules/actividad/views/alerta/MostrarView.vue";
import AlertaCrearView from "@/modules/actividad/views/alerta/CrearView.vue";
import AlertaEditarView from "@/modules/actividad/views/alerta/EditarView.vue";

import ActividadListarView from "@/modules/actividad/views/actividad/ListarView.vue";
import ActividadMostrarView from "@/modules/actividad/views/actividad/MostrarView.vue";
import ActividadCrearView from "@/modules/actividad/views/actividad/CrearView.vue";
import ActividadEditarView from "@/modules/actividad/views/actividad/EditarView.vue";

const moduleRoute = {
    path: "/alerta",
    component: AppHome,
    children: [
        {
            path: "/alerta/",
            name: "alerta.listar",
            components: { content: AlertaListarView},
        },
        {
            path: "/alerta/:id",
            name: "alerta.mostrar",
            components: { content: AlertaMostrarView},
        },
        {
            path: "/alerta/crear",
            name: "alerta.crear",
            components: { content: AlertaCrearView},
        },
        {
            path: "/alerta/:id/editar",
            name: "alerta.editar",
            components: { content: AlertaEditarView},
        },

        {
            path: "/actividad/",
            name: "actividad.listar",
            components: { content: ActividadListarView},
        },
        {
            path: "/actividad/:id",
            name: "actividad.mostrar",
            components: { content: ActividadMostrarView},
        },
        {
            path: "/actividad/crear",
            name: "actividad.crear",
            components: { content: ActividadCrearView},
        },
        {
            path: "/actividad/:id/editar",
            name: "actividad.editar",
            components: { content: ActividadEditarView},
        },
    ]
};

export default router => {
    router.addRoute(moduleRoute);
};
