<template>
  <div>
    <NavBar />
    <Aside />
    <div class="vertical-overlay"></div>
    <div class="main-content">
      <div class="page-content pt-5">
        <div class="container-fluid">
          <router-view
              v-slot="{ Component }"
              name="content" class="mt-5">
            <transition
                name="fade"
                mode="out-in">
              <component :is="Component" />
            </transition>
          </router-view>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NavBar from "@/components/template/NavBar";
import Aside from "@/components/template/Aside";

export default {
  name: 'AppHomeView',
  components: {
    NavBar, Aside
  },
}
</script>