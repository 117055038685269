<template>
  <div>
    <label for="selectTipoProduccion" class="form-label">Tipo de Producción</label>
    <div id="selectTipoProduccion" class="flex-wrap">
      <input @change="onCambiarSeleccion($event)" id="opcionTodo" type="radio" class="btn-check" name="options" value="0" :checked="this.tipoProduccion===0">
      <label class="btn btn-outline-success me-2" for="opcionTodo">Todos</label>

      <input @change="onCambiarSeleccion($event)" id="opcionComercial" type="radio" class="btn-check" name="options" value="1"  :checked="this.tipoProduccion===1">
      <label class="btn btn-outline-success me-2" for="opcionComercial">Comercial</label>

      <input @change="onCambiarSeleccion($event)" id="opcionConsumo" type="radio" class="btn-check" name="options" value="2" :checked="this.tipoProduccion===2">
      <label class="btn btn-outline-success" for="opcionConsumo">Consumo</label>
    </div>
  </div>
</template>
<script>
import router from "@/router";

export default {
  name: 'SelectTipoProduccionComponent',
  data() {
    return {
      tipoProduccion: 0
    }
  },
  methods: {
    onCambiarSeleccion(event) {
      let params = {...this.$route.query, tipoProduccion: event.target.value};
      router.push({ path: this.$route.path, query: params });
    }
  },
  mounted() {
    if (this.$route.query.tipoProduccion !== undefined)
      this.tipoProduccion = this.$route.query.tipoProduccion;
  }
}
</script>