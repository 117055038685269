<template>
  <div>
    <div class="mb-2"><h3>Listar de Huertos</h3></div>
    <div class="d-flex flex-column flex-md-row justify-content-between">
      <div class="d-flex flex-column flex-md-row">
        <select-departamento-component class="m-md-1"/>
        <select-tipo-produccion-component class="m-md-1"/>
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <router-link :to="{name: 'huerto.crear'}" class="btn btn-success waves-effect waves-light">
          Agregar Nuevo
        </router-link>
      </div>
    </div>
    <mensaje-alerta-component :error="this.error" class="my-2" />
    <div class="row mt-2">
      <div class="col-12">
        <GoogleMap
            :api-key="this.googleMap.key"
            style="width: 100%; height: 600px"
            :center="this.googleMap.center"
            :zoom="12">
          <Marker v-for="huerto in this.huertos" v-bind:key="huerto.id"
              :options="{ position: {lat: Number(huerto.latitud), lng: Number(huerto.longitud)},
                         icon: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png', clickable: true }" >
            <InfoWindow>
              <div id="content">
                <div id="siteNotice"></div>
                <h1 id="firstHeading" class="firstHeading">{{huerto.nombre}}</h1>
                <div id="bodyContent">
                  <p><b>Propietario: </b> {{huerto.cultivadorDato.nombre + ' ' + huerto.cultivadorDato.apellidos}}</p>
                  <p><b>Tipo de producción: </b> {{huerto.tipoProduccion === 1 ? 'Comercial' : 'Consumo'}} </p>
                  <p>Registrado el {{huerto.fechaHoraRegistrado}}.
                  </p>
                </div>
              </div>
            </InfoWindow>
          </Marker>
        </GoogleMap>
      </div>
    </div>
  </div>
</template>
<script>
import SelectDepartamentoComponent from '@/modules/huerto/components/utils/SelectDepartamentoComponent.vue';
import SelectTipoProduccionComponent from "@/modules/huerto/components/utils/SelectTipoProduccionComponent.vue";
import Service from "@/utils/Service";
import {route} from "@/utils/Route";
import {GoogleMap, Marker, InfoWindow} from "vue3-google-map";
import MensajeAlertaComponent from "@/components/utils/MensajeAlerta.vue";
import {GOOGLE_MAP_KEY} from "@/utils/config";
export default {
  name: 'HuertoMapaView',
  components: {
    MensajeAlertaComponent,
    Marker, GoogleMap, InfoWindow,
    SelectTipoProduccionComponent,
    SelectDepartamentoComponent
  },
  data() {
    return {
      error: {
        mostrar: false,
        mensaje: null,
      },
      huertos: [],
      googleMap: {
        key: GOOGLE_MAP_KEY,
        center: {lat: -17.783602, lng: -63.182327},
        marker: {lat: -17.783602, lng: -63.182327},
      },
    }
  },
  methods: {
    listarHuerto() {
      this.ocultarError();
      Service.get(this.getRutaParams()).then((data) => {
        this.huertos = data.data;
      }).catch((error) => {
        this.mostrarError(error);
      });
    },

    getRutaParams() {
      let ruta = route('huerto.listar');
      let departamento = 0;
      let tipoProduccion = 0;
      if (this.$route.query.departamento !== undefined) {
        departamento = this.$route.query.departamento;
      }
      if (this.$route.query.tipoProduccion !== undefined) {
        tipoProduccion = this.$route.query.tipoProduccion;
      }
      return ruta + '?tipoProduccion=' + tipoProduccion +'&departamento=' + departamento;
    },

    ocultarError() {
      this.error.mostrar = false;
      this.error.mensaje = '';
    },
    mostrarError(error) {
      this.error.mostrar = true;
      this.error.mensaje = error;
    }
  },

  mounted() {
    this.listarHuerto();
  },
  updated() {
    this.listarHuerto();
  }
}
</script>