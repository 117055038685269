<template>
  <div class="d-flex ">
    <div class="flex-fill">
      <label for="tipoCultivo" class="form-label">Tipo de Cultivo</label>
      <select v-model="this.form.tipoCultivo" @change="onChangeTipoCultivo($event)" id="tipoCultivo"
              class="form-select">
        <option value="0">Todos</option>
        <option v-for="tipoCultivo in this.lista.tipoCultivos" :key="tipoCultivo.id"
                :value="tipoCultivo.id">{{tipoCultivo.nombre}}
        </option>
      </select>
    </div>
    <div class="flex-fill ms-2">
      <label for="cultivo" class="form-label">Cultivo</label>
      <select v-model="this.form.cultivo" @change="onChangeCultivo($event)" id="cultivo" class="form-select">
        <option value="0">Todos</option>
        <option v-for="cultivo in this.lista.cultivos" :key="cultivo.id"
                :value="cultivo.id">{{cultivo.nombre}}
        </option>
      </select>
    </div>
  </div>
</template>
<script>
import Service from "@/utils/Service";
import {route} from "@/utils/Route";
import router from "@/router";

export default {
  name: 'SelectTipoCultivoComponent',
  data() {
    return {
      lista: {
        tipoCultivos: [],
        cultivos: []
      },
      form: {
        tipoCultivo: 0,
        cultivo: 0
      }
    }
  },
  methods: {
    listarTipoCultivo() {
      Service.get(route('recurso.tipo_cultivo')).then( data => {
        this.lista.tipoCultivos = data.data;
      }).catch( error => {
        console.log(error);
      });
    },
    listarCultivo(tipoCultivo) {
      Service.get(route('recurso.tipo_cultivo.cultivo', [tipoCultivo])).then( data => {
        this.lista.cultivos = data.data;
      }).catch( error => {
        console.log(error);
      });
    },

    onChangeTipoCultivo(event) {
      if (event.target.value > 0) {
        this.listarCultivo(event.target.value)
      } else {
        this.lista.cultivos = [];
        this.form.cultivo = 0;
      }
    },
    onChangeCultivo(event) {
      if (event.target.value > 0) {
        let params = {...this.$route.query, tipoCultivo: this.form.tipoCultivo, cultivo: event.target.value};
        router.push({ path: this.$route.path, query: params });
      }
    },
  },
  mounted() {
    this.listarTipoCultivo();
    if (this.$route.query.tipoCultivo !== undefined)
      this.form.tipoCultivo = this.$route.query.tipoCultivo;
    if (this.$route.query.cultivo !== undefined) {
      this.form.cultivo = this.$route.query.cultivo;
      this.listarCultivo(this.form.cultivo);
    }
  }
}
</script>