import { createRouter, createWebHistory } from 'vue-router'
import InicioSesionView from "@/views/InicioSesionView.vue";
import AppHome from "@/views/template/AppHome.vue";
import HomeView from "@/views/HomeView.vue";

const routes = [
  {
    path: '/',
    name: 'inicio.sesion',
    component: InicioSesionView
  },
  {
    path: '/home',
    component: AppHome,
    children: [
      {
        path: '/home',
        name: 'home',
        components: {
          content: HomeView
        },
        meta: {
          reload: true,
        },
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
