<template>
  <div class="card">
    <div class="card-header">
      <h6 class="card-title mb-0">Permisos del usuario</h6>
    </div>
    <div class="card-body">
      <mensaje-alerta-component :error="this.error" class="mb-2" />
      <form v-on:submit.prevent="registrarUsuarioPermiso()" id="form">
        <div class="d-flex flex-column">
          <div class="row">
            <div v-for="permiso in this.lista.permisos" v-bind:key="permiso.id" class="form-check mb-2 col-12 col-md-6">
              <input v-model="this.form.permisos" :id="'checkPermiso' + permiso.id" class="form-check-input" type="checkbox" :value="permiso.id" :disabled="!this.form.activo">
              <label class="form-check-label" :for="'checkPermiso' + permiso.id">{{permiso.nombre}}</label>
            </div>
          </div>
          <div class="d-flex flex-row-reverse">
            <button :disabled="!this.form.activo" type="submit" class="btn btn-primary waves-effect waves-light">Modificar</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Service from "@/utils/Service";
import {route} from "@/utils/Route";
import Toast from "@/utils/Toast";
import MensajeAlertaComponent from "@/components/utils/MensajeAlerta.vue";

export default {
  name: 'UsuarioPermisoCrearComponent',
  components: {MensajeAlertaComponent},
  props: {
    persona: {
      id: Number
    }
  },
  data() {
    return {
      error: {
        mostrar: false,
        mensaje: null,
      },
      form: {
        activo: true,
        permisos: []
      },
      lista: {
        permisos: []
      }
    }
  },
  methods: {
    crearUsuarioPermiso() {
      this.ocultarError();
      Service.get(route('administrador.usuario.permiso.crear', [this.persona.id] )).then((data) => {
        this.lista.permisos = data.data.permisos;
        this.form.permisos = [];
        for( let i in data.data.usuario.permisos) {
          this.form.permisos.push(data.data.usuario.permisos[i].id);
        }
      }).catch((error) => {
        this.mostrarError(error);
      });
    },

    registrarUsuarioPermiso() {
      this.desactivarForm();
      this.ocultarError();
      Service.post(route('administrador.usuario.permiso.registrar', [this.persona.id]),
          this.getParametros()).then((data) => {
        Toast.showSuccess(data.mensaje)
      }).catch( (error) => {
        this.mostrarError(error);
      }).finally( () => {
        this.activarForm();
      });
    },

    getParametros() {
      return {
        permisos: this.form.permisos
      }
    },

    desactivarForm() {
      this.form.activo = false;
    },
    activarForm() {
      this.form.activo = true;
    },

    ocultarError() {
      this.error.mostrar = false;
      this.error.mensaje = '';
    },
    mostrarError(error) {
      this.error.mostrar = true;
      this.error.mensaje = error;
    },
  },
  mounted() {
    this.crearUsuarioPermiso();
  }
}
</script>