<template>
  <div>
    <div class="mb-2"><h3>Lista de cultivos</h3></div>
    <div class="d-flex flex-column flex-md-row justify-content-between">
      <div class="d-flex flex-row">
        <select-tipo-cultivo-component class="m-md-1"/>
      </div>
      <div class="d-flex align-items-center justify-content-end pt-2">
        <div class="dropdown">
          <button class="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
            Exportar
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" :href="routeQuery('reporte.administracion.cultivo.excel', this.$route.query)" target="_blank">Excel</a>
          </div>
        </div>
        <router-link :to="{name: 'cultivo.crear'}" class="btn btn-success waves-effect waves-light ms-2">
          Agregar Nuevo
        </router-link>
      </div>
    </div>
    <mensaje-alerta-component :error="this.error" class="my-2" />
    <tabla-cultivo-listar-component ref="tablaCultivoListar" @onCultivoEliminar="onCultivoEliminar" class="mt-2"/>
    <modal-cultivo-eliminar-component :cultivo="this.cultivo" @onCultivoEliminado="onCultivoEliminado"/>
  </div>
</template>
<script>
import TablaCultivoListarComponent from "@/modules/administracion/components/cultivo/TablaListarComponent.vue";
import SelectTipoCultivoComponent from "@/modules/administracion/components/cultivo/SelectTipoCultivoComponent.vue";
import ModalCultivoEliminarComponent from "@/modules/administracion/components/cultivo/ModalEliminarComponent.vue";
import Service from "@/utils/Service";
import {route, routeQuery} from "@/utils/Route";
import MensajeAlertaComponent from "@/components/utils/MensajeAlerta.vue";
export default {
  name: 'CultivoListarView',
  components: {
    MensajeAlertaComponent,
    SelectTipoCultivoComponent,
    TablaCultivoListarComponent,
    ModalCultivoEliminarComponent,
  },
  data() {
    return {
      error: {
        mostrar: false,
        mensaje: null,
      },
      cultivos: [],
      cultivo: {},
    }
  },
  methods: {
    routeQuery,
    listarCultivo() {
      this.ocultarError();
      Service.get(this.getRutaParams()).then((data) => {
        this.cultivos = data.data;
        this.$refs.tablaCultivoListar.setCultivos(this.cultivos);
      }).catch((error) => {
        this.mostrarError(error);
      });
    },

    getRutaParams() {
      let ruta = route('cultivo.listar');
      let tipoCultivo = 0;
      if (this.$route.query.tipoCultivo !== undefined) {
        tipoCultivo = this.$route.query.tipoCultivo;
      }
      return ruta + '?tipoCultivo=' + tipoCultivo;
    },

    onCultivoEliminar(cultivo) {
      this.cultivo = cultivo;
    },

    onCultivoEliminado() {
      this.listarCultivo();
    },

    ocultarError() {
      this.error.mostrar = false;
      this.error.mensaje = '';
    },
    mostrarError(error) {
      this.error.mostrar = true;
      this.error.mensaje = error;
    }
  },
  mounted() {
    this.listarCultivo();
  },
  updated() {
    this.listarCultivo();
  }
}
</script>