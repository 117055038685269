<template>
  <div class="d-flex flex-column flex-md-row align-items-center mt-4 pt-2 justify-content-between">
    <div class="w-100">
      <div class="pagination pagination-separated mb-0 d-flex justify-content-start align-items-center">
        <div class="py-0 my-0 border-0">
          <select
              v-model="cantidad"
              class="form-select cursor-pointer"
              aria-label="Default select example"
              @change="actualizarCantidad()">
            <option selected value="5">5</option>
            <option value="10">10</option>
            <option value="30">30</option>
            <option value="50">50</option>
          </select>
        </div>
        <div class="py-1 flex-grow-1 w-auto ms-2">
          <span class="d-block text-muted" style="min-width: 150px">mostrando
          <span class="fw-semibold">{{ (pagina-1)*cantidad+1 }}-{{ (pagina*cantidad) > cantidadFilas ? cantidadFilas : (pagina*cantidad) }}</span> de
          <span class="fw-semibold">{{ cantidadFilas }}</span>
        </span>
        </div>
      </div>
    </div>
    <ul class="pagination pagination-separated pagination-sm mb-0 mt-2 mt-md-0 w-100 d-flex justify-content-end">
      <li :class="['page-item',  pagina === 1 ? 'disabled' : '']">
        <a href="javascript:void(0)" @click="primeraPagina()" class="page-link">
          <i class="mdi mdi-arrow-collapse-left fs-6"></i>
        </a>
      </li>
      <li :class="['page-item',  pagina === 1 ? 'disabled' : '']">
        <a href="javascript:void(0)" @click="anteriorPagina()" class="page-link">
          <i class="mdi mdi-arrow-left fs-6"></i>
        </a>
      </li>
      <li :class="['page-item',  pagina === limitePagina ? 'disabled' : '']">
        <a href="javascript:void(0)" @click="siguientePagina()" class="page-link">
          <i class="mdi mdi-arrow-right fs-6"></i>
        </a>
      </li>
      <li :class="['page-item',  pagina === limitePagina ? 'disabled' : '']">
        <a href="javascript:void(0)" @click="ultimaPagina()" class="page-link">
          <i class="mdi mdi-arrow-collapse-right fs-6"></i>
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'TablaPaginacionComponent',
  data() {
    return {
      cantidad: 5,
      pagina: 1,
      limitePagina: 1,
      cantidadFilas: 0,
    }
  },
  methods: {
    getCantidad() {
      return this.cantidad;
    },
    getPagina() {
      return this.pagina;
    },

    primeraPagina() {
      if(this.pagina > 1) {
        this.pagina = 1;
        this.$emit('calcularFilasMostrar');
      }
    },
    anteriorPagina() {
      if(this.pagina > 1) {
        this.pagina--;
        this.$emit('calcularFilasMostrar');
      }
    },
    siguientePagina() {
      if(this.pagina < this.limitePagina) {
        this.pagina++;
        this.$emit('calcularFilasMostrar');
      }
    },
    ultimaPagina() {
      if(this.pagina < this.limitePagina) {
        this.pagina = this.limitePagina;
        this.$emit('calcularFilasMostrar');
      }
    },
    actualizarCantidad() {
      this.pagina = 1;
      this.$emit('calcularFilasMostrar');
      this.calcularLimitePagina();
    },
    reiniciarPaginacion(cantidadFilas) {
      this.cantidad = 5;
      this.pagina = 1;
      this.cantidadFilas = cantidadFilas;
      this.calcularLimitePagina();
    },
    calcularLimitePagina() {
      this.limitePagina =
          Math.trunc(this.cantidadFilas / this.cantidad) + (this.cantidadFilas % this.cantidad === 0 ? 0 : 1);
    }
  }
}
</script>
